import React, { useState, useEffect } from "react";
import { moderateScale } from 'react-native-size-matters';
import { StyleSheet, View, FlatList, Platform, SafeAreaView, ScrollView, Linking, Dimensions } from "react-native";
import {
  Avatar,
  Button,
  Subheading,
  Surface,
  Text,
  Title,
  TouchableRipple,
  useTheme
} from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ca from 'date-fns/locale/ca/index';
import { startOfToday, format } from 'date-fns';
import useDimensions from '@rnhooks/dimensions';
import RenderNavbarSonItem from "../components/sons/NavbarSons/RenderNavbarSonItem";
import HeadingSection from "../components/headings/HeadingSection";
import ContainedButton from "../components/buttons/ContainedButton";
import { NAV_BAR_HEIGHT } from "../utiles/CalculateNavbarHeight";
import { logout } from "../store/user/actions";
import NotificationBox from "../components/notifications/NotificationBox";
import RenderClassroomItem from "../components/classroom/RenderClassroomItem";


const HomeScreen = ({ navigation }) => {
  const { colors } = useTheme();
  const { unreaded } = useSelector(state => state.notifications);
  const [countUnreaded, setCountUnreaded] = useState(unreaded);
  const { currentYearId } = useSelector(state => state.info);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { isAdministration, isEmployee, families } = useSelector(state => state.user);
  const [familySelected, setFamilySelected] = useState(families && families.length > 0 ? 0 : null);
  const [classrooms, setClassrooms] = useState([]);
  const today = startOfToday();
  const screenWidth = useDimensions('window').width;

  useEffect(() => {
    setFamilySelected(families && families.length > 0 ? 0 : null);
  }, [families]);

  useEffect(() => {
    setCountUnreaded(unreaded);
  }, [unreaded]);

  useEffect(() => {
    if (isEmployee) {
      setClassrooms(user.assigned_classrooms);
    }
  }, [isEmployee]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      paddingTop: NAV_BAR_HEIGHT,
      backgroundColor: colors.primary
    },
    appContainer: {
      width: (Platform.OS === 'web' && screenWidth > 960) ? '33%' : '100%',
      alignSelf: 'center',
      flex: 1
    },
    userHeader: {
      marginHorizontal: 12,
      marginBottom: 12,
      elevation: 0,
      borderRadius: 12,
      backgroundColor: 'white',
      padding: 24.0,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    notificationsContainer: {
      marginHorizontal: 12,
      elevation: 0,
      borderRadius: 12,
      backgroundColor: 'white',
      padding: 12.0,
      flexDirection: 'row',
      alignItems: 'center'
    },
    notificationText: {
      marginLeft: 8,
      fontFamily: 'Rubik-Light',
      fontSize: moderateScale(16, 0.1)
    },
    notificationTextBold: {
      fontFamily: 'Rubik-Light',
      fontSize: moderateScale(20, 0.1)
    },
    userNickname: {
      paddingLeft: 8,
      flex: 1,
    },
    userNicknameLabel: {
      fontFamily: 'Rubik-Medium',
      fontSize: moderateScale(24, 0.1),
    },
    userSubheadingLabel: {
      fontFamily: 'Rubik-Light',
      fontSize: moderateScale(18, 0.1)
    },
    userSubsubheadingLabel: {
      fontFamily: 'Rubik-Light',
      fontSize: moderateScale(10, 0.1)
    },
    btnPhone: {
      backgroundColor: colors.accent
    },
    btnPhoneLabel: {
      fontFamily: 'Rubik-Light',
      fontSize: 24
    }
  });


  const onClickItemSon = (id) => {
    navigation.navigate('ChildDetails', {
      childId: id,
      familyTitle: `${families[familySelected].firstName} ${families[familySelected].lastName}`
    });
  };

  const onClickItemClassroom = (id) => {
    navigation.navigate('ClassroomScreen', {
      classroomId: id,
    });
  };

  const onRenderItemClassroom = ({ index, item }) => {
    return <RenderClassroomItem
      item={item}
      handleOnPress={() => onClickItemClassroom(item.id)}
      currentSchoolYear={currentYearId}
    />
  };

  const onRenderItemSon = ({ index, item }) => {
    return <RenderNavbarSonItem
      item={item}
      handleOnPress={() => onClickItemSon(item.id)}
      currentSchoolYear={currentYearId}
    />
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.appContainer}>
        <Surface roundness={50} style={styles.userHeader}>
          <View style={styles.userNickname}>
            <Title style={styles.userNicknameLabel}>Hola, {user.name}</Title>
            <View>
              {families && families.map((family, index) =>
                <TouchableRipple key={`family-${index}`} style={{ cursor: 'pointer' }} onPress={() => setFamilySelected(index)}>
                  <Subheading
                    style={[
                      styles.userSubheadingLabel,
                      families.length > 1 ? { marginRight: 12 } : {},
                      index !== familySelected ? { color: 'silver', fontSize: moderateScale(14, 0.1) } : {}
                    ]}>Família {family.firstName} {family.lastName}</Subheading>
                </TouchableRipple>
              )}
            </View>
            <Text style={styles.userSubsubheadingLabel}>
              {format(today, 'dd LLLL yyyy', {
                locale: ca
              })}
            </Text>
          </View>
          {user.avatar ?
            <Avatar.Image size={48} source={{
              uri: `https://agenda.granota.cat/avatars/${user.avatar}`
            }} /> : <Avatar.Text size={48} label={user && user.hasOwnProperty('name') ? user.name.toUpperCase().substring(0, 2) : ''} />
          }

        </Surface>

        {isAdministration && <View style={{
          flexDirection: 'row',
          marginHorizontal: 12,
          marginBottom: 12,
        }}>
          <View style={{ flex: 1 }}>
            <ContainedButton icon={"cogs"} lblButton={"Administració"}
              onPress={() => navigation.navigate('Administration')} />
          </View>
        </View>}

        <View style={{
          flexDirection: 'row',
          marginHorizontal: 12,
          marginBottom: 12,
        }}>
          <View style={{ flex: 1 }}>
            <ContainedButton onPress={() => navigation.replace('EditInfo')} icon={"pencil"} lblButton={"Editar informació"} />
          </View>
          <View style={{ flex: 1 }}>
            <ContainedButton onPress={() => dispatch(logout(() => {
              navigation.replace('Loading');
            }))} icon={"power"} lblButton={"Tancar sessió"} />
          </View>
        </View>

        <View style={{
          flex: 1
        }}>
          <TouchableRipple style={{ padding: 6, cursor: 'pointer' }} onPress={() => navigation.navigate('Notifications')}>
            <>
              <HeadingSection icon="bell" iconColor={colors.secondaryText} titleColor={colors.secondaryText}
                title="notifications" badgeCount={unreaded} />
              <View style={{ marginTop: 0 }}>
                <Surface roundness={50} style={styles.notificationsContainer}>
                  <NotificationBox />
                </Surface>
              </View>
            </>
          </TouchableRipple>

          {isEmployee && (
            <View style={{
              padding: 6,
            }}>
              <HeadingSection icon="notebook" iconColor={colors.secondaryText} titleColor={colors.secondaryText}
                title="gestionar classes" />
              <View>
                <FlatList
                  nestedScrollEnabled
                  style={{
                    padding: 6,
                    marginHorizontal: 12,
                    backgroundColor: 'white',
                    borderRadius: 12
                  }}
                  ListEmptyComponent={() =>
                    <Text style={[styles.notificationText, { padding: 8 }]}>
                      no tens cap classe assignada
                    </Text>
                  }
                  data={classrooms}
                  renderItem={onRenderItemClassroom}
                  keyExtractor={(item, index) => `classroom-item-${index}`}
                />
              </View>
            </View>
          )}

          {(!isEmployee || isEmployee && families.length > 0) && (
            <View style={{
              padding: 6,
            }}>
              <HeadingSection icon="notebook" iconColor={colors.secondaryText} titleColor={colors.secondaryText}
                title="agenda" />
              <View>
                <FlatList
                  nestedScrollEnabled
                  style={{
                    padding: 6,
                    marginHorizontal: 12,
                    backgroundColor: 'white',
                    borderRadius: 12
                  }}
                  ListEmptyComponent={() =>
                    <Text style={[styles.notificationText, { padding: 8 }]}>
                      no s'han afegit fills a la família
                  </Text>
                  }
                  data={(families && familySelected !== null) ? families[familySelected].students : []}
                  renderItem={onRenderItemSon}
                  keyExtractor={(item, index) => `agenda-item-${index}`}
                />
              </View>
            </View>
          )}

          <View style={{
            padding: 6,
            flex: 1
          }}>
            <HeadingSection icon="info" iconColor={colors.secondaryText} titleColor={colors.secondaryText}
              title="centre" />
            <View style={{ flex: 1, paddingHorizontal: 24, justifyContent: 'center' }}>
              <Button mode="contained" icon="phone" style={styles.btnPhone} labelStyle={styles.btnPhoneLabel} onPress={() => Linking.openURL(`tel:932451029`)}>
                932 45 10 29
              </Button>
              <Text style={{
                flex: 1,
                textAlign: 'center',
                marginTop: 8.0,
                fontSize: 10.0,
                fontFamily: 'Rubik-Light',
                color: colors.secondaryText
              }}>Atenció dies laborals de 07:30 fins 18:00 hores</Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default HomeScreen;
