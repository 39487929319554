import React, { useState } from 'react';
import { View, Platform } from 'react-native';
import { Surface, Title, TextInput, Button, Text } from 'react-native-paper';
import { useTheme } from 'react-native-paper';
import {
    CodeField,
    Cursor,
    useBlurOnFulfill,
    useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import services from '../../services/index';
import { useEffect } from 'react';
import { doLogin } from '../../store/user/actions';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';

const RecoveryStepThree = ({ styles, email, password, setDisplayError, goNext }) => {
    const { colors } = useTheme();
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const [value, setValue] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);
    const ref = useBlurOnFulfill({ value, cellCount: 6 });
    const [props, getCellOnLayoutHandler] = useClearByFocusCell({
        value,
        setValue,
    });

    useEffect(() => {
        if (value.length === 6) {
            onSubmitRecovery()
        }
    }, [value]);

    const onSubmitRecovery = () => {
        setSubmitting(true);
        if (value.length === 6) {
            services.authManagement.create({
                action: 'resetPwdShort',
                value: {
                    user: {
                        email: email
                    },
                    token: value,
                    password: password
                }
            }).then((res) => {
                console.log('ok?', res)
                dispatch(doLogin({
                    email: email,
                    password: password
                },
                    () => {
                        navigation.replace('Loading');
                    }, (err) => {
                        setDisplayError('No s\'ha pogut iniciar sessió de manera automatica');
                    }));

            }).catch((err) => {
                console.log('err?', err)
                setSubmitting(false);
                setDisplayError(`No s'ha pogut realitzar el canvi de contrasenya.`)
            })
        } else {
            setSubmitting(false);
            setDisplayError(`El codi no es correcte.`);
        }
    };

    return (
        <View style={styles.contentContainer}>
            <Surface style={styles.formContainer}>
                <Title style={styles.title}>Recuperar contrasenya</Title>
                <Title style={styles.subtitle}>S'ha enviat un correu electrònic amb un codi de 6 dígits, introdueix-lo aquí:</Title>
                <View style={{
                    paddingHorizontal: Platform.OS === 'web' ? 24.0 : 0,
                }}>
                    <CodeField
                        ref={ref}
                        {...props}
                        value={value}
                        onChangeText={setValue}
                        cellCount={6}
                        rootStyle={styles.codeFieldRoot}
                        keyboardType="number-pad"
                        textContentType="oneTimeCode"
                        renderCell={({ index, symbol, isFocused }) => (
                            <Text
                                key={index}
                                style={[styles.cell, isFocused && styles.focusCell]}
                                onLayout={getCellOnLayoutHandler(index)}>
                                {symbol || (isFocused ? <Cursor /> : null)}
                            </Text>
                        )}
                    />
                </View>

                <View style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    marginTop: 16,
                    marginBottom: 32
                }}>
                    <Button loading={isSubmitting} disabled={isSubmitting} style={styles.btnSubmit} labelStyle={styles.btnSubmitLabel} mode="contained"
                        onPress={onSubmitRecovery}>
                        Confirmar canvi de contrasenya
        </Button>
                </View>

            </Surface>
        </View>
    );
}

export default RecoveryStepThree;