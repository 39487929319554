import React, { useEffect, useState } from 'react';
import { FlatList, SafeAreaView, ScrollView } from "react-native";
import { Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import RenderNotificationItem from "../components/notifications/RenderNotificationItem";
import { fetchNotifications, notificationUpdateToReaded } from "../store/notifications/actions";


const NotificationsScreen = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [elements, setElements] = useState([]);
  const { data } = useSelector(state => state.notifications);

  const markAsRead = (id) => {
    dispatch(notificationUpdateToReaded(id));
  };

  const onRenderNotificationItem = ({ index, item }) => {
    return <RenderNotificationItem item={item.notification} unread={item.unread} onRender={() => {
      if(item.unread === true) {
        markAsRead(item.id)
      }
    }} />
  };

  useEffect(() => {
    dispatch(fetchNotifications(user.id));
  }, []);

  useEffect(() => {
    setElements(data);
  }, [data]);

  return (
    <SafeAreaView style={{
      flex: 1
    }}>
        <FlatList
          style={{
            padding: 12,
            backgroundColor: 'white',
            borderRadius: 12
          }}
          ListEmptyComponent={() =>
            <Text style={{ fontSize: 18, fontFamily: 'Rubik-Light', padding: 8 }}>
              no hi ha cap notificació.
            </Text>
          }
          data={elements}
          renderItem={onRenderNotificationItem}
          keyExtractor={(item, index) => `notification-${item.id}`}
        />
    </SafeAreaView>
  );
};

export default NotificationsScreen;
