import React, { useEffect, useState } from "react";
import services from "../services";
import { View, StyleSheet, FlatList, Platform, TextInput} from "react-native";
import AppLoading from "../components/loading/AppLoading";
import { NAV_BAR_HEIGHT } from "../utiles/CalculateNavbarHeight";
import { Surface, Text, TextInput as PaperTextInput, TouchableRipple, useTheme } from "react-native-paper";
import HeaderBar from "../components/sons/HeaderBar";
import HeadingSection from "../components/headings/HeadingSection";
import { SimpleLineIcons } from '@expo/vector-icons';
import { moderateScale } from "react-native-size-matters";
import RenderNotificationItem from "../components/notifications/RenderNotificationItem";


const NotificationsTeacherScreen = ({ route, navigation }) => {
  const { colors } = useTheme();
  const { studentId } = route.params;
  const [student, setStudent] = useState({});
  const [notificacions, setNotificacions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [creatingNotification, setCreatingNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationTitleErr, setNotificationTitleErr] = useState(false);
  const [notificationBody, setNotificationBody] = useState('');
  const [notificationBodyErr, setNotificationBodyErr] = useState(false);

  const styles = StyleSheet.create({
                                     container: {
                                       flex: 1,
                                       paddingTop: NAV_BAR_HEIGHT,
                                       backgroundColor: colors.primary
                                     },
                                     notificationsContainer: {
                                       marginHorizontal: 12,
                                       elevation: 0,
                                       borderRadius: 12,
                                       backgroundColor: 'white',
                                       padding: 12.0,
                                       flexDirection: 'row',
                                       alignItems: 'center'
                                     },
                                     buttonSubmitNotification: {
                                       marginTop: 8.0,
                                       padding: 12.0,
                                       borderRadius: 12,
                                       backgroundColor: '#e8e8e8'
                                     },
                                     sectionInlineContent: {
                                       flexDirection: 'row',
                                       alignItems: 'center'
                                     }
                                   });

  useEffect(() => {
    setLoading(true);
    services.students.get(studentId).then((res) => {
      setStudent(res);
      services.myNotifications.find({
                                       query: {
                                         studentId: studentId,
                                         $sort: { createdAt: -1 }
                                       }
                                     }).then((all) => {
        setNotificacions(all.data);
        setLoading(false);
      })
    });
  }, [studentId]);

  const fetchNotifications = () => {
    services.myNotifications.find({
                                    query: {
                                      studentId: studentId,
                                      $sort: { createdAt: -1 }
                                    }
                                  }).then((all) => {
      setNotificacions(all.data);
      setLoading(false);
    })
  }

  const handleSubmitNotification = () => {
    setCreatingNotification(true);
    let validate = true;

    if(notificationTitle.length === 0) {
      validate = false;
      setNotificationTitleErr(true);
    } else {
      setNotificationTitleErr(false);
    }

    if(notificationBody.length === 0) {
      validate = false;
      setNotificationBodyErr(true);
    } else {
      setNotificationBodyErr(false);
    }

    if(validate) {
      let data = {
        title: notificationTitle,
        text: notificationBody,
        type: 'student',
        studentId: studentId
      };
      services.notifications.create(data).then((res) => {
        setNotificationTitle('');
        setNotificationBody('');
        fetchNotifications();
        setCreatingNotification(false);
      });
    }
  };

  const FlatListItemSeparator = () => {
    return (
      <View
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#47b000",
        }}
      />
    );
  };

  const onRenderNotificationItem = ({ index, item }) => {
    return <RenderNotificationItem item={item.notification} />
  };

  return (
    <View style={styles.container}>
      {isLoading && <AppLoading/>}
      {(!isLoading && student.hasOwnProperty('id')) && (
        <>
          <HeaderBar student={student} family={`${student.family.firstName} ${student.family.lastName}`}/>
          <View style={{
            flex: 1
          }}>
              <>
                <View style={{ marginTop: 0 }}>
                  <Surface roundness={50} style={styles.notificationsContainer}>
                    <View style={{
                      flex: 1,
                      flexDirection: 'column',
                      marginVertical: 12
                    }}>
                      <PaperTextInput
                        dense
                        error={notificationTitleErr}
                        mode={"outlined"}
                        style={[{
                          flex: 1,
                          fontSize: 12,
                          ...Platform.select({
                                               android: {
                                                 textAlignVertical: "top"
                                               }
                                             })
                        }]}
                        value={notificationTitle}
                        onChangeText={setNotificationTitle}
                        placeholder="Títol del missatge"
                        theme={{ colors: { primary: 'green', underlineColor: 'transparent', } }}
                        render={props => (
                          <TextInput {...props} style={{
                            width: '100%',
                            fontSize: 12,
                            padding: 12,
                            ...Platform.select({
                                                 android: {
                                                   textAlignVertical: "top"
                                                 }
                                               })
                          }}/>
                        )}
                      />
                      <PaperTextInput
                        multiline
                        error={notificationBodyErr}
                        mode={"outlined"}
                        style={[{
                          flex: 1,
                          fontSize: 12,
                          ...Platform.select({
                                               android: {
                                                 textAlignVertical: "top"
                                               }
                                             })
                        }]}
                        value={notificationBody}
                        onChangeText={setNotificationBody}
                        placeholder="Missatge"
                        theme={{ colors: { primary: 'green', underlineColor: 'transparent', } }}
                        render={props => (
                          <TextInput {...props} style={{
                            width: '100%',
                            fontSize: 12,
                            padding: 12,
                            ...Platform.select({
                                                 android: {
                                                   textAlignVertical: "top"
                                                 }
                                               })
                          }}/>
                        )}
                      />

                      <TouchableRipple disabled={creatingNotification} onPress={handleSubmitNotification} style={[styles.buttonSubmitNotification, { marginHorizontal: 24, cursor: 'pointer' }]}>
                        <View style={[styles.sectionInlineContent, { justifyContent: 'center' }]}>
                          <SimpleLineIcons name="paper-plane" size={12} color="#666"/>
                          <Text style={{
                            fontSize: moderateScale(12, 0.1),
                            marginLeft: 4.0,
                            fontFamily: 'Rubik-Regular',
                            textTransform: 'uppercase',
                            color: '#666'
                          }}>Enviar notificació</Text>
                        </View>
                      </TouchableRipple>

                    </View>
                  </Surface>
                </View>
              </>

              <View style={{ marginTop: 12 }}>
                <HeadingSection icon="list" iconColor={colors.secondaryText} titleColor={colors.secondaryText}
                                title="Llistat de notificacions enviades"/>
                <View>
                  <Surface roundness={50} style={styles.notificationsContainer}>
                    <FlatList
                      style={{
                        marginHorizontal: 12,
                        marginBottom: 12,
                        borderRadius: 12,
                        padding: 6,
                        backgroundColor: 'white',
                      }}
                      ItemSeparatorComponent={FlatListItemSeparator}
                      contentContainerStyle={{ flexGrow: 1 }}
                      ListEmptyComponent={() => <View style={styles.emptyView}>
                        <Text style={styles.emptyText}>No hi ha cap notificació</Text>
                      </View>}
                      data={notificacions}
                      renderItem={onRenderNotificationItem}
                      keyExtractor={(item, index) => `noti-item-${index}`}
                    />
                  </Surface>
                </View>
              </View>

          </View>

          </>)}
    </View>
  );
};


export default NotificationsTeacherScreen;
