import React, { useState, useEffect, useLayoutEffect } from "react";
import { Text, Dialog, Button, TextInput, Portal, Paragraph, TouchableRipple, Avatar } from "react-native-paper";
import services from "../../../services";
import { Picker, View, Platform } from "react-native";
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';

const FormAssignClassroom = ({ visible, studentId, studentName, familyTitle, schoolYearId, onDismiss, onComplete }) => {
  const [cicles, setCicles] = useState([]);
  const [selectedCicle, setSelectedCicle] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [student, setStudent] = useState({});
  const [childName, setChildName] = useState(studentName);
  const [avatar, setAvatar] = useState('');
  const [newAvatar, setNewAvatar] = useState(false);
  const [showDelete, setDelete] = useState(false);

  const getPermissionAsync = async () => {
    if (Platform.OS !== 'web') {
      const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
      }
    }
  };

  useLayoutEffect(() => {
    getPermissionAsync();
    return () => {

    };
  }, [])

  useEffect(() => {
    services.students.get(studentId).then((res) => {
      setStudent(res);
      setAvatar(res.avatar);
      setChildName(res.name);
    });

    services.classroomStudent.find({
      query: {
        studentId,
        schoolyearId: schoolYearId
      }
    }).then((res) => {
      if (res.total > 0) {
        setSelectedCicle(res.data[0].classroomId);
      } else {
        setSelectedCicle('')
      }
    })
  }, [studentId]);

  useEffect(() => {
    services.classrooms.find({
      query: {
        schoolyearId: schoolYearId,
        paginate: false
      }
    }).then((res) => {
      setCicles(res);
    });
  }, []);

  const removeSonAdvertise = () => {
    setDelete(true);
  };

  const handleConfirmRemoveSon = () => {
    setLoading(true);
    services.students.remove(student.id).then(() => {
      setLoading(false);
      onComplete();
    });
  };

  const _pickImage = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        base64: true,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      if (!result.cancelled) {
        if (Platform.OS !== "web") {
          setNewAvatar('data:image/jpeg;base64,' + result.base64);
        } else {
          setNewAvatar(result.uri);
        }
      } else {
        setNewAvatar(false);
      }

      console.log(result);
    } catch (E) {
      console.log(E);
    }
  };

  const updateStudentInfo = () => {
    setLoading(true);
    let data = {
      name: childName
    };
    if (newAvatar !== false) {
      data.avatar = newAvatar;
    }
    services.students.patch(student.id, data).then(() => {
      setLoading(false);
      onComplete();
    });
  };

  return (
    <Dialog style={{
      width: '33%',
      alignSelf: 'center'
    }} visible={visible}
      onDismiss={onDismiss}>
      <Dialog.Content>
        {student.hasOwnProperty('id') && (
          <View style={{
            marginBottom: 24,
          }}>

            <TouchableRipple borderless style={{ borderRadius: 12, marginBottom: 12, cursor: 'pointer' }} onPress={_pickImage}>
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                {avatar ?
                  <Avatar.Image size={48} source={{
                    uri: newAvatar !== false ? newAvatar : `https://agenda.granota.cat/avatars/${avatar}`
                  }} /> : <Avatar.Text size={48} label={childName ? childName.toUpperCase().substring(0, 2) : ''} />
                }
              </View>
            </TouchableRipple>

            <TextInput
              dense
              style={{
                flex: 1,
                marginRight: 24,
                marginBottom: 12
              }}
              label="Nom del fill/a"
              value={childName}
              onChangeText={text => setChildName(text)}
            />

            <Button loading={isLoading} mode="contained" icon="content-save" onPress={updateStudentInfo}>
              Guardar dades
          </Button>
          </View>
        )}


        <Text style={{ fontSize: 24, fontFamily: 'Rubik-Bold' }}>Assignar {studentName} {familyTitle} a:</Text>
        <View style={{
          flex: 1,
          marginTop: 12,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row'
        }}>
          <Picker
            selectedValue={selectedCicle}
            style={{ height: 34, width: 180, borderRadius: 12, marginRight: 12 }}
            onValueChange={(itemValue, itemIndex) => {
              setSelectedCicle(itemValue);
            }}
          >
            {cicles.map((cicle) => <Picker.Item key={cicle.id} label={cicle.classroom_name} value={cicle.id} />)}
          </Picker>

          <Button loading={isLoading} mode="contained" icon="content-save" onPress={() => {
            setLoading(true);
            services.classroomStudent.find({
              query: {
                studentId: studentId,
                schoolyearId: schoolYearId
              }

            }).then((res) => {
              if (res.total > 0) {
                services.classroomStudent.patch(res.data[0].id, {
                  classroomId: selectedCicle,
                }).then(() => {
                  setLoading(false);
                  onComplete();
                });
              } else {
                services.classroomStudent.create({
                  classroomId: selectedCicle,
                  studentId: studentId,
                  schoolyearId: schoolYearId
                }).then(() => {
                  setLoading(false);
                  onComplete();
                })
              }
            });
          }}>
            Guardar cicle
          </Button>
        </View>

        <View style={{
          marginTop: 12
        }}>
          {student.hasOwnProperty('id') && (
            <View style={{
              marginTop: 32,
            }}>
              <Button loading={isLoading} mode="contained" icon="delete" onPress={removeSonAdvertise} contentStyle={{
                backgroundColor: 'darkred'
              }}>
                Esborrar alumne
          </Button>
            </View>
          )}
        </View>

        <Portal>
          <Dialog style={{
            width: '33%',
            alignSelf: 'center'
          }} visible={showDelete} onDismiss={() => {
            setDelete(false);
          }}>
            <Dialog.Title style={{
              fontFamily: 'Rubik-Bold'
            }}>Atenció!!</Dialog.Title>
            <Dialog.Content>
              <Paragraph>Aquesta acció es irreversible, estàs segur/a de continuar?</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => {
                setDelete(false);
              }}>Cancel·lar</Button>
              <Button onPress={() => {
                handleConfirmRemoveSon();
                setDelete(false);
              }}>Continuar</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

      </Dialog.Content>
    </Dialog>
  );
};

export default FormAssignClassroom;
