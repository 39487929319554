import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Picker } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Text, List } from "react-native-paper";
import Dashboard from "../components/admin/dashboard/Dashboard";
import ManageCourses from "../components/admin/courses/ManageCourses";
import ManageFamilies from "../components/admin/families/ManageFamilies";
import ManageEmployees from "../components/admin/employees/ManageEmployees";
import ManageYears from "../components/admin/years/ManageYears";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSchoolYear } from "../store/schoolyear/actions";
import ManageNotifications from "../components/admin/notifications/ManageNotifications";

const AdminScreen = ({ navigation }) => {
  const dispatch = useDispatch();
  const { currentYearId } = useSelector(state => state.info);
  const { current, list } = useSelector(state => state.schoolYear);
  const [selectedYearObj, setSelectedYearObj] = useState(list.find(e => e.id === currentYearId));
  const [selectedYear, setSelectedYear] = useState(currentYearId);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    setSelectedYear(currentYearId);
    setSelectedYearObj(list.find(e => e.id === currentYearId));
  }, [currentYearId]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.sidebar}>

        <View style={styles.sidebarHeading}>
          <Text style={styles.sidebarHeaderText}>Administració</Text>
          <Text style={styles.sidebarHeaderTitle}>Granota</Text>
          <Picker
            selectedValue={selectedYear}
            style={{ height: 34, width: 150 }}
            onValueChange={(itemValue, itemIndex) => {
              setSelectedYear(itemValue);
              setSelectedYearObj(list[itemIndex]);
              dispatch(setCurrentSchoolYear(list[itemIndex]))
            }}
          >
            {list.map((year) => <Picker.Item key={year.id} label={year.name} value={year.id} />)}
          </Picker>
        </View>

        <List.Item
          onPress={() => setTab(0)}
          title="Dashboard"
          description="Pàgina inicial d'administració"
          left={props => <List.Icon {...props} icon="chart-pie"/>}
        />
        <List.Item
          onPress={() => setTab(1)}
          title="Gestionar cursos acadèmics"
          description="Organitza cada any escolar"
          left={props => <List.Icon {...props} icon="calendar"/>}
        />
        <List.Item
          onPress={() => setTab(2)}
          title="Gestionar cursos"
          description="Organitza i administra els cursos de la llar d'infants"
          left={props => <List.Icon {...props} icon="baby"/>}
        />
        <List.Item
          onPress={() => setTab(3)}
          title="Gestionar famílies"
          description="Administra pares, mares i nens"
          left={props => <List.Icon {...props} icon="account-supervisor"/>}
        />
        <List.Item
          onPress={() => setTab(4)}
          title="Gestionar personal"
          description="Administra usuaris del centre"
          left={props => <List.Icon {...props} icon="account-group"/>}
        />
        <List.Item
          onPress={() => setTab(5)}
          title="Control de notificacions"
          description="Llista i crea notificacions"
          left={props => <List.Icon {...props} icon="bullhorn"/>}
        />
        <List.Item
          onPress={() => navigation.navigate('Home')}
          title="Tornar a l'agenda"
          left={props => <List.Icon {...props} icon="arrow-left"/>}
        />
      </View>

      <View style={styles.main}>
        {tab === 0 && <Dashboard />}
        {tab === 1 && <ManageYears />}
        {tab === 2 && <ManageCourses currentSchoolYear={selectedYearObj} />}
        {tab === 3 && <ManageFamilies currentSchoolYear={selectedYearObj} />}
        {tab === 4 && <ManageEmployees />}
        {tab === 5 && <ManageNotifications currentSchoolYear={selectedYearObj} />}
      </View>

    </SafeAreaView>
  );

};

const styles = StyleSheet.create({
                                   container: {
                                     flex: 1,
                                     flexDirection: 'row',
                                     margin: 20,
                                     backgroundColor: 'white',
                                     borderRadius: 12
                                   },
                                   sidebar: {
                                     flex: 1,
                                     padding: 12,
                                     borderRightColor: 'silver',
                                     borderRightWidth: 1.0
                                   },
                                   sidebarHeading: {
                                     alignItems: 'center',
                                     justifyContent: 'center',
                                     marginTop: 6,
                                     marginBottom: 12,
                                   },
                                   sidebarHeaderText: {
                                     fontFamily: 'Rubik-Black',
                                     fontSize: 12,
                                   },
                                   sidebarHeaderTitle: {
                                     fontFamily: 'Rubik-Black',
                                     fontSize: 24,
                                   },
                                   main: {
                                     flex: 5,
                                     padding: 12,
                                     backgroundColor: 'transparent'
                                   }
                                 });

export default AdminScreen;
