import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Surface, Text, Button, Paragraph, Portal, Dialog } from "react-native-paper";
import { SimpleLineIcons } from '@expo/vector-icons';
import { formatDistanceToNowStrict } from 'date-fns'
import ca from 'date-fns/locale/ca/index';
import { useSelector } from "react-redux";

const RenderNotificationItem = ({ item, unread, onDeleteNotification, onRender }) => {
  const user = useSelector(state => state.user);
  const [showDelete, setDelete] = useState(false);

  useEffect(() => {
    if(onRender) {
      onRender();
    }
  }, []);

  return (
    <>
      <Surface style={{
        width: '100%',
        padding: 12,
        borderRadius: 12,
        flexDirection: 'row',
        marginBottom: 12,
        ...!unread && { opacity: 0.5 }
      }}>
        <View style={{
          padding: 12
        }}>
          <SimpleLineIcons name={'envelope-letter'} size={24} color={'#47b000'}/>
        </View>
        <View style={{
          padding: 12,
          flex: 1
        }}>
          <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Text style={{
              flex: 2,
              fontFamily: 'Rubik-Bold'
            }}>{item.title}</Text>
            <Text style={{
              flex: 0.5,
              textAlign: 'right',
              fontFamily: 'Rubik-Light',
              fontSize: 12
            }}>fa {formatDistanceToNowStrict(new Date(item.createdAt), {
              locale: ca
            })}</Text>
          </View>
          <Text style={{
            fontFamily: 'Rubik-Light', marginTop: 6
          }}>{item.text}</Text>
          {(user.isAdministration && onDeleteNotification) &&
          <View style={{
            marginTop: 8,
            paddingTop: 8,
            borderTopWidth: 1.0,
            borderTopColor: 'silver',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Text>Enviat per: {item.sendByUser ? item.sendByUser.name : ' - err - '}</Text>
            <Button onPress={() => setDelete(true)} mode="text" icon="delete" labelStyle={{
              fontSize: 12
            }} compact>Esborrar notificació</Button>
          </View>}
        </View>
      </Surface>

      <Portal>
        <Dialog style={{
          width: '33%',
          alignSelf: 'center'
        }} visible={showDelete} onDismiss={() => setDelete(false)}>
          <Dialog.Title style={{
            fontFamily: 'Rubik-Bold'
          }}>Atenció!!</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Aquesta acció es irreversible, estàs segur/a de continuar?</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => {
              setDelete(false);
            }}>Cancel·lar</Button>
            <Button onPress={() => {
              onDeleteNotification(item.id);
              setDelete(false);
            }}>Continuar</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

    </>
  );
};

RenderNotificationItem.defaultProps = {
  unread: true
};

export default RenderNotificationItem;
