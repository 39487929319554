import React, { useState, useEffect } from 'react';
import { StyleSheet, View } from "react-native";
import { Avatar, Subheading, Surface, Text, Title, useTheme } from "react-native-paper";
import { eachDayOfInterval, endOfWeek, startOfToday, startOfWeek } from "date-fns";
import ca from 'date-fns/locale/ca/index';
import { TouchableRipple } from 'react-native-paper';
import DailyControl from "../components/dailyControl/DailyControl";
import { moderateScale } from 'react-native-size-matters';
import { SimpleLineIcons } from '@expo/vector-icons';
import { NAV_BAR_HEIGHT } from "../utiles/CalculateNavbarHeight";
import HeaderBar from "../components/sons/HeaderBar";
import services from "../services";
import AppLoading from "../components/loading/AppLoading";

const ChildDetails = ({ route, navigation }) => {
  const { colors } = useTheme();
  const { childId, familyTitle } = route.params;
  const today = startOfToday();
  const [currentDay, setCurrentDay] = useState(today);
  const [currentWeek, setCurrentWeek] = useState(eachDayOfInterval({
                                                                     start: startOfWeek(today, {
                                                                       locale: ca
                                                                     }),

                                                                     end: endOfWeek(today, {
                                                                                      locale: ca
                                                                                    }
                                                                     )
                                                                   }));
  const [isLoading, setLoading] = useState(true);
  const [student, setStudent] = useState([]);

  useEffect(() => {
    setLoading(true);
    services.students.get(childId).then((res) => {
      setStudent(res);
      setLoading(false);
    });
  }, [childId]);


  const styles = StyleSheet.create({
                                     container: {
                                       flex: 1,
                                       paddingTop: NAV_BAR_HEIGHT,
                                       backgroundColor: colors.primary
                                     },

                                     userTopLabel: {
                                       color: '#757575',
                                       fontFamily: 'Rubik-Medium',
                                       fontSize: moderateScale(14, 0.1),
                                     },


                                     userSubsubheadingLabel: {
                                       fontFamily: 'Rubik-Light',
                                       fontSize: moderateScale(10, 0.1)
                                     },
                                     surfaceContainer: {
                                       marginHorizontal: 0,
                                       elevation: 0,
                                       backgroundColor: 'white',
                                       padding: 6.0,
                                       flexDirection: 'row',
                                       alignItems: 'center'
                                     },


                                     childBoxes: {
                                       flexDirection: 'row',
                                       marginHorizontal: 12.0,
                                     },

                                     boxContainer: {
                                       flex: 1,
                                       borderRadius: 12,
                                       backgroundColor: 'white',
                                       margin: 16.0,
                                       paddingHorizontal: 6.0,
                                       paddingVertical: 24.0,
                                       elevation: 1,
                                       justifyContent: 'center',
                                       alignItems: 'center'
                                     },

                                     boxIcon: {
                                       alignItems: 'center',
                                       padding: 12.0
                                     },

                                     boxText: {
                                       textTransform: 'uppercase',
                                       fontFamily: 'Rubik-Light',
                                       fontSize: moderateScale(16, 0.1),
                                       textAlign: 'center'
                                     }

                                   });

  const handleChangeCurrentDay = (date) => {
    setCurrentDay(date);
  };

  const goToAllergens = () => {
    navigation.navigate('Allergens', {
      childId, familyTitle
    });
  };

  const goToNotifications = () => {
    navigation.navigate('ChildrenNotifications', {
      childId, familyTitle
    });
  };

  const goToAgenda = () => {
    navigation.navigate('Agenda', {
      childId, familyTitle
    });
  };

  const goToDailyControl = () => {
    navigation.navigate('DailyControl', {
      childId, familyTitle
    });
  };

  return (
    <View style={styles.container}>

      {isLoading && <AppLoading/>}
      {!isLoading && (
        <>

          <HeaderBar student={student} family={familyTitle} />

          <View style={{
            flex: 1,
            alignItems: 'stretch',
            justifyContent: 'center'
          }}>
            <View style={styles.childBoxes}>
              <TouchableRipple onPress={goToAllergens} style={[styles.boxContainer, { cursor: 'pointer' }]}>
                <>
                  <View style={styles.boxIcon}>
                    <SimpleLineIcons name={'heart'} size={36} color={colors.primary}/>
                  </View>
                  <Text style={styles.boxText}>al·lèrgens</Text>
                </>
              </TouchableRipple>
              <TouchableRipple onPress={goToNotifications} style={[styles.boxContainer, {cursor: 'pointer' }]}>
                <>
                  <View style={styles.boxIcon}>
                    <SimpleLineIcons name={'bell'} size={36} color={colors.primary}/>
                  </View>
                  <Text style={styles.boxText}>notificacions</Text>
                </>
              </TouchableRipple>
            </View>
            <View style={styles.childBoxes}>
              <TouchableRipple onPress={goToAgenda} style={[styles.boxContainer, { cursor: 'pointer' }]}>
                <>
                  <View style={styles.boxIcon}>
                    <SimpleLineIcons name={'calendar'} size={36} color={colors.primary}/>
                  </View>
                  <Text style={styles.boxText}>Comunicacions</Text>
                </>
              </TouchableRipple>
              <TouchableRipple onPress={goToDailyControl} style={[styles.boxContainer, { cursor: 'pointer' }]}>
                <>
                  <View style={styles.boxIcon}>
                    <SimpleLineIcons name={'notebook'} size={36} color={colors.primary}/>
                  </View>
                  <Text style={styles.boxText}>control diari</Text>
                </>
              </TouchableRipple>
            </View>
          </View>
        </>
      )}

      {/*<View style={{ marginTop: 12 }}>
        <DailyControl selectedDay={currentDay}/>
      </View>*/}
    </View>
  );
};

export default ChildDetails;
