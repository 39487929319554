import services from "../../services";

export const setCurrentSchoolYear = (schoolYearId) => {
  return dispatch => {
    dispatch({
               type: 'SET_SCHOOLYEAR',
               payload: schoolYearId
             });
  }
};

const setSchoolYears = (list) => {
  return dispatch => {
    dispatch({
               type: 'LOAD_YEARS_LIST',
               payload: list
             });
  }
};

export const initSchoolYear = () => {
  return dispatch => {
    services.schoolyears.find({
                                query: {
                                  paginate: false,
                                  $sort: { createdAt: -1 }
                                }
                              }).then((res) => {
                                dispatch(setSchoolYears(res));
                                dispatch(setCurrentSchoolYear(res[0]));
    })

  };
};
