import React from 'react';
import { Calendar, CalendarList, LocaleConfig } from 'react-native-calendars';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { ScrollView, Dimensions } from 'react-native';
import { useTheme } from 'react-native-paper';

//LocaleConfig.defaultLocale = 'ca';

const ExpandCalendarScreen = () => {
    const { colors } = useTheme();
    const windowHeight = Dimensions.get('window').height
    return (
        <ScrollView style={{
            height: 300,
        }}>
            <CalendarList
            horizontal={false}
                style={{
                    height: windowHeight
                }}
                testID={'expandCalendar'}
                current={'2020-06-10'}
                pastScrollRange={24}
                futureScrollRange={24}
                renderHeader={(date) => {
                    const header = date.toString('MMMM yyyy');
                    const [month, year] = header.split(' ');
                    const textStyle = {
                        fontSize: 18,
                        fontWeight: 'bold',
                        paddingTop: 10,
                        paddingBottom: 10,
                        color: colors.accent,
                        paddingRight: 5
                    };

                    return (
                        <View style={{
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginTop: 10,
                            marginBottom: 10
                        }}>
                            <Text style={{ marginLeft: 5, ...textStyle }}>{`${month}`}</Text>
                            <Text style={{ marginRight: 5, ...textStyle }}>{year}</Text>
                        </View>
                    );
                }}
                theme={{
                    'stylesheet.calendar.header': {
                        dayHeader: {
                            fontWeight: '600',
                            color: colors.accent
                        }
                    },
                    'stylesheet.day.basic': {
                        today: {
                            backgroundColor: colors.accent,
                            borderRadius: 8,
                            borderWidth: 0
                        },
                        todayText: {
                            color: 'white',
                            fontWeight: '800'
                        }
                    }
                }}
            />
        </ScrollView>
    );
};

export default ExpandCalendarScreen;