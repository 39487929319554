import React, { useState, useEffect } from 'react';
import services from "../../services";
import AppLoading from "../loading/AppLoading";
import RenderSelectableClassroomItem from "./RenderSelectableClassroomItem";

const  RenderSelectableClassroom = ({ course, courseId, isSelected, handleOnPress }) => {
  const [classroom, setClassroom] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(course) {
      setClassroom(course);
      setLoading(false);
    } else {
      services.classrooms.get(courseId).then((res) => {
        setClassroom(res);
        setLoading(false);
      });
    }
  }, [course, courseId]);

  return (
    <>
      {loading && <AppLoading/>}
      {!loading &&  <RenderSelectableClassroomItem
        item={classroom}
        isSelected={isSelected}
        handleOnPress={handleOnPress}
      />}
    </>
  );

};

export default RenderSelectableClassroom;
