import React, { useState } from 'react';
import { Snackbar, Portal } from 'react-native-paper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { StyleSheet, ImageBackground, View, Platform } from 'react-native';
import bgContent from '../assets/bg/bg-login.png';
import RecoveryStepOne from '../components/recovery/RecoveryStepOne';
import RecoveryStepTwo from '../components/recovery/RecoveryStepTwo';
import RecoveryStepThree from '../components/recovery/RecoveryStepThree';

const RecoveryScreen = () => {
    

    const [email, setEmail] = useState('');
    const [pwd, setPassword] = useState('');
    const [displayError, setDisplayError] = useState(false);
    const [step, setStep] = useState(1);

    return (
        <KeyboardAwareScrollView
            resetScrollToCoords={{ x: 0, y: 0 }}
            automaticallyAdjustContentInsets={true}
            contentContainerStyle={{
                backgroundColor: '#47b000',
                flexGrow: 1,
                justifyContent: 'center'
            }}
            scrollEnabled>
            <ImageBackground
                source={bgContent}
                resizeMode='cover'
                style={styles.background}
            >

                {step === 1 && <RecoveryStepOne setDisplayError={setDisplayError} goNext={(e) => {
                    setEmail(e);
                    setStep(2);
                    }} styles={styles} />}
                {step === 2 && <RecoveryStepTwo setDisplayError={setDisplayError} goNext={(e) => { 
                    setPassword(e);
                    setStep(3);
                    }} styles={styles} />}
                {step === 3 && <RecoveryStepThree email={email} password={pwd} setDisplayError={setDisplayError} goNext={() => setStep(1)} styles={styles} />}

            </ImageBackground>
            <Portal>

                <Snackbar
                    theme={{
                        colors: {
                            onSurface: "rgba(204, 0, 0, 0.8)",
                        },
                    }}
                    visible={displayError !== false}
                    onDismiss={() => setDisplayError(false)}
                    action={{
                        label: 'OK',
                        onPress: () => {
                            setDisplayError(false)
                        },
                    }}>
                    {displayError}
                </Snackbar>

            </Portal>
        </KeyboardAwareScrollView>
    );

};

const styles = StyleSheet.create({
    background: {
        flex: 1, width: '100%', height: '100%', padding: 0
    },
    contentContainer: {
        flex: 1,
        padding: 12,
        paddingBottom: 0,
        alignSelf: 'center',
        alignItems: 'center',
        width: (Platform.OS === 'web') ? '33%' : '100%',
        justifyContent: (Platform.OS === 'web') ? 'center' : 'flex-end',
    },
    title: {
        fontFamily: 'Rubik-Bold',
    },
    subtitle: {
        fontFamily: 'Rubik-Light',
        fontSize: 14,
        ...Platform.OS === 'web' ? { lineHeight: '1.25' } : {},
        marginBottom: 16
    },
    formContainer: {
        width: '100%',
        backgroundColor: '#fff',
        elevation: 0,
        padding: 24,
        borderRadius: 24,
        borderBottomLeftRadius: (Platform.OS === 'web') ? 24 : 0,
        borderBottomRightRadius: (Platform.OS === 'web') ? 24 : 0,
    },
    btnSubmit: {
        flex: 1,
        alignSelf: 'center',
    },
    btnRecoveryLabel: {
        fontSize: 14
    },
    btnSubmitLabel: {
        color: 'white'
    },
    inputContainer: {
        borderRadius: 12,
        overflow: 'hidden',
        marginBottom: 12
    },
    textInput: {
        borderRadius: 12,
        backgroundColor: '#dbf496',
    },
    cell: {
      width: 42,
      height: 42,
      lineHeight: 38,
      fontSize: 24,
      borderBottomWidth: 2,
      backgroundColor: '#dbf496',
      borderColor: '#47b000',
      textAlign: 'center',
    },
    focusCell: {
      borderColor: '#000',
    },
});

export default RecoveryScreen;