import AsyncStorage from '@react-native-community/async-storage';
import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';

/*
const socket = io('http://192.168.1.97:3030', {
  transports: ['websocket'],
  forceNew: true
});
*/

const socket = io('https://granota-backend.bayso.eu/', {
  transports: ['websocket'],
  forceNew: true
});

const client = feathers().configure(socketio(socket)).configure(auth({ storage: AsyncStorage }));

export default client;
