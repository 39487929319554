import React, { useState, useEffect } from "react";
import { moderateScale } from 'react-native-size-matters';
import { View, StyleSheet } from "react-native";
import { Avatar, Text, TouchableRipple, useTheme } from "react-native-paper";
import RenderStudent from "../RenderStudent";

const RenderNavbarSonItem = (props) => {
  const { colors } = useTheme();
  const { isSelected, item, handleOnPress, currentSchoolYear, showCourse, showFamily } = props;
  const { id, avatar, name, classrooms, family } = item;
  const [selClassroom, setClassroom] = useState(false);

  useEffect(() => {
    if (classrooms) {
      let c = classrooms.find(e => e.schoolyearId === currentSchoolYear);
      if (c) {
        setClassroom(c.classroom_name)
      }
    }
  }, [classrooms]);

  const styles = StyleSheet.create({
                                     container: {
                                       flex: 1,
                                       padding: 8,
                                       backgroundColor: isSelected ? colors.accent : 'white',
                                       borderRadius: 12,
                                     },
                                     parent: {
                                       flexDirection: 'row',
                                     },
                                     content: {
                                       justifyContent: 'center',
                                       alignItems: 'flex-start',
                                       marginLeft: 6
                                     },
                                     sonName: {
                                       color: isSelected ? 'white' : colors.text,
                                       fontFamily: 'Rubik-Medium',
                                       fontSize: moderateScale(16, 0.1),
                                       marginBottom: 2.0
                                     },
                                     sonClassroom: {
                                       color: isSelected ? 'white' : colors.text,
                                       fontFamily: 'Rubik-Light',
                                       fontSize: moderateScale(12, 0.1)
                                     }
                                   });

  return (
    <TouchableRipple onPress={() => handleOnPress(id)} style={[styles.container, { cursor: 'pointer' }]}>
      <View style={styles.parent}>
        {!avatar && <Avatar.Text size={36} label={name.toUpperCase().substring(0, 2)}/>}
        {avatar && <Avatar.Image size={36} source={{
          uri: `https://agenda.granota.cat/avatars/${avatar}`
        }}/>}
        <View style={styles.content}>
          <Text style={styles.sonName}>{name}</Text>
          {showFamily &&
          <Text
            style={[styles.sonClassroom, !family ? { color: 'orangered' } : {}]}>{family ? (family.firstName + ' ' + family.lastName) : '- sense família -'}</Text>}
          {showCourse &&
          <Text
            style={[styles.sonClassroom, !selClassroom ? { color: 'orangered' } : {}]}>{selClassroom ? selClassroom : '- sense curs assignat -'}</Text>}
        </View>
      </View>
    </TouchableRipple>
  );
};

RenderNavbarSonItem.defaultProps = {
  showFamily: false,
  showCourse: true
};

export default RenderNavbarSonItem;
