import React from "react";
import { Avatar, Text, TouchableRipple, useTheme, Checkbox } from "react-native-paper";
import { StyleSheet, View } from "react-native";
import { moderateScale } from "react-native-size-matters";

const RenderSelectableClassroomItem = (props) => {
  const { colors } = useTheme();
  const { isSelected, item, handleOnPress } = props;
  const { id, classroom_name } = item;

  const styles = StyleSheet.create({
                                     container: {
                                       marginVertical: 6,
                                       padding: 8,
                                       backgroundColor: isSelected ? colors.accent : 'white',
                                       borderRadius: 12,
                                     },
                                     parent: {
                                       flex: 1,
                                       flexDirection: 'row',
                                     },
                                     content: {
                                       justifyContent: 'center',
                                       alignItems: 'flex-start',
                                       marginLeft: 6
                                     },
                                     sonName: {
                                       color: isSelected ? 'white' : colors.text,
                                       fontFamily: 'Rubik-Medium',
                                       fontSize: moderateScale(16, 0.1),
                                       marginBottom: 2.0
                                     },
                                     sonClassroom: {
                                       color: isSelected ? 'white' : colors.text,
                                       fontFamily: 'Rubik-Light',
                                       fontSize: moderateScale(12, 0.1)
                                     }
                                   });

  return (
    <TouchableRipple onPress={() => handleOnPress(id)} style={[styles.container, { cursor: 'pointer' }]}>
      <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <View style={{
          width: 48,
          alignItems: 'center',
          marginRight: 12,
          backgroundColor: 'white',
          borderRadius: 12
        }}>
          <Checkbox.Item
            labelStyle={{
              width: 0
            }}
            status={isSelected ? "checked" : "unchecked"}/>
        </View>
        <View style={styles.parent}>
          <View style={styles.content}>
            <Text style={styles.sonName}>{classroom_name}</Text>
          </View>
        </View>
      </View>
    </TouchableRipple>
  );
};

export default RenderSelectableClassroomItem;
