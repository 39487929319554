import React from "react";
import { Surface, Text } from "react-native-paper";
import { View, StyleSheet } from "react-native";

const RenderMedicamentItem = ({ item }) => {
  return (
    <Surface style={{
      padding: 12,
      borderRadius: 12,
      marginBottom: 12
    }}>
      <View>
        <Text style={styles.captionLabel}>Medicament: </Text>
        <Text style={styles.captionValue}>{item.medicament}</Text>
      </View>
      <View style={{
        marginTop: 12,
        flexDirection: 'row'
      }}>
        <View style={{ flex: 1 }}>
          <Text style={styles.captionLabel}>Dosi: </Text>
          <Text style={styles.captionValue}>{item.dosi}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.captionLabel}>Hora: </Text>
          <Text style={styles.captionValue}>{item.hora}</Text>
        </View>
      </View>
      {item.observacions.length > 0 && (
        <View style={{
          marginTop: 12
        }}>
          <Text style={styles.captionLabel}>Observacions: </Text>
          <Text style={styles.captionValue}>{item.observacions}</Text>
        </View>
      )}
    </Surface>
  );
};

const styles = StyleSheet.create({
                                   captionLabel: {
                                     fontSize: 14,
                                     fontFamily: 'Rubik-Light'
                                   },
                                   captionValue: {
                                     fontSize: 16,
                                     fontFamily: 'Rubik-Medium'
                                   }
                                 });

export default RenderMedicamentItem;
