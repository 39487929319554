import React, { useState } from "react";
import { View } from "react-native";
import { Button, TextInput, Dialog } from "react-native-paper";
import services from "../../../services";

const FormNewFamily = ({ visible, onDismiss, onComplete }) => {
  const [firstSurname, setFirstSurname] = useState('');
  const [secondSurname, setSecondSurname] = useState('');

  return (
    <Dialog style={{
      width: '33%',
      alignSelf: 'center'
    }} visible={visible} onDismiss={onDismiss}>
      <Dialog.Content>
        <View style={{
          flexDirection: 'row',
          marginBottom: 12
        }}>
          <TextInput
            style={{
              flex: 1,
              marginRight: 24
            }}
            label="Primer cognom"
            value={firstSurname}
            onChangeText={text => setFirstSurname(text)}
          />
          <TextInput
            label="Segon cognom"
            value={secondSurname}
            onChangeText={text => setSecondSurname(text)}
          />
        </View>
        <Button mode="contained" onPress={() => services.families.create({
                                                                           firstName: firstSurname,
                                                                           lastName: secondSurname
                                                                         }).then((res) => {
          onComplete();
        })}>
          Guardar
        </Button>
      </Dialog.Content>
    </Dialog>
  );

};

export default FormNewFamily;
