import services from "../../services";

export const fetchNotifications = (userId) => {
  return dispatch => {
    services.myNotifications.find({
                                    query: {
                                      userId: userId,
                                      $sort: { createdAt: -1 }
                                    }
                                  }).then((res) => {
      countUnreaded(userId).then((count) => {
        dispatch({
                   type: 'SET_NOTIFICATIONS_DATA',
                   payload: {
                     unreaded: count,
                     data: res.data,
                     total: res.total,
                     skip: res.skip
                   }
                 })
      });
    });
  };
};

export const notificationUpdateToReaded = (id) => {
  return (dispatch, getState) => {
    const { unreaded, data } = getState().notifications;

    services.myNotifications.patch(id, {
      unread: false
    }).then((res) => {
      let newUnreadedValue = unreaded;
      if (unreaded > 0) {
        newUnreadedValue = newUnreadedValue - 1;
      }
      let newArr = data.map((notification) => {
        if (notification.id === id) {
          notification.unread = false
        }
        return notification;
      });
      dispatch({
                 type: 'SET_NOTIFICATIONS_DATA',
                 payload: {
                   unreaded: newUnreadedValue,
                   data: newArr,
                 }
               })
    });
  };
};

const countUnreaded = (userId) => {
  return services.myNotifications.find({
                                         query: {
                                           userId: userId,
                                           unread: true,
                                         }
                                       }).then((res) => res.total);
}
