import React, { useState, useEffect } from "react";
import AppLoading from "../loading/AppLoading";
import services from "../../services";
import RenderSelectableTeacherItem from "./RenderSelectableTeacherItem";

const RenderSelectableTeacher = ({ user, teacherId, isSelected, handleOnPress }) => {
  const [teacher, setTeacher] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(user) {
      setTeacher(user);
      setLoading(false);
    } else {
      services.users.get(teacherId).then((res) => {
        setTeacher(res);
        setLoading(false);
      });
    }
  }, [user, teacherId]);

  return (
    <>
      {loading && <AppLoading/>}
      {!loading &&  <RenderSelectableTeacherItem
        item={teacher}
        isSelected={isSelected}
        handleOnPress={handleOnPress}
      />}
    </>
  );
};

export default RenderSelectableTeacher;
