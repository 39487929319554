import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { View, Image, Platform } from "react-native";
import { fetchInfo } from "../store/info/actions";
import Logo from '../assets/logo.png';
import { ActivityIndicator } from "react-native-paper";
import packageJson from '../../package.json';


const LoadingScreen = ({ navigation }) => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector(state => state.auth);

  const checkVersion = (versionA, versionB) => {
    return versionA === versionB;
  };

  const doFetchInfo = (retries = 10) => {
    dispatch(fetchInfo((info) => {
      if(checkVersion(info.versionApp, packageJson.version)) {
        if (loggedIn) {
          navigation.replace('Home');
        } else {
          navigation.replace('Login');
        }
      } else {
        if(Platform.OS === 'web') {
          if(caches) {
            caches.keys().then((names) => {
              for (let name of names) caches.delete(name);
            });
            window.location.reload();
          }
        } else {
          if (loggedIn) {
            navigation.replace('Home');
          } else {
            navigation.replace('Login');
          }
        }
      }
    }, (err) => {
      if(retries > 0) {
        setTimeout(() => doFetchInfo(retries - 1), 2000)
      } else {
        console.log(err)
      }
    }));
  }

  useEffect(() => {
    doFetchInfo();
  }, [loggedIn]);

  return (<View style={{
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#47b000'
  }}>
    <Image resizeMode={'contain'} style={{
      width: 128,
      height: 128
    }} source={Logo} />
    <ActivityIndicator animating={true} color={'white'} />
  </View>);
};

export default LoadingScreen;
