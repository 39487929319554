import React from 'react';
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";

const DashboardPanel = ({ count, title }) => {
  return (
    <View style={styles.card}>
      <Text style={styles.countText}>{count}</Text>
      <Text style={styles.titleText}>{title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
                                   card: {
                                     padding: 12,
                                     margin: 12,
                                     backgroundColor: '#a3d77f',
                                     borderRadius: 12,
                                     flex: 1,
                                     alignItems: 'center'
                                   },
                                   countText: {
                                     fontFamily: 'Rubik-Bold',
                                     color: 'white',
                                     fontSize: 48
                                   },
                                   titleText: {
                                     fontFamily: 'Rubik-Medium',
                                     color: 'white',
                                     fontSize: 24
                                   }
                                 });

export default DashboardPanel;
