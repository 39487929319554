import React from "react";
import { StyleSheet } from "react-native";
import { Button, useTheme } from "react-native-paper";

const ContainedButton = ({icon, lblButton, onPress}) => {
  const { colors } = useTheme();

  const styles = StyleSheet.create({
      baseButton: {
        backgroundColor: 'white'
      },
      baseLabel: {
        color: colors.primary
      }
                                   });

  return (
    <Button onPress={onPress} style={{ margin: 4.0 }} contentStyle={styles.baseButton} labelStyle={styles.baseLabel}  mode="contained" icon={icon}>
      {lblButton}
    </Button>
  );

};

ContainedButton.defaultProps = {
  icon: null,
  lblButton: ""
}

export default ContainedButton;
