import React, { useState, useEffect } from "react";
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useDispatch, useSelector } from "react-redux";
import * as Linking from 'expo-linking';
import { IconButton, Text } from "react-native-paper";
import LoginScreen from "./pages/LoginScreen";
import SignupScreen from "./pages/SignupScreen";
import HomeScreen from "./pages/HomeScreen";

import { reAuthenticate } from "./store/user/actions";
import LoadingScreen from "./pages/LoadingScreen";
import ChildDetails from "./pages/ChildDetails";
import AgendaScreen from "./pages/AgendaScreen";
import AllergensScreen from "./pages/AllergensScreen";
import DailyControlScreen from "./pages/DailyControlScreen";
import AdminScreen from "./pages/AdminScreen";
import NotificationsScreen from "./pages/NotificationsScreen";
import OneSignal from 'react-native-onesignal';
import { fetchNotifications } from "./store/notifications/actions";
import ClassroomScreen from "./pages/ClassroomScreen";
import StudentDetails from "./pages/StudentDetails";
import DailyControlTeacherScreen from "./pages/DailyControlTeacherScreen";
import AgendaTeacherScreen from "./pages/AgendaTeacherScreen";
import NotificationsTeacherScreen from "./pages/NotificationsTeacherScreen";
import ChildrenNotificationsScreen from "./pages/ChildrenNotificationsScreen";
import EditInfoScreen from './pages/EditInfoScreen';
import VerifyScreen from './pages/VerifyScreen';
import RecoveryScreen from './pages/RecoveryScreen';
import ExpandCalendarScreen from './pages/ExpandCalendarScreen';

const Stack = createStackNavigator();
const prefix = Linking.makeUrl('/');

const MainRoutes = () => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector(state => state.auth);
  const user = useSelector(state => state.user);
  const { isAdministration, isEmployee } = useSelector(state => state.user);

  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        Verify: 'verify/:token?'
      }
    }
  };

  useEffect(() => {
    dispatch(reAuthenticate());
  }, []);

  useEffect(() => {
    console.log('MainRoutes OneSignal init')
    //Remove this method to stop OneSignal Debugging
    OneSignal.setLogLevel(6, 0);


    // Replace 'YOUR_ONESIGNAL_APP_ID' with your OneSignal App ID.
    OneSignal.init("7b5504ba-5e5c-43b1-bc78-c1ea35a61680", {kOSSettingsKeyAutoPrompt : false, kOSSettingsKeyInAppLaunchURL: false, kOSSettingsKeyInFocusDisplayOption:2});
    OneSignal.inFocusDisplaying(2); // Controls what should happen if a notification is received while the app is open. 2 means that the notification will go directly to the device's notification center.

    // Setting requestPermissions
    const permissions = {
      alert: true,
      badge: true,
      sound: true
    };
    OneSignal.requestPermissions(permissions);

    // The promptForPushNotifications function code will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step below)
    //OneSignal.promptForPushNotificationsWithUserResponse(myiOSPromptCallback);

    OneSignal.addEventListener('received', onReceived);
    OneSignal.addEventListener('opened', onOpened);
    OneSignal.addEventListener('ids', onIds);

    return () => {
      OneSignal.removeEventListener('received', onReceived);
      OneSignal.removeEventListener('opened', onOpened);
      OneSignal.removeEventListener('ids', onIds);
    }
  }, []);

  const onReceived = (notification) => {
    console.log("Notification received: ", notification);
    dispatch(fetchNotifications(user.id));
  };

  const onOpened = (openResult) => {
    console.log('Message: ', openResult.notification.payload.body);
    console.log('Data: ', openResult.notification.payload.additionalData);
    console.log('isActive: ', openResult.notification.isAppInFocus);
    console.log('openResult: ', openResult);
  };

  const onIds = (device) => {
    console.log('Device info: ', device);
  };

  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <Stack.Navigator initialRouteName={"Loading"}>
        <Stack.Screen name="Verify" path={'verify/:token'} component={VerifyScreen}  options={{
             headerShown: false,
           }}/>
           <Stack.Screen name="Recovery" component={RecoveryScreen} options={{
             headerShown: false
           }}/>
        <Stack.Screen name="Loading" component={LoadingScreen} options={{
          headerShown: false
        }}/>
        {loggedIn ?

         <>
           <Stack.Screen
             name="Home" component={HomeScreen}
             options={{
               headerShown: false,
               headerRight: () => (
                 <IconButton
                   icon="power"
                   color="red"
                   size={20}
                   onPress={() => console.log('Pressed')}
                 />
               ),
             }}
           />
           <Stack.Screen name="EditInfo" component={EditInfoScreen} options={{
             headerShown: false
           }}
            />
           <Stack.Screen name="ChildDetails" component={ChildDetails} options={{
             headerShown: false,
           }}/>
           <Stack.Screen name="ChildrenNotifications" component={ChildrenNotificationsScreen} options={{
              headerShown: false
            }}/>
           <Stack.Screen name="Agenda" component={AgendaScreen} options={{
             headerShown: false,
           }}/>
           <Stack.Screen name="Allergens" component={AllergensScreen} options={{
             headerShown: false,
           }}/>
           <Stack.Screen name="DailyControl" component={DailyControlScreen} options={{
             headerShown: false,
           }}/>
           <Stack.Screen name="Notifications" component={NotificationsScreen} options={{
             title: 'Notificacions',
             headerShown: true,
           }}/>
           <Stack.Screen name="ExpandCalendar" component={ExpandCalendarScreen} options={{
             title: 'Prueba',
             headerShown: true
           }} />
           {isAdministration && <Stack.Screen name="Administration" component={AdminScreen} options={{
             headerShown: false,
           }}/>}
           {isEmployee && <Stack.Screen name="ClassroomScreen" component={ClassroomScreen} options={{
             headerShown: false,
           }}/>}
           {isEmployee && <Stack.Screen name="StudentDetails" component={StudentDetails} options={{
             headerShown: false,
           }}/>}
           {isEmployee && <Stack.Screen name="DailyControlTeacherScreen" component={DailyControlTeacherScreen} options={{
             headerShown: false,
           }}/>}
           {isEmployee && <Stack.Screen name="AgendaTeacherScreen" component={AgendaTeacherScreen} options={{
             headerShown: false,
           }}/>}
           {isEmployee && <Stack.Screen name="NotificationsTeacher" component={NotificationsTeacherScreen} options={{
             headerShown: false,
           }}/>}
         </>

                  :

         <>
           <Stack.Screen name="Login" component={LoginScreen} options={{
             headerShown: false
           }}/>
           <Stack.Screen name="Signup" component={SignupScreen}/>
         </>

        }
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default MainRoutes;
