import React, { useState } from "react";
import { Button, Text, TextInput, Dialog } from "react-native-paper";
import { View } from "react-native";
import services from "../../../services";

const FormNewUser = ({ visible, familyTitle, familyId, onDismiss, onComplete }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);

  const associateUserFamily = (userId) => {
    return services.userFamily.create({
                                        userId,
                                        familyId
                                      });
  };

  return (
    <Dialog style={{
      width: '33%',
      alignSelf: 'center'
    }} visible={visible} onDismiss={onDismiss}>
      <Dialog.Content>
        <Text style={{ fontSize: 24 }}>Credencials per a la família: {familyTitle}</Text>
        <View style={{
          flexDirection: 'column',
          marginVertical: 12
        }}>
          <TextInput
            style={{
              flex: 1,
              marginBottom: 12
            }}
            label="Nom"
            value={username}
            onChangeText={text => setUsername(text)}
          />
          <TextInput
            style={{
              flex: 1,
            }}
            label="Correu electrònic"
            value={email}
            onChangeText={text => setEmail(text)}
          />
        </View>
        <Button loading={isLoading} mode="contained" onPress={() => {
          setLoading(true);
          services.users.create({
                                  name: username,
                                  email: email,
                                  password: ""
                                }).then((res) => {
            associateUserFamily(res.id).then((res) => {
              setLoading(false);
              setUsername('');
              setEmail('');
              onComplete();
              onDismiss();
            });
          }).catch((err) => {
            if (err.message === 'El correu electrònic actualment ja existeix al sistema.') {
              services.users.find({
                                    query: {
                                      email: email
                                    }
                                  }).then((res) => {
                if (res.total > 0) {
                  associateUserFamily(res.data[0].id).then((res) => {
                    setLoading(false);
                    setUsername('');
                    setEmail('');
                    onComplete();
                    onDismiss();
                  });
                }
              })
            } else {
              alert(err.message);
              setEmail('');
              setLoading(false);
            }
          });
        }}>
          Enviar correu amb enllaç
        </Button>
      </Dialog.Content>
    </Dialog>
  );
};

export default FormNewUser;
