const initialState = {
  versionBackend: null,
  versionApp: null,
  currentYearId: null
};

const infoReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SET_INFO':
      return action.payload;
    case 'RESET_INFO':
      return initialState;
    default:
      return state
  }
};

export default infoReducer;
