import React, { useState, useEffect } from 'react';
import { View } from "react-native";
import { Button, Text } from "react-native-paper";
import services from "../../../services";

const CourseRow = ({ course, currentSchoolYear, onManageStudents, onManageTeachers , onDeleteItem, styles }) => {
  const [countAlumnes, setCountAlumnes] = useState(0);
  const [countTeachers, setCountTeachers] = useState(0);

  useEffect(() => {
    services.classroomStudent.find({
      query: {
        classroomId: course.id,
        schoolyearId: currentSchoolYear
      }
                                   }).then((res) => setCountAlumnes(res.total));
    services.classroomTeacher.find({
                                     query: {
                                       classroomId: course.id,
                                       schoolyearId: currentSchoolYear
                                     }
                                   }).then((res) => setCountTeachers(res.total));
  }, []);

  return (
    <View key={course.id} style={styles.tableRow}>
      <View style={styles.tableItem}>
        <Text style={styles.tableItem}>{course.classroom_name}</Text>
      </View>
      <View style={styles.tableItem}>
        <Text style={styles.tableItem}>{countAlumnes}</Text>
      </View>
      <View style={styles.tableItem}>
        <Text style={styles.tableItem}>{countTeachers}</Text>
      </View>
      <View style={[styles.tableItem, {
        flex: 3,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }]}>
        <Button compact mode="text" icon="school" onPress={() => onManageStudents(course.id, currentSchoolYear)}>Gestionar
          alumnes</Button>
        <Button compact mode="text" icon="account-group" onPress={() => onManageTeachers(course.id)}>Gestionar
          professors</Button>
        <Button compact mode="text" icon="delete" onPress={() => onDeleteItem(course.id)}>Esborrar curs</Button>
      </View>
    </View>
  );
};

export default CourseRow;
