import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TextInput, Platform, ScrollView } from "react-native";
import { Surface, Text, TextInput as PaperTextInput, useTheme, Button, TouchableRipple } from "react-native-paper";
import { moderateScale } from "react-native-size-matters";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import HeadingSection from "../components/headings/HeadingSection";
import CalendarWeekDays from "../components/calendar/CalendarWeekDays";
import { eachDayOfInterval, endOfWeek, startOfToday, startOfWeek, isSameWeek } from "date-fns";
import ca from 'date-fns/locale/ca/index';
import { NAV_BAR_HEIGHT } from "../utiles/CalculateNavbarHeight";
import HeaderBar from "../components/sons/HeaderBar";
import { FontAwesome5 } from '@expo/vector-icons';
import services from "../services";
import AppLoading from "../components/loading/AppLoading";
import DeposicioController from '../components/deposicions/DeposicioController';
import { format } from 'date-fns-tz';

// Tot, Bastant, Poc, - Buit -

const DailyControlTeacherScreen = ({ route, navigation }) => {
  const { colors } = useTheme();
  const { studentId } = route.params;

  const [dailyId, setDailyId] = useState(null);
  const [esmorzar, setEsmorzar] = useState('');
  const [dinar, setDinar] = useState('');
  const [berenar, setBerenar] = useState('');
  const [alimentacio, setAlimentacio] = useState(1);
  const [dormirMati, setDormirMati] = useState('');
  const [dormirTarda, setDormirTarda] = useState('');
  const [observacions, setObservacions] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [deposicionsNormals, setDeposicionsNormals] = useState(0);
  const [deposicionsToves, setDeposicionsToves] = useState(0);
  const [deposicionsLiquides, setDeposicionsLiquides] = useState(0);

  const today = startOfToday();
  const [currentDay, setCurrentDay] = useState(today);
  const [currentWeek, setCurrentWeek] = useState(eachDayOfInterval({
    start: startOfWeek(today, {
      locale: ca
    }),

    end: endOfWeek(today, {
      locale: ca
    }
    )
  }));

  const [isLoading, setLoading] = useState(true);
  const [fetchingDailyControl, setFetchingDailyControl] = useState(true);
  const [student, setStudent] = useState([]);

  const fetchDailyControl = (student, date) => {
    setFetchingDailyControl(true);
    services.dailyControl.find({
      query: {
        date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
        studentId: student,
        $sort: {
          createdAt: -1,
        },
      }
    }).then((res) => {
      if (res.total > 0) {
        let item = res.data[0];
        console.log('item?', item)
        setDailyId(item.id);
        setEsmorzar(item.esmorzar);
        setDinar(item.dinar);
        setBerenar(item.berenar);
        setAlimentacio(item.tipus_alimentacio);
        setDormirMati(item.mati_dormir);
        setDormirTarda(item.tarda_dormir);
        setDeposicionsNormals(item.deposicio_normal);
        setDeposicionsToves(item.deposicio_tova);
        setDeposicionsLiquides(item.deposicio_liquida)
        setObservacions(item.observacions_centre);
      } else {
        setDailyId(null);
        setEsmorzar('');
        setDinar('');
        setBerenar('');
        setDeposicionsNormals(0);
        setDeposicionsToves(0);
        setDeposicionsLiquides(0);
        setAlimentacio(1);
        setDormirMati('');
        setDormirTarda('');
        setObservacions('');
      }
      setFetchingDailyControl(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    services.students.get(studentId).then((res) => {
      setStudent(res);
      fetchDailyControl(studentId, currentDay);
      setLoading(false);
    });
  }, [studentId]);

  useEffect(() => {
    fetchDailyControl(studentId, currentDay);
  }, [currentDay]);

  const handleSubmit = () => {
    setSubmitting(true);
    if (dailyId) {
      services.dailyControl.patch(dailyId, {
        studentId: studentId,
        date: format(currentDay, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
        esmorzar: esmorzar,
        dinar: dinar,
        berenar: berenar,
        tipus_alimentacio: alimentacio,
        mati_dormir: dormirMati,
        tarda_dormir: dormirTarda,
        deposicio_normal: deposicionsNormals,
        deposicio_tova: deposicionsToves,
        deposicio_liquida: deposicionsLiquides,
        observacions_centre: observacions
      }).then((res) => {
        setSubmitting(false);
        alert('Les dades s\'han deixat correctament.');
      });
    } else {
      services.dailyControl.create({
        studentId: studentId,
        date: format(currentDay, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
        esmorzar: esmorzar,
        dinar: dinar,
        berenar: berenar,
        tipus_alimentacio: alimentacio,
        mati_dormir: dormirMati,
        tarda_dormir: dormirTarda,
        deposicio_normal: deposicionsNormals,
        deposicio_tova: deposicionsToves,
        deposicio_liquida: deposicionsLiquides,
        observacions_centre: observacions
      }).then((res) => {
        setSubmitting(false);
        alert('Les dades s\'han deixat correctament.');
      });
    }
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      paddingTop: NAV_BAR_HEIGHT,
      backgroundColor: colors.primary
    },
    calendarHeaderWeekContainer: {
      elevation: 0,
      padding: 8.0,
      marginHorizontal: 12,
      marginTop: 4,
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12
    },
    calendarObj: {
      flex: 1,
      padding: 8.0,
      marginHorizontal: 12,
      marginBottom: 12,
      backgroundColor: colors.accent,
      borderBottomRightRadius: 12,
      borderBottomLeftRadius: 12
    },
    sectionContainer: {

      elevation: 0,
      margin: 12,
      marginTop: 0,
      paddingVertical: 16,
      borderRadius: 12
    },
    sectionInlineContent: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    medicacioButtonAdd: {
      padding: 12.0,
      borderRadius: 12,
      backgroundColor: '#e8e8e8'
    },
    sectionInlineTitle: {
      fontSize: moderateScale(14, 0.1),
      textAlign: 'right',
      fontFamily: 'Rubik-Bold',
      marginRight: 8
    },
    sectionInlineValue: {
      fontSize: moderateScale(14, 0.1),
      fontFamily: 'Rubik-Light'
    },
    superTextInputStyle: {
      width: '100%',
      height: 100,
      padding: 12,
      ...Platform.select({
        android: {
          textAlignVertical: "top"
        }
      })
    },
    textInputStyle: {
      width: '100%',
      padding: 12,
      ...Platform.select({
        android: {
          textAlignVertical: "top"
        }
      })
    },
    deposicionsItem: {
      flex: 1,
      borderRadius: 12,
      paddingVertical: 12,
      paddingHorizontal: 12,
      alignItems: 'center',
      borderWidth: 2.0,
      borderColor: '#eee',
      opacity: 0.8
    },
    deposicionsSelected: {
      borderColor: '#47b000',
      opacity: 1
    },
    deposicioActionBar: {
      flex: 1,
      flexDirection: 'row'
    },
    deposicioButtonAction: {

    }
  });

  const handleChangeDay = (date) => {
    setCurrentDay(date);
    if(!isSameWeek(currentDay, date, { locale: ca })) {
      setCurrentWeek(
        eachDayOfInterval({
          start: startOfWeek(date, {
            locale: ca
          }),
      
          end: endOfWeek(date, {
            locale: ca
          }
          )
        })
      );
    }
  };

  return (
    <KeyboardAwareScrollView
      resetScrollToCoords={{ x: 0, y: 0 }}
      automaticallyAdjustContentInsets={true}
      contentContainerStyle={{
        backgroundColor: '#47b000',
        flexGrow: 1,
        justifyContent: 'center'
      }}>
      <View style={styles.container}>
        {isLoading && <AppLoading />}
        {!isLoading && (
          <>
            <HeaderBar student={student} family={`${student.family.firstName} ${student.family.lastName}`} />

            <HeadingSection icon="notebook" title="Control diari" iconColor={colors.secondaryText}
              titleColor={colors.secondaryText} />
            <Surface style={styles.calendarHeaderWeekContainer}>
              <CalendarWeekDays onClickDay={handleChangeDay} selectedDay={currentDay} weekdays={currentWeek} />
            </Surface>

            <View style={styles.calendarObj}>
              <ScrollView>
                {!fetchingDailyControl && (
                  <>
                    <View style={{
                      flexDirection: 'column'
                    }}>
                      <Surface style={styles.sectionContainer}>
                        <HeadingSection
                          icon={null}
                          title="Menjar"
                          iconColor={colors.primary}
                          titleColor={colors.primary}
                          containerStyle={{
                            marginTop: 0,
                            marginBottom: 12,
                            marginHorizontal: 12
                          }}
                        />
                        <View style={{
                          marginHorizontal: 18
                        }}>
                          <View style={{
                            ...styles.sectionInlineContent,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            borderBottomWidth: 0,
                            borderColor: '#eee',
                            paddingBottom: 12.0
                          }}>
                            <Text style={styles.sectionInlineTitle}>Esmorzar: </Text>
                            <PaperTextInput
                              dense
                              mode={"outlined"}
                              style={[styles.textInputStyle, { paddingVertical: 0 }]}
                              value={esmorzar}
                              onChangeText={setEsmorzar}
                              placeholder="Tot / Bastant / Poc"
                              theme={{ colors: { primary: 'green', underlineColor: 'transparent', } }}
                              render={props => (
                                <TextInput {...props} style={styles.textInputStyle} />
                              )}
                            />
                          </View>
                          <View style={{
                            ...styles.sectionInlineContent,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            borderBottomWidth: 0,
                            borderColor: '#eee',
                            paddingBottom: 12.0
                          }}>
                            <Text style={styles.sectionInlineTitle}>Dinar: </Text>
                            <PaperTextInput
                              dense
                              mode={"outlined"}
                              style={[styles.textInputStyle, { paddingVertical: 0 }]}
                              value={dinar}
                              onChangeText={setDinar}
                              placeholder="Tot / Bastant / Poc"
                              theme={{ colors: { primary: 'green', underlineColor: 'transparent', } }}
                              render={props => (
                                <TextInput {...props} style={styles.textInputStyle} />
                              )}
                            />
                          </View>
                          <View style={{
                            ...styles.sectionInlineContent,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            borderBottomWidth: 0,
                            borderColor: '#eee',
                            paddingBottom: 12.0
                          }}>
                            <Text style={styles.sectionInlineTitle}>Berenar: </Text>
                            <PaperTextInput
                              dense
                              mode={"outlined"}
                              style={[styles.textInputStyle, { paddingVertical: 0 }]}
                              value={berenar}
                              onChangeText={setBerenar}
                              placeholder="Tot / Bastant / Poc"
                              theme={{ colors: { primary: 'green', underlineColor: 'transparent', } }}
                              render={props => (
                                <TextInput {...props} style={styles.textInputStyle} />
                              )}
                            />
                          </View>
                        </View>
                      </Surface>

                      <Surface style={styles.sectionContainer}>
                        <HeadingSection
                          icon={null}
                          title="Tipus d'alimentació"
                          iconColor={colors.primary}
                          titleColor={colors.primary}
                          containerStyle={{
                            marginTop: 0,
                            marginBottom: 12,
                            marginHorizontal: 0,
                            justifyContent: 'center',
                          }}
                        />
                        <View style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginHorizontal: 12
                        }}>                          
                          <TouchableRipple borderless style={{
                            flex: 1,
                            borderRadius: 12,
                            marginRight: 6,
                            cursor: 'pointer'
                          }} onPress={() => setAlimentacio(1)}>
                            <View style={[styles.deposicionsItem, alimentacio === 1 ? styles.deposicionsSelected : null]}>
                              <FontAwesome5 name="carrot" size={24} color="orange" />
                              {true === false && <FontAwesome5 name="seedling" size={24} color="black" />}
                              <Text style={{
                                marginTop: 8,
                                fontSize: moderateScale(14, 0.1),
                                fontFamily: 'Rubik-Bold',
                                textAlign: 'center'
                              }}>NORMAL</Text>
                            </View>
                          </TouchableRipple>
                          <TouchableRipple borderless style={{
                            flex: 1,
                            borderRadius: 12,
                            marginLeft: 6,
                            cursor: 'pointer'
                          }} onPress={() => setAlimentacio(2)}>
                            <View style={[styles.deposicionsItem, alimentacio === 2 ? styles.deposicionsSelected : null]}>
                              <FontAwesome5 name="seedling" size={24} color="#CDDC39" />
                              <Text style={{
                                marginTop: 8,
                                fontSize: moderateScale(14, 0.1),
                                fontFamily: 'Rubik-Bold',
                                textAlign: 'center'
                              }}>DIETA</Text>
                            </View>
                          </TouchableRipple>
                        </View>
                      </Surface>
                    </View>

                    <View style={{
                      flexDirection: 'column'
                    }}>
                      <Surface style={styles.sectionContainer}>
                        <HeadingSection
                          icon={null}
                          title="Dormir"
                          iconColor={colors.primary}
                          titleColor={colors.primary}
                          containerStyle={{
                            marginTop: 0,
                            marginBottom: 12,
                            marginHorizontal: 12
                          }}
                        />
                        <View style={{
                          marginHorizontal: 18
                        }}>
                          <View style={{
                            ...styles.sectionInlineContent,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            borderBottomWidth: 0,
                            borderColor: '#eee',
                            paddingBottom: 12.0
                          }}>
                            <Text style={styles.sectionInlineTitle}>Matí: </Text>
                            <PaperTextInput
                              dense
                              mode={"outlined"}
                              style={[styles.textInputStyle, { paddingVertical: 0 }]}
                              value={dormirMati}
                              onChangeText={setDormirMati}
                              placeholder="Matí"
                              theme={{ colors: { primary: 'green', underlineColor: 'transparent', } }}
                              render={props => (
                                <TextInput {...props} style={styles.textInputStyle} />
                              )}
                            />
                          </View>
                          <View style={{
                            ...styles.sectionInlineContent,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            borderBottomWidth: 0,
                            borderColor: '#eee',
                            marginTop: 6
                          }}>
                            <Text style={styles.sectionInlineTitle}>Tarda: </Text>
                            <PaperTextInput
                              dense
                              mode={"outlined"}
                              style={[styles.textInputStyle, { paddingVertical: 0 }]}
                              value={dormirTarda}
                              onChangeText={setDormirTarda}
                              placeholder="Tarda"
                              theme={{ colors: { primary: 'green', underlineColor: 'transparent', } }}
                              render={props => (
                                <TextInput {...props} style={styles.textInputStyle} />
                              )}
                            />
                          </View>
                        </View>
                      </Surface>

                      <Surface style={styles.sectionContainer}>
                        <HeadingSection
                          icon={null}
                          title="Deposicions"
                          iconColor={colors.primary}
                          titleColor={colors.primary}
                          containerStyle={{
                            marginTop: 0,
                            marginBottom: 12,
                            marginHorizontal: 0,
                            justifyContent: 'center',
                          }}
                        />
                        <View style={{
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          marginHorizontal: 12
                        }}>
                          <DeposicioController icon="thumbs-up" label="NORMAL" initCounter={deposicionsNormals} onChangeCount={setDeposicionsNormals} />
                          <DeposicioController icon="poo" iconColor="#795548" label="TOVA" initCounter={deposicionsToves} onChangeCount={setDeposicionsToves} />
                          <DeposicioController icon="tint" iconColor="#03A9F4" label="LÍQUIDA" initCounter={deposicionsLiquides} onChangeCount={setDeposicionsLiquides} />
                        </View>
                      </Surface>
                    </View>

                    <Surface style={styles.sectionContainer}>
                      <HeadingSection icon="pencil" title="Observacions del centre" iconColor={colors.primary}
                        titleColor={colors.primary} />
                      <View style={{
                        marginHorizontal: 12
                      }}>
                        <PaperTextInput
                          mode={"outlined"}
                          style={[styles.superTextInputStyle, { paddingVertical: 0 }]}
                          multiline
                          value={observacions}
                          onChangeText={setObservacions}
                          placeholder="Escriu aquí les observacions que vulguis transmetre a la família"
                          theme={{ colors: { primary: 'green', underlineColor: 'transparent', } }}
                          render={props => (
                            <TextInput {...props} style={styles.superTextInputStyle} />
                          )}
                        />
                      </View>
                    </Surface>

                    <View>
                      <Button
                        style={{
                          paddingVertical: 12
                        }}
                        labelStyle={{
                          fontSize: 18
                        }}
                        loading={submitting}
                        onPress={handleSubmit}
                        icon="content-save"
                        mode="contained">Guardar</Button>
                    </View>
                  </>
                )}
              </ScrollView>
            </View>
          </>)}

      </View>
    </KeyboardAwareScrollView>
  );
};

export default DailyControlTeacherScreen;
