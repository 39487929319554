// Imports: Dependencies
import { combineReducers } from 'redux';

// Imports: Reducers
import authReducer from "./auth/reducer";
import userReducer from "./user/reducer";
import loaderReducer from "./loader/reducer";
import schoolYearReducer from "./schoolyear/reducer";
import infoReducer from "./info/reducer";
import notificationsReducer from "./notifications/reducer";


// Redux: Root Reducer
const rootReducer = combineReducers({
                                      info: infoReducer,
                                      loader: loaderReducer,
                                      auth: authReducer,
                                      user: userReducer,
                                      schoolYear: schoolYearReducer,
                                      notifications: notificationsReducer
                                    });
// Exports
export default rootReducer;
