import React, { useState, useEffect } from 'react';
import { Text } from "react-native-paper";
import { View } from "react-native";
import DashboardPanel from "./DashboardPanel";
import services from "../../../services";

const Dashboard = () => {

  const [familyCount, setFamilyCount] = useState(0);
  const [alumnesCount, setAlumnesCount] = useState(0);
  const [treballadorsCount, setTreballadorsCount] = useState(0);

  useEffect(() => {
    services.families.find({}).then((res) => setFamilyCount(res.total));
    services.students.find({}).then((res) => setAlumnesCount(res.total));
    services.users.find({
      query: {
        $or: {
          isAdministration: true,
          isEmployee: true,
        }
      }
                        }).then((res) => setTreballadorsCount(res.total));
  }, []);

  return (
    <>
      <Text>Dashboard</Text>
      <View style={{
        marginTop: 24,
        flexDirection: 'row'
      }}>
        <DashboardPanel count={familyCount} title={"Famílies"}/>
        <DashboardPanel count={alumnesCount} title={"Alumnes"}/>
        <DashboardPanel count={treballadorsCount} title={"Treballadors"}/>
      </View>
    </>
  );
};

export default Dashboard;
