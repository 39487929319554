import { useSelector } from "react-redux";
import { Button } from "react-native-paper";
import React, { useState, useEffect } from "react";
import RenderNotificationItem from "./RenderNotificationItem";
import { View } from "react-native";

const NotificationBox = () => {
  const [item, setItem] = useState(null);
  const notifications = useSelector(state => state.notifications);

  useEffect(() => {
    if(notifications.data.length > 0) {
      setItem(notifications.data[0]);
    }
  }, [notifications]);

  return (
    <View style={{
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      {(item !== null && item.hasOwnProperty('notification') && item.notification) && <RenderNotificationItem item={item.notification} unread={item.unread} />}
      {(notifications.unreaded > 0 && notifications.data.length > 1) && <Button>Veure més notificacions</Button>}
    </View>
  );

};

export default NotificationBox;
