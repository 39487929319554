import React, { useState } from 'react';
import { Text, Surface, Title, TextInput, useTheme, Button, Snackbar, Portal } from 'react-native-paper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { StyleSheet, ImageBackground, View, Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import bgContent from '../assets/bg/bg-login.png';
import services from '../services/index';
import { doLogin } from '../store/user/actions';

const VerifyScreen = ({ navigation, route }) => {

    if(route === undefined || !route.hasOwnProperty('params') || !route.params.hasOwnProperty('token')) {
        return (null);
    }

    const dispatch = useDispatch();
    const { colors } = useTheme();
    const { token } = route.params;
    const [pwd, setPwd] = useState('');
    const [pwdVerify, setPwdVerify] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [displayError, setDisplayError] = useState(false);

    const onSubmitVerification = () => {
        setIsSubmitting(true);
        if (pwd.length > 3) {
            if (pwd === pwdVerify) {
                services.authManagement.create({
                    action: "verifySignupSetPasswordLong",
                    value: {
                        token,
                        password: pwd
                    }
                }).then((res) => {
                    console.log('res??', res)
                    console.log(
                        {
                            email: res.email,
                        password: pwd
                        }
                    )
                    dispatch(doLogin({
                        email: res.email,
                        password: pwd
                      },
                      () => {
                        navigation.replace('Loading');
                      }, (err) => {
                        setDisplayError('No s\'ha pogut iniciar sessió de manera automatica');
       }));

                }).catch((err) => {
                    console.log('err?', err)
                    setDisplayError(err.message)
                })
            } else {
                setIsSubmitting(false);
                setDisplayError('Les contrasenyes no coincideixen')
            }
        } else {
            setIsSubmitting(false);
            setDisplayError('La contrasenya té que tenir com a mínim més de 3 caràcters')
        }
    };


    return (
        <KeyboardAwareScrollView
            resetScrollToCoords={{ x: 0, y: 0 }}
            automaticallyAdjustContentInsets={true}
            contentContainerStyle={{
                backgroundColor: '#47b000',
                flexGrow: 1,
                justifyContent: 'center'
            }}
            scrollEnabled>
            <ImageBackground
                source={bgContent}
                resizeMode='cover'
                style={styles.background}
            >
                {token !== null && (
                    <View style={styles.contentContainer}>
                        <Surface style={styles.formContainer}>
                            <Title style={styles.title}>Un últim pas per continuar</Title>
                            <Title style={styles.subtitle}>Introdueix la contrasenya que vols tenir per accedir al sistema</Title>

                            <View style={styles.inputContainer}>
                                <TextInput
                                    autoCorrect={false}
                                    autoCompleteType="password"
                                    secureTextEntry
                                    textContentType="password"
                                    keyboardType="default"
                                    importantForAutofill="yes"
                                    dense
                                    style={styles.textInput}
                                    mode="flat"
                                    label="Contrasenya"
                                    value={pwd}
                                    theme={{
                                        colors: {
                                            text: colors.primary,
                                            placeholder: 'rgba(0,0,0,.25)'
                                        }
                                    }}
                                    onChangeText={text => setPwd(text)}
                                />
                            </View>

                            <View style={styles.inputContainer}>
                                <TextInput
                                    autoCorrect={false}
                                    autoCompleteType="password"
                                    secureTextEntry
                                    textContentType="password"
                                    keyboardType="default"
                                    importantForAutofill="yes"
                                    dense
                                    style={styles.textInput}
                                    mode="flat"
                                    label="Repetir Contrasenya"
                                    value={pwdVerify}
                                    theme={{
                                        colors: {
                                            text: colors.primary,
                                            placeholder: 'rgba(0,0,0,.25)'
                                        }
                                    }}
                                    onChangeText={text => setPwdVerify(text)}
                                />
                            </View>


                            <View style={{
                                width: '100%',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                marginBottom: 32
                            }}>
                                <Button loading={isSubmitting} disabled={isSubmitting} style={styles.btnSubmit} labelStyle={styles.btnSubmitLabel} mode="contained"
                                    onPress={onSubmitVerification}>
                                    Confirmar
        </Button>
                            </View>

                        </Surface>
                    </View>
                )}
            </ImageBackground>
            <Portal>

                <Snackbar
                    theme={{
                        colors: {
                            onSurface: "rgba(204, 0, 0, 0.8)",
                        },
                    }}
                    visible={displayError !== false}
                    onDismiss={() => setDisplayError(false)}
                    action={{
                        label: 'OK',
                        onPress: () => {
                            setDisplayError(false)
                        },
                    }}>
                    {displayError}
                </Snackbar>

            </Portal>
        </KeyboardAwareScrollView>
    );
};


const styles = StyleSheet.create({
    background: {
        flex: 1, width: '100%', height: '100%', padding: 0
    },
    contentContainer: {
        flex: 1,
        padding: 12,
        paddingBottom: 0,
        alignSelf: 'center',
        alignItems: 'center',
        width: (Platform.OS === 'web') ? '33%' : '100%',
        justifyContent: (Platform.OS === 'web') ? 'center' : 'flex-end',
    },
    title: {
        fontFamily: 'Rubik-Bold',
    },
    subtitle: {
        fontFamily: 'Rubik-Light',
        fontSize: 14,
        ...Platform.OS === 'web' ? {  lineHeight: '1.25' } : {},
        marginBottom: 16
    },
    formContainer: {
        width: '100%',
        backgroundColor: '#fff',
        elevation: 0,
        padding: 24,
        borderRadius: 24,
        borderBottomLeftRadius: (Platform.OS === 'web') ? 24 : 0,
        borderBottomRightRadius: (Platform.OS === 'web') ? 24 : 0,
    },
    btnSubmit: {
        flex: 1,
        alignSelf: 'center',
    },
    btnRecoveryLabel: {
        fontSize: 14
    },
    btnSubmitLabel: {
        color: 'white'
    },
    inputContainer: {
        borderRadius: 12,
        overflow: 'hidden',
        marginBottom: 12
    },
    textInput: {
        borderRadius: 12,
        backgroundColor: '#dbf496',
    }
});

export default VerifyScreen;