const initialState = {
  unreaded: 0,
  data: [],
  total: 0,
  skip: 0
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS_DATA':
      return { ...state, ...action.payload };
    case 'SET_UNREAD_NOTIFICATIONS_COUNT':
      return { ...state, unreaded: action.payload };
    case 'UNSET_NOTIFICATIONS':
      return initialState;
    default:
      return state
  }
};

export default notificationsReducer;
