import React, { useState, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { moderateScale } from "react-native-size-matters";
import { SafeAreaView, ScrollView, StyleSheet, View, TextInput, Platform, Image } from 'react-native';
import { Appbar, Title, TextInput as PaperTextInput, TouchableRipple, Text, Avatar } from 'react-native-paper';
import { NAV_BAR_HEIGHT } from '../utiles/CalculateNavbarHeight';
import { useTheme, Surface } from 'react-native-paper';
import { SimpleLineIcons } from '@expo/vector-icons';
import HeadingSection from '../components/headings/HeadingSection';
import { useDispatch } from 'react-redux';
import services from '../services/index';
import * as ImagePicker from 'expo-image-picker';
import useDimensions from '@rnhooks/dimensions';
import * as Permissions from 'expo-permissions';

const EditInfoScreen = ({ navigation }) => {
    const { colors } = useTheme();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [name, setName] = useState(user.name);
    const [avatar, setAvatar] = useState(user.avatar);
    const [newAvatar, setNewAvatar] = useState(false);
    const screenWidth = useDimensions('window').width;

    const getPermissionAsync = async () => {
        if (Platform.OS !== 'web') {
            const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
            if (status !== 'granted') {
                return false;
            }
            return true;
        }
    };


    const handleSubmit = () => {
        if ((user.name !== name && name.length > 0) || newAvatar !== false) {
            const data = {
                name: name
            };
            if (newAvatar !== false) {
                data.avatar = newAvatar;
            }
            services.users.patch(user.id, data).then((res) => {
                dispatch({
                    type: 'SET_USER',
                    payload: res
                });
                navigation.navigate('Home');
            });
        }
        navigation.navigate('Home');
    };

    const _pickImage = async () => {
        let permission = await getPermissionAsync();
        if(permission){
            try {
                let result = await ImagePicker.launchImageLibraryAsync({
                    mediaTypes: ImagePicker.MediaTypeOptions.All,
                    base64: true,
                    allowsEditing: true,
                    aspect: [4, 3],
                    quality: 1,
                });
                if (!result.cancelled) {
                    if(Platform.OS !== "web") {
                        setNewAvatar('data:image/jpeg;base64,' + result.base64);
                    } else {
                        setNewAvatar(result.uri);
                    }
                }else {
                    setNewAvatar(false);
                }
    
                console.log(result);
            } catch (E) {
                console.log(E);
            }
        } else {
            alert('Sorry, we need camera roll permissions to make this work!');
        }
    };

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            paddingTop: NAV_BAR_HEIGHT,
            backgroundColor: colors.primary
        },
        appContainer: {
            width: (Platform.OS === 'web' && screenWidth > 960) ? '33%' : '100%',
            alignSelf: 'center',
            flex: 1
        },
        userTop: {
            elevation: 0,
            flexDirection: 'row',
            alignItems: 'center',
        },
        userHeader: {
            flex: 1,
            margin: 0,
            elevation: 0,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        userNickname: {
            paddingLeft: 8,
            flex: 1,
        },
        whiteBoxContainer: {
            marginHorizontal: 12,
            elevation: 0,
            borderRadius: 12,
            backgroundColor: 'white',
            padding: 12.0,
            flexDirection: 'row',
            alignItems: 'center'
        },
        submitButton: {
            marginTop: 8.0,
            padding: 12.0,
            borderRadius: 12,
            backgroundColor: '#fff'
        },
        sectionInlineContent: {
            flexDirection: 'row',
            alignItems: 'center'
        }
    });

    return (
        <SafeAreaView style={styles.container}>
            <ScrollView style={styles.appContainer}>
                <View style={{
                    margin: 12,
                    backgroundColor: 'white',
                    borderRadius: 12,
                    elevation: 1,
                    paddingVertical: 12,
                    flexDirection: 'row'
                }}>
                    <View style={styles.userTop}>
                        <Appbar.BackAction onPress={() => navigation.navigate('Home')} />
                    </View>
                    <View style={styles.userHeader}>
                        <View style={styles.userNickname}>
                            <Title style={styles.userNicknameLabel}>
                                Editar informació
                            </Title>
                        </View>
                    </View>
                </View>

                <View style={{
                    padding: 6,
                }}>

                    <HeadingSection icon="camera" iconColor={colors.secondaryText} titleColor={colors.secondaryText}
                        title="Fotografía de perfil" />
                    <TouchableRipple borderless style={{ borderRadius: 12, cursor: 'pointer' }} onPress={_pickImage}>
                        <Surface roundness={50} style={styles.whiteBoxContainer}>
                            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                {avatar ?
                                    <Avatar.Image size={48} source={{
                                        uri: newAvatar !== false ? newAvatar : `https://agenda.granota.cat/avatars/${avatar}`
                                    }} /> : <Avatar.Text size={48} label={user && user.hasOwnProperty('name') ? user.name.toUpperCase().substring(0, 2) : ''} />
                                }
                            </View>
                        </Surface>
                    </TouchableRipple>

                    <HeadingSection icon="envelope-open" iconColor={colors.secondaryText} titleColor={colors.secondaryText}
                        title="Correu electrònic" />
                    <Surface roundness={50} style={styles.whiteBoxContainer}>

                        <PaperTextInput
                            dense
                            disabled={true}
                            mode={"outlined"}
                            style={[{
                                flex: 1,
                                fontSize: 12,
                                opacity: 0.5,
                                ...Platform.select({
                                    android: {
                                        textAlignVertical: "top"
                                    }
                                })
                            }]}
                            value={user.email}
                            theme={{ colors: { primary: 'green', underlineColor: 'transparent', } }}
                            render={props => (
                                <TextInput {...props} style={{
                                    width: '100%',
                                    fontSize: 12,
                                    padding: 12,
                                    ...Platform.select({
                                        android: {
                                            textAlignVertical: "top"
                                        }
                                    })
                                }} />
                            )}
                        />

                    </Surface>
                </View>

                <View style={{
                    padding: 6,
                }}>
                    <HeadingSection icon="user" iconColor={colors.secondaryText} titleColor={colors.secondaryText}
                        title="Nom" />
                    <Surface roundness={50} style={styles.whiteBoxContainer}>
                        <PaperTextInput
                            dense
                            mode={"outlined"}
                            style={[{
                                flex: 1,
                                fontSize: 12,
                                ...Platform.select({
                                    android: {
                                        textAlignVertical: "top"
                                    }
                                })
                            }]}
                            value={name}
                            onChangeText={setName}
                            theme={{ colors: { primary: 'green', underlineColor: 'transparent', } }}
                            render={props => (
                                <TextInput {...props} style={{
                                    width: '100%',
                                    fontSize: 12,
                                    padding: 12,
                                    ...Platform.select({
                                        android: {
                                            textAlignVertical: "top"
                                        }
                                    })
                                }} />
                            )}
                        />
                    </Surface>
                </View>

                <TouchableRipple onPress={handleSubmit} style={[styles.submitButton, { marginHorizontal: 24, cursor: 'pointer' }]}>
                    <View style={[styles.sectionInlineContent, { justifyContent: 'center' }]}>
                        <SimpleLineIcons name="note" size={12} color="#666" />
                        <Text style={{
                            fontSize: moderateScale(12, 0.1),
                            marginLeft: 4.0,
                            fontFamily: 'Rubik-Regular',
                            textTransform: 'uppercase',
                            color: '#666'
                        }}>Guardar canvis</Text>
                    </View>
                </TouchableRipple>

            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    userTop: {
        elevation: 0,
        flexDirection: 'row',
        alignItems: 'center',
    },
    userHeader: {
        flex: 1,
        margin: 0,
        elevation: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    userNickname: {
        paddingLeft: 8,
        flex: 1,
    },
    userNicknameLabel: {
        height: 20,
        fontFamily: 'Rubik-Medium',
        fontSize: moderateScale(20, 0.1),
        minHeight: 24
    },
    userSubheadingLabel: {
        fontFamily: 'Rubik-Light',
        fontSize: moderateScale(16, 0.1)
    },
});

export default EditInfoScreen;