import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { Button, TextInput, Dialog, Checkbox, Paragraph, Portal } from "react-native-paper";
import services from "../../../services";

const FormEmployee = ({ visible, employeeId, onDismiss, onComplete }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pwd, setPassword] = useState('');
  const [isAdministrator, setAdmin] = useState(false);
  const [isEmployee, setEmployee] = useState(false);
  const [showDelete, setDelete] = useState(false);

  const fetchUser = (id) => {
    services.users.get(id).then((res) => {
      setName(res.name);
      setEmail(res.email);
      setPassword('');
      if(res.isAdministration){
        setAdmin(true)
      }
      if(res.isEmployee) {
        setEmployee(true)
      }
    })
  }

  const handleConfirmDeleteAccount = () => {
    services.users.remove(employeeId).then(() => {
      onComplete();
    });
  }

  useEffect(() => {
    fetchUser(employeeId);
  }, [employeeId]);

  const onSubmit = () => {
    if(!isAdministrator && !isEmployee) {
      alert('No has seleccionat el rol');
    } else {
      let data = {
        name: name,
        email: email,
        isAdministration: isAdministrator,
        isEmployee: isEmployee
      }
      if(pwd.length > 0){
        data.password = pwd;
      }
      services.users.patch(employeeId, data).then((res) => {
        setName('');
        setEmail('');
        setPassword('');
        setAdmin(false);
        setEmployee(false);
        onComplete();
      });
    }
  }

  return (
    <Dialog style={{
      width: '33%',
      alignSelf: 'center'
    }} visible={visible} onDismiss={onDismiss}>
      <Dialog.Content>
        <View style={{
          flexDirection: 'column',
          marginBottom: 12
        }}>
          <TextInput
            style={{
              flex: 1,
              marginBottom: 12
            }}
            label="Nom"
            value={name}
            onChangeText={text => setName(text)}
          />
          <TextInput
            style={{
              flex: 1,
              marginBottom: 12
            }}
            label="Correu electrònic"
            value={email}
            onChangeText={text => setEmail(text)}
          />
          <TextInput
            style={{
              flex: 1,
            }}
            label="Contrasenya"
            value={pwd}
            onChangeText={text => setPassword(text)}
          />
        </View>
        <View style={{
          flexDirection: 'row',
          marginBottom: 12
        }}>
          <View>
            <Checkbox.Item label="Rol Administrador" onPress={() => setAdmin(!isAdministrator)}
              status={isAdministrator ? "checked" : "unchecked"} />
          </View>
          <View>
            <Checkbox.Item label="Rol empleat" onPress={() => setEmployee(!isEmployee)}
              status={isEmployee ? "checked" : "unchecked"} />
          </View>
        </View>
        <Button mode="contained" onPress={onSubmit}>
          Guardar canvis
        </Button>

        <Button mode="contained" style={{ marginTop: 24 }} contentStyle={{ backgroundColor: 'darkred' }} onPress={() => setDelete(true)}>
          Esborrar usuari
        </Button>

        <Portal>
          <Dialog style={{
            width: '33%',
            alignSelf: 'center'
          }} visible={showDelete} onDismiss={() => {
            setDelete(false);
          }}>
            <Dialog.Title style={{
              fontFamily: 'Rubik-Bold'
            }}>Atenció!!</Dialog.Title>
            <Dialog.Content>
              <Paragraph>Aquesta acció es irreversible, estàs segur/a de continuar?</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => {
                setDelete(false);
              }}>Cancel·lar</Button>
              <Button onPress={() => {
                handleConfirmDeleteAccount();
                setDelete(false);
              }}>Continuar</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

      </Dialog.Content>
    </Dialog>
  );

};

export default FormEmployee;
