import services from "../../services";

export const fetchInfo = (onComplete, onError) => {
  return dispatch => {
    services.info.get(1).then((res) => {
      dispatch({
                 type: 'SET_INFO',
                 payload: {
                   versionBackend: res.versionBackend,
                   versionApp: res.versionApp,
                   currentYearId: res.currentYearId
                 }
               });
      onComplete(res);
    }).catch((err) => {
      console.log('Can\'t fetch info err', err)
      onError(err)
    });
  };
};

export const manuallyChangeYearId = (yearId) => {
  return (dispatch, getState) => {
    const info = getState().info;
        dispatch({
               type: 'SET_INFO',
               payload: {
                 ...info,
                 currentYearId: yearId
               }
             });

  };
};
