import React, { useState, useEffect } from 'react';
import { Button, IconButton, Text, useTheme, Menu } from "react-native-paper";
import services from "../services";
import { FlatList, StyleSheet, View } from "react-native";
import AppLoading from "../components/loading/AppLoading";
import HeaderBar from "../components/classroom/HeaderBar";
import { format, startOfToday } from "date-fns";
import ca from 'date-fns/locale/ca/index';
import { NAV_BAR_HEIGHT } from "../utiles/CalculateNavbarHeight";
import { moderateScale } from "react-native-size-matters";
import RenderClassroomStudent from "../components/classroom/RenderClassroomStudent";


const ClassroomScreen = ({ route, navigation }) => {
  const { colors } = useTheme();
  const today = startOfToday();
  const { classroomId } = route.params;
  const [isLoading, setLoading] = useState(true);
  const [classroom, setClassroom] = useState(null);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    setLoading(true);
    services.classrooms.get(classroomId).then((res) => {
      setClassroom(res);

      services.classroomStudent.find({
                                       query: {
                                         classroomId: classroomId,
                                         schoolyearId: res.schoolyearId,
                                         paginate: false
                                       }
                                     }).then((students) => {
        setStudents(students);
        setLoading(false);
      });

    });
  }, [classroomId]);

  const styles = StyleSheet.create({
                                     container: {
                                       flex: 1,
                                       paddingTop: NAV_BAR_HEIGHT,
                                       backgroundColor: colors.primary
                                     },
                                     emptyView: {
                                       flex: 1,
                                       alignItems: 'center',
                                       justifyContent: 'center',
                                     },
                                     emptyText: {
                                       fontSize: moderateScale(16, 0.1),
                                       textAlign: 'center',
                                       marginVertical: 24,
                                       fontFamily: 'Rubik-Light'
                                     }
                                   });

  const handleOnPressStudent = (studentId) => {
    navigation.navigate('StudentDetails', {
      studentId: studentId
    });
  };

  const handleOnPressAssistance = (studentId) => {
    navigation.navigate('StudentDetails', {
      studentId: studentId
    });
  };
  const handleOnPressDailyControl = (studentId) => {
    navigation.navigate('DailyControlTeacherScreen', {
      studentId: studentId
    });
  };
  const handleOnPressAgenda = (studentId) => {
    navigation.navigate('AgendaTeacherScreen', {
      studentId: studentId
    });
  };
  const handleOnPressNotifications = (studentId) => {
    navigation.navigate('NotificationsTeacher', {
      studentId: studentId
    });
  };

  const onRenderItem = ({ index, item }) => {
    return <RenderClassroomStudent
      date={today}
      item={item}
      classroom={classroom}
      onPressStudent={handleOnPressStudent}
      onPressAssistance={handleOnPressAssistance}
      onPressDailyControl={handleOnPressDailyControl}
      onPressAgenda={handleOnPressAgenda}
      onPressNotifications={handleOnPressNotifications}
    />
  };

  const FlatListItemSeparator = () => {
    return (
      <View
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#47b000",
        }}
      />
    );
  };

  return (
    <View style={styles.container}>

      {isLoading && <AppLoading/>}
      {!isLoading && (
        <>
          <HeaderBar classroom={classroom} today={format(today, 'dd LLLL yyyy', {
            locale: ca
          })}/>

          <FlatList
            style={{
              marginHorizontal: 12,
              marginBottom: 12,
              borderRadius: 12,
              padding: 6,
              backgroundColor: 'white',
            }}
            ItemSeparatorComponent={FlatListItemSeparator}
            contentContainerStyle={{ flexGrow: 1 }}
            ListEmptyComponent={() => <View style={styles.emptyView}>
              <Text style={styles.emptyText}>No hi ha cap alumne assignat a la classe</Text>
            </View>}
            data={students}
            renderItem={onRenderItem}
            keyExtractor={(item, index) => `classroom-alumne-item-${index}`}
          />

        </>
      )}

    </View>
  );
};

export default ClassroomScreen;
