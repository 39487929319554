import React, { useState, useEffect } from 'react';
import HeaderBar from "../components/sons/HeaderBar";
import { View, StyleSheet, TextInput, Platform, FlatList } from "react-native";
import { NAV_BAR_HEIGHT } from "../utiles/CalculateNavbarHeight";
import { useTheme, Surface, Text, TouchableRipple, TextInput as PaperTextInput } from "react-native-paper";
import { eachDayOfInterval, endOfWeek, startOfToday, startOfWeek, isSameWeek } from "date-fns";
import ca from 'date-fns/locale/ca/index';
import CalendarWeekDays from "../components/calendar/CalendarWeekDays";
import HeadingSection from "../components/headings/HeadingSection";
import { Checkbox } from 'react-native-paper';
import { SimpleLineIcons } from '@expo/vector-icons';
import { moderateScale } from "react-native-size-matters";
import services from "../services";
import AppLoading from "../components/loading/AppLoading";
import RenderMedicamentItem from "../components/medicaments/RenderMedicamentItem";
import { format } from "date-fns-tz";

const AgendaScreen = ({ route, navigation }) => {
  const { colors } = useTheme();
  const { studentId } = route.params;

  const today = startOfToday();
  const [currentDay, setCurrentDay] = useState(today);
  const [currentWeek, setCurrentWeek] = useState(eachDayOfInterval({
                                                                     start: startOfWeek(today, {
                                                                       locale: ca
                                                                     }),

                                                                     end: endOfWeek(today, {
                                                                                      locale: ca
                                                                                    }
                                                                     )
                                                                   }));

  const [isLoading, setLoading] = useState(true);
  const [student, setStudent] = useState(null);

  const [calPortarObj, setCalPortarObj] = useState({});
  const [bolquers, setBolquers] = useState(false);
  const [tovalloletes, setTovalloletes] = useState(false);
  const [crema, setCrema] = useState(false);
  const [enableCustomField, setEnableCustomField] = useState(false);
  const [customField, setCustomField] = useState('');

  const [observacionsFamilia, setObservacionsFamilia] = useState('');
  const [medicaments, setMedicaments] = useState([]);

  const fetchAgenda = (date, student) => {
    services.studentAgenda.find({
                                  query: {
                                    date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
                                    studentId: student,
                                  }
                                }).then((res) => {
      if (res.total > 0) {
        let item = res.data[0];
        setCalPortarObj(item);
        setBolquers(item.bolquers);
        setTovalloletes(item.tovalloletes);
        setCrema(item.crema);
        setEnableCustomField(item.custom_field);
        setCustomField(item.custom_field_value);
      } else {
        setCalPortarObj({});
        setBolquers(false);
        setTovalloletes(false);
        setCrema(false);
        setEnableCustomField(false);
        setCustomField('');
      }
    });
    services.agendaFamilia.find({
                                  query: {
                                    date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
                                    studentId: student,
                                    $sort: {
                                      createdAt: -1,
                                    },
                                  }
                                }).then((res) => {
        if(res.total > 0) {
          let item = res.data[0];
          setObservacionsFamilia(item.observacions_familia);
        } else {
          setObservacionsFamilia('');
        }
    });
    services.agendaMedicament.find({
                                     query: {
                                       date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
                                       studentId: student,
                                       paginate: false,
                                       $sort: { createdAt: 1 }
                                     }
                                   }).then((res) => {
      if(res.length > 0) {
        setMedicaments(res);
      } else {
        setMedicaments([]);
      }
    })
  };

  useEffect(() => {
    setLoading(true);
    services.students.get(studentId).then((res) => {
      setStudent(res);
      fetchAgenda(currentDay, studentId)
      setLoading(false);
    });
  }, [studentId]);

  useEffect(() => {
    fetchAgenda(currentDay, studentId);
  }, [currentDay]);


  const styles = StyleSheet.create({
                                     container: {
                                       flex: 1,
                                       paddingTop: NAV_BAR_HEIGHT,
                                       backgroundColor: colors.primary
                                     },
                                     calendarHeaderWeekContainer: {
                                       elevation: 0,
                                       padding: 8.0,
                                       marginHorizontal: 12,
                                       marginTop: 4,
                                       borderTopRightRadius: 12,
                                       borderTopLeftRadius: 12
                                     },
                                     calendarObj: {
                                       padding: 8.0,
                                       marginHorizontal: 12,
                                       backgroundColor: colors.accent,
                                       borderBottomRightRadius: 12,
                                       borderBottomLeftRadius: 12
                                     },
                                     sectionContainer: {
                                       elevation: 0,
                                       margin: 12,
                                       marginTop: 0,
                                       paddingVertical: 16,
                                       borderRadius: 12
                                     },
                                     sectionInlineContent: {
                                       flexDirection: 'row',
                                       alignItems: 'center'
                                     },
                                     medicacioButtonAdd: {
                                       padding: 12.0,
                                       borderRadius: 12,
                                       backgroundColor: '#e8e8e8'
                                     }
                                   });

  const handleChangeDay = (date) => {
    setCurrentDay(date);
    if(!isSameWeek(currentDay, date, { locale: ca })) {
      setCurrentWeek(
        eachDayOfInterval({
          start: startOfWeek(date, {
            locale: ca
          }),
      
          end: endOfWeek(date, {
            locale: ca
          }
          )
        })
      );
    }
  };

  const saveCalPortar = () => {
    if (calPortarObj.hasOwnProperty('id')) {
      services.studentAgenda.patch(calPortarObj.id, {
        bolquers: bolquers,
        tovalloletes: tovalloletes,
        crema: crema,
        custom_field: enableCustomField,
        custom_field_value: customField
      }).then((res) => {
        setCalPortarObj(res)
      });
    } else {
      services.studentAgenda.create({
                                      date: format(currentDay, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
                                      studentId: studentId,
                                      bolquers: bolquers,
                                      tovalloletes: tovalloletes,
                                      crema: crema,
                                      custom_field: enableCustomField,
                                      custom_field_value: customField
                                    }).then((res) => {
        setCalPortarObj(res)
      });
    }
  };

  const FlatListItemSeparator = () => {
    if ((Platform.OS === 'web')) {
      return (null);
    }
    return (
      <View
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#47b000",
        }}
      />
    );
  };

  const onRenderMedicament = ({ item }) => <RenderMedicamentItem item={item}/>

  return (
    <View style={styles.container}>
      {isLoading && <AppLoading/>}
      {!isLoading && (
        <>

          <HeaderBar student={student} family={`${student.family.firstName} ${student.family.lastName}`}/>

          <HeadingSection icon="calendar" title="Agenda" iconColor={colors.secondaryText}
                          titleColor={colors.secondaryText}/>
          <Surface style={styles.calendarHeaderWeekContainer}>
            <CalendarWeekDays onClickDay={handleChangeDay} selectedDay={currentDay} weekdays={currentWeek}/>
          </Surface>

          <View style={styles.calendarObj}>
            <Surface style={styles.sectionContainer}>
              <HeadingSection icon="pin" title="Cal portar" iconColor={colors.primary} titleColor={colors.primary}/>
              <View style={{
                marginHorizontal: 28
              }}>
                <View style={styles.sectionInlineContent}>
                  <Checkbox
                    status={bolquers ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setBolquers(!bolquers);
                    }}
                  />
                  <Text>bolquers</Text>
                </View>
                <View style={styles.sectionInlineContent}>
                  <Checkbox
                    status={tovalloletes ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setTovalloletes(!tovalloletes);
                    }}
                  />
                  <Text>tovalloletes</Text>
                </View>
                <View style={styles.sectionInlineContent}>
                  <Checkbox
                    status={crema ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setCrema(!crema);
                    }}
                  />
                  <Text>crema</Text>
                </View>
                <View style={styles.sectionInlineContent}>
                  <Checkbox
                    status={enableCustomField ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setEnableCustomField(!enableCustomField);
                    }}
                  />
                  <PaperTextInput
                    dense
                    disabled={!enableCustomField}
                    mode={"outlined"}
                    style={[{
                      flex: 1,
                      fontSize: 12,
                      padding: 12,
                      ...Platform.select({
                                           android: {
                                             textAlignVertical: "top"
                                           }
                                         })
                    }]}
                    value={customField}
                    onChangeText={setCustomField}
                    placeholder="Roba,.."
                    theme={{ colors: { primary: 'green', underlineColor: 'transparent', } }}
                    render={props => (
                      <TextInput {...props} style={{
                        width: '100%',
                        fontSize: 12,
                        padding: 12,
                        ...Platform.select({
                                             android: {
                                               textAlignVertical: "top"
                                             }
                                           })
                      }}/>
                    )}
                  />
                </View>
              </View>
              <TouchableRipple onPress={saveCalPortar} style={[styles.medicacioButtonAdd, { marginHorizontal: 24, cursor: 'pointer' }]}>
                <View style={[styles.sectionInlineContent, { justifyContent: 'center' }]}>
                  <SimpleLineIcons name="note" size={12} color="#666"/>
                  <Text style={{
                    fontSize: moderateScale(12, 0.1),
                    marginLeft: 4.0,
                    fontFamily: 'Rubik-Regular',
                    textTransform: 'uppercase',
                    color: '#666'
                  }}>Guardar</Text>
                </View>
              </TouchableRipple>
            </Surface>

            <Surface style={styles.sectionContainer}>
              <HeadingSection icon="pencil" title="Observacions de la família" iconColor={colors.primary}
                              titleColor={colors.primary}/>
              <View style={{
                marginHorizontal: 28
              }}>
                <Text style={{
                  fontSize: 14,
                  fontFamily: 'Rubik-Medium'
                }}>{observacionsFamilia}</Text>
              </View>
            </Surface>

            <Surface style={styles.sectionContainer}>
              <HeadingSection icon="exclamation" title="Medicació" iconColor={colors.primary}
                              titleColor={colors.primary}/>
              <View>
                <FlatList
                  style={{
                    marginHorizontal: 12,
                    marginBottom: 12,
                    borderRadius: 12,
                    padding: 6,
                    backgroundColor: 'white',
                  }}
                  ItemSeparatorComponent={FlatListItemSeparator}
                  contentContainerStyle={{ flexGrow: 1 }}
                  ListEmptyComponent={() => <View style={styles.emptyView}>
                    <TouchableRipple style={styles.medicacioButtonAdd}>
                      <View style={[styles.sectionInlineContent, { justifyContent: 'center' }]}>
                        <Text style={{
                          fontSize: moderateScale(12, 0.1),
                          marginLeft: 4.0,
                          fontFamily: 'Rubik-Regular',
                          textTransform: 'uppercase',
                          color: '#666'
                        }}>Cap indicació</Text>
                      </View>
                    </TouchableRipple>
                  </View>}
                  data={medicaments}
                  renderItem={onRenderMedicament}
                  keyExtractor={(item, index) => `med-item-${item.id}`}
                />

              </View>
            </Surface>
          </View>

        </>)}
    </View>
  )
};

export default AgendaScreen;
