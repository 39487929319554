import React, { useState, useEffect } from "react";
import { Dialog, Text } from "react-native-paper";
import AppLoading from "../../loading/AppLoading";
import services from "../../../services";
import { FlatList, ScrollView } from "react-native";
import RenderSelectableTeacher from "../../teachers/RenderSelectableTeacher";

const CourseManageTeachers = ({ courseId, currentSchoolYear, visible, onDismiss, onComplete: updateParent }) => {
  const [course, setCourse] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    services.classrooms.get(courseId).then((c) => {
      setCourse(c);

      services.users.find({
                            query: {
                              isEmployee: true,
                              paginate: false
                            }
                          }).then((employees) => {
        setTeachers(employees);
        services.classroomTeacher.find({
                                         query: {
                                           classroomId: courseId,
                                           schoolyearId: currentSchoolYear,
                                           paginate: false
                                         }
                                       }).then((res) => {
          setSelectedTeachers(res.map((u) => u.userId));
          setLoading(false);
        });
      });
    });
  }, [courseId]);

  const onRenderTeacherItem = ({ index, item }) => {
    return <RenderSelectableTeacher user={item} handleOnPress={() => {
      let exists = selectedTeachers.indexOf(item.id) !== -1;
      if (exists) {
        services.classroomTeacher.remove(null, {
          query: {
            userId: item.id,
            classroomId: courseId,
            schoolyearId: currentSchoolYear
          }
        }).then((res) => {
          let newArr = selectedTeachers.filter(e => e !== item.id);
          setSelectedTeachers(newArr);
        });
      } else {
        services.classroomTeacher.create({
                                           userId: item.id,
                                           classroomId: courseId,
                                           schoolyearId: currentSchoolYear
                                         }).then((res) => {
          setSelectedTeachers([...selectedTeachers, item.id])
        });

      }
      updateParent()
    }} isSelected={selectedTeachers.indexOf(item.id) !== -1}/>
  };

  return (
    <Dialog visible={visible} onDismiss={onDismiss} style={{
      width: '25%',
      alignSelf: 'center'
    }}>
      <Dialog.Content>
        {isLoading && <AppLoading/>}
        {!isLoading &&
        <>
          <Text style={{ fontSize: 24, fontFamily: 'Rubik-Bold' }}>Professors {course.classroom_name}:</Text>

          <ScrollView style={{
            maxHeight: 300
          }}>
            <FlatList
              style={{
                padding: 6,
                marginHorizontal: 12,
                backgroundColor: 'white',
                borderRadius: 12
              }}
              ListEmptyComponent={() =>
                <Text style={{ fontSize: 18, fontFamily: 'Rubik-Light', padding: 8 }}>
                  no hi han professors assignats.
                </Text>
              }
              data={teachers}
              renderItem={onRenderTeacherItem}
              keyExtractor={(item, index) => `course-teacher-${item.id}`}
            />
          </ScrollView>

        </>
        }
      </Dialog.Content>
    </Dialog>
  );

};

export default CourseManageTeachers;
