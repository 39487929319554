import React, { useState, useEffect } from "react";
import { Dialog, Text } from "react-native-paper";
import AppLoading from "../../loading/AppLoading";
import services from "../../../services";
import { FlatList, View } from "react-native";
import RenderStudent from "../../sons/RenderStudent";

const CourseManageStudents = ({ courseId, currentSchoolYear, visible, onDismiss }) => {
  const [course, setCourse] = useState(null);
  const [students, setStudents] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    services.classrooms.get(courseId).then((c) => {
      setCourse(c);
      services.classroomStudent.find({
                                       query: {
                                         classroomId: courseId,
                                         schoolyearId: currentSchoolYear,
                                         paginate: false
                                       }
                                     }).then((res) => {
        setStudents(res);
        setLoading(false);
      });
    });
  }, [courseId]);

  const onRenderStudentItem = ({ index, item }) => {
    return <RenderStudent studentId={item.studentId} handleOnPress={() => console.log('open student')} currentSchoolYear={currentSchoolYear} showFamily={true} />
  };

  return (
    <Dialog visible={visible} onDismiss={onDismiss} style={{
      width: '25%',
      alignSelf: 'center'
    }}>
      <Dialog.Content>
        {isLoading && <AppLoading/>}
        {!isLoading &&
        <>
          <Text style={{ fontSize: 24, fontFamily: 'Rubik-Bold' }}>Alumnes {course.classroom_name}:</Text>

          <FlatList
            style={{
              padding: 6,
              marginHorizontal: 12,
              backgroundColor: 'white',
              borderRadius: 12
            }}
            ListEmptyComponent={() =>
              <Text style={{ fontSize: 18, fontFamily: 'Rubik-Light', padding: 8 }}>
                no hi han alumnes.
              </Text>
            }
            data={students}
            renderItem={onRenderStudentItem}
            keyExtractor={(item, index) => `course-student-${item.id}`}
          />

        </>
        }
      </Dialog.Content>
    </Dialog>
  );

};

export default CourseManageStudents;
