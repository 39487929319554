import React, { useEffect, useState } from "react";
import services from "../services";
import { View, StyleSheet, FlatList } from "react-native";
import AppLoading from "../components/loading/AppLoading";
import { NAV_BAR_HEIGHT } from "../utiles/CalculateNavbarHeight";
import { IconButton, Surface, Text, useTheme } from "react-native-paper";
import HeaderBar from "../components/sons/HeaderBar";
import HeadingSection from "../components/headings/HeadingSection";
import RenderParentItem from "../components/parents/RenderParentItem/RenderParentItem";

const StudentDetails = ({ route, navigation }) => {
  const { colors } = useTheme();
  const { studentId } = route.params;
  const [student, setStudent] = useState({});
  const [pares, setPares] = useState([]);
  const [allergens, setAllergens] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const styles = StyleSheet.create({
                                     container: {
                                       flex: 1,
                                       paddingTop: NAV_BAR_HEIGHT,
                                       backgroundColor: colors.primary
                                     },
                                     notificationsContainer: {
                                       marginHorizontal: 12,
                                       elevation: 0,
                                       borderRadius: 12,
                                       backgroundColor: 'white',
                                       padding: 12.0,
                                       flexDirection: 'row',
                                       alignItems: 'center'
                                     },
                                   });

  useEffect(() => {
    setLoading(true);
    services.students.get(studentId).then((res) => {
      setStudent(res);
      services.families.get(res.familyId).then((fam) => {
        setPares(fam.tutors);

        services.studentAllergens.find({
                                         query: {
                                           studentId: studentId,
                                           paginate: false,
                                           $sort: { createdAt: 1 }
                                         }
                                       }).then((all) => {
                                         setAllergens(all);
          setLoading(false);
        })

      });
    });
  }, [studentId]);

  const onRenderItemUser = ({ index, item }) => {
    return <RenderParentItem item={item} handleOnPress={() => console.log(item)} showVerifyStatus={false} />
  };

  const FlatListItemSeparator = () => {
    return (
      <View
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#47b000",
        }}
      />
    );
  };

  const onRenderAllergenItem = ({ index, item }) => {
    return <View style={{
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 8.0,
      paddingVertical: 12
    }}>
      <View>
        <Text style={{
          fontSize: 18,
          fontFamily: 'Rubik-Medium',
        }}>{item.text}</Text>
      </View>
    </View>
  };

  return (
    <View style={styles.container}>
      {isLoading && <AppLoading/>}
      {(!isLoading && student.hasOwnProperty('id')) && (
        <>
          <HeaderBar student={student} family={`${student.family.firstName} ${student.family.lastName}`}/>

          <View style={{
            flex: 1
          }}>
              <>
                <HeadingSection icon="people" iconColor={colors.secondaryText} titleColor={colors.secondaryText}
                                title="Familiars"/>
                <View style={{ marginTop: 0 }}>
                  <Surface roundness={50} style={styles.notificationsContainer}>
                    <FlatList
                      style={{
                        width: '100%',
                        backgroundColor: '#fff',
                        padding: 6,
                        borderRadius: 12,
                        flex: 1
                      }}
                      contentContainerStyle={{ flexGrow: 1 }}
                      ListEmptyComponent={() => <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontFamily: 'Rubik-Light', marginBottom: 12 }}>No hi ha cap familiar</Text>
                      </View>}
                      data={pares}
                      renderItem={onRenderItemUser}
                      keyExtractor={(item, index) => `family-pare-${index}`}
                    />
                  </Surface>
                </View>
              </>

              <View style={{ marginTop: 12 }}>
                <HeadingSection icon="heart" iconColor={colors.secondaryText} titleColor={colors.secondaryText}
                                title="Al·lergens"/>
                <View>
                  <Surface roundness={50} style={styles.notificationsContainer}>
                    <FlatList
                      style={{
                        marginHorizontal: 12,
                        marginBottom: 12,
                        borderRadius: 12,
                        padding: 6,
                        backgroundColor: 'white',
                      }}
                      ItemSeparatorComponent={FlatListItemSeparator}
                      contentContainerStyle={{ flexGrow: 1 }}
                      ListEmptyComponent={() => <View style={styles.emptyView}>
                        <Text style={styles.emptyText}>No hi ha cap al·lergen registrat encara</Text>
                      </View>}
                      data={allergens}
                      renderItem={onRenderAllergenItem}
                      keyExtractor={(item, index) => `allergen-item-${index}`}
                    />
                  </Surface>
                </View>
              </View>

          </View>

          </>)}
    </View>
  );
};


export default StudentDetails;
