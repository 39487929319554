import React, { useState } from 'react';
import { View } from 'react-native';
import { Surface, Title, TextInput, Button } from 'react-native-paper';
import { useTheme } from 'react-native-paper';
import services from '../../services/index';

const RecoveryStepOne = ({  styles, setDisplayError, goNext }) => {
    const { colors } = useTheme();
    const [email, setEmail] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);
    const onSubmitRecovery = () => {
        setSubmitting(true);

        services.authManagement.create({
            action: "sendResetPwd",
            value: {
                email: email
            }
        }).then((res) => {
            goNext(email);
        }).catch((err) => {
            setSubmitting(false);
            console.log(err)
            if(err.message === "User not found.") {
                setDisplayError('No s\'ha trobat cap compte amb aquest correu.');
            } else if (err.message === "User is not verified.") {
                setDisplayError('El compte d\'usuari encara no ha sigut verificat');
            } else if (err.message === "User must wait") {
                setDisplayError('El sistema ha detectat múltiples intents, has d\'esperar.');
            }
        });

    };

    return (
        <View style={styles.contentContainer}>
            <Surface style={styles.formContainer}>
                <Title style={styles.title}>Recuperar contrasenya</Title>
                <Title style={styles.subtitle}>Ingressa el teu correu electrònic.</Title>

                <View style={styles.inputContainer}>
                    <TextInput
                        autoCorrect={false}
                        autoCompleteType="email"
                        autoCapitalize="none"
                        textContentType="emailAddress"
                        keyboardType="email-address"
                        importantForAutofill="yes"
                        dense
                        style={styles.textInput}
                        mode="flat"
                        label="Correu electrònic"
                        value={email}
                        underlineColor={"#dbf496"}
                        theme={{
                            colors: {
                                text: colors.primary,
                                placeholder: 'rgba(0,0,0,.25)'
                            }
                        }}
                        onChangeText={text => setEmail(text)}
                    />
                </View>


                <View style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    marginBottom: 32
                }}>
                    <Button loading={isSubmitting} disabled={isSubmitting} style={styles.btnSubmit} labelStyle={styles.btnSubmitLabel} mode="contained"
                        onPress={onSubmitRecovery}>
                        Següent pas
        </Button>
                </View>

            </Surface>
        </View>
    );
}

export default RecoveryStepOne;