import React, { useState, useEffect } from 'react';
import { Button, Portal, Text, TextInput, Dialog } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import services from "../../../services";
import CourseRow from "./CourseRow";
import CourseManageStudents from "./CourseManageStudents";
import CourseManageTeachers from "./CourseManageTeachers";

const ManageCourses = ({ currentSchoolYear }) => {
  const [skip, setSkip] = useState(0);
  const [haveMore, setHaveMore] = useState(false);
  const [data, setData] = useState([]);
  const [newCursName, setNewCursName] = useState('');

  const [showCreate, setCreate] = useState(false);
  const [manageStudents, setManageStudents] = useState({});
  const [manageTeachers, setManageTeachers] = useState({});

  const firstFetchRes = () => {
    setSkip(0);
    services.classrooms.find({
                               query: {
                                 schoolyearId: currentSchoolYear.id,
                                 $sort: {
                                  classroom_name: 1,
                                },
                                   $limit: 20
                               }
                             }).then((res) => {
      setSkip(0 + res.data.length);
      setData(res.data);
      if (res.total > (0 + res.data.length)) {
        setHaveMore(true);
      } else {
        setHaveMore(false);
      }
    });
  };

  const deleteItem = (courseId) => {
    services.classrooms.remove(courseId).then((res) => {
      firstFetchRes();
    });
  };

  useEffect(() => {
    firstFetchRes();
  }, [currentSchoolYear]);

  return (
    <>
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 24
      }}>
        <Text style={styles.headingTitle}>Gestionar cursos</Text>
        <View>
          <Button mode="contained" icon="plus" onPress={() => setCreate(true)}>Nou</Button>
        </View>
      </View>

      <View style={styles.mainTable}>
        <View style={styles.tableHeading}>
          <Text style={styles.tableHeadingItem}>Curs</Text>
          <Text style={styles.tableHeadingItem}>Alumnes</Text>
          <Text style={styles.tableHeadingItem}>Professors</Text>
          <Text style={[styles.tableHeadingItem, { flex: 3 }]}>Accions</Text>
        </View>
        {data.map((course) =>
                    <CourseRow key={course.id} styles={styles} course={course} currentSchoolYear={currentSchoolYear.id}
                               onDeleteItem={deleteItem} onManageStudents={() => setManageStudents(course)}
                               onManageTeachers={() => setManageTeachers(course)}/>
        )}

      </View>
      <Portal>
        {manageStudents && manageStudents.hasOwnProperty('id') && (
          <CourseManageStudents
            visible={manageStudents && manageStudents.hasOwnProperty('id')}
            courseId={manageStudents.id}
            currentSchoolYear={currentSchoolYear.id}
            onDismiss={() => setManageStudents({})}
          />
        )}
        {manageTeachers && manageTeachers.hasOwnProperty('id') && (
          <CourseManageTeachers
            visible={manageTeachers && manageTeachers.hasOwnProperty('id')}
            courseId={manageTeachers.id}
            currentSchoolYear={currentSchoolYear.id}
            onDismiss={() => setManageTeachers({})}
            onComplete={() => console.log('f')}
          />
        )}

        <Dialog style={{
          width: '33%',
          alignSelf: 'center'
        }} visible={showCreate} onDismiss={() => setCreate(false)}>
          <Dialog.Content>
            <TextInput
              label="Curs"
              placeholder="Cargols"
              value={newCursName}
              onChangeText={text => setNewCursName(text)}
              onKeyPress={(event) => {
                if (event.nativeEvent.key === "Enter") {
                  if (newCursName.length > 0) {
                    services.classrooms.create({
                                                 classroom_name: newCursName,
                                                 schoolyearId: currentSchoolYear.id
                                               }).then((res) => {
                      firstFetchRes(0);
                      setCreate(false);
                      setNewCursName('');
                    })
                  }
                }
              }}
            />
          </Dialog.Content>
        </Dialog>
      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
                                   headingTitle: {
                                     fontSize: 24,
                                     fontFamily: 'Rubik-Medium',
                                   },
                                   mainTable: {
                                     margin: 12,
                                     padding: 12
                                   },
                                   tableHeading: {
                                     flexDirection: 'row',
                                   },
                                   tableRow: {
                                     flexDirection: 'row'
                                   },
                                   tableHeadingItem: {
                                     flex: 1,
                                     padding: 6,
                                     margin: 4,
                                     backgroundColor: '#a3d77f',
                                     color: 'white',
                                     fontFamily: 'Rubik-Light',
                                     textTransform: 'uppercase'
                                   },
                                   tableItem: {
                                     flex: 1,
                                     padding: 6,
                                     margin: 4,
                                     backgroundColor: '#e5efdf',
                                     fontFamily: 'Rubik-Medium',
                                   }
                                 });

export default ManageCourses;
