import React, { useState, useEffect, useLayoutEffect } from "react";
import { Text, Dialog, Button, TextInput, Portal, Paragraph, TouchableRipple, Avatar } from "react-native-paper";
import { Picker, View, Platform } from "react-native";
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
import services from "../../../services";

const FormParentDetails = ({ visible, parentId, onDismiss, onComplete }) => {
  const [isLoading, setLoading] = useState(false);
  const [showDelete, setDelete] = useState(false);
  const [user, setUser] = useState({});
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState('');
  const [newAvatar, setNewAvatar] = useState(false);

  const getPermissionAsync = async () => {
    if (Platform.OS !== 'web') {
      const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
      }
    }
  };

  useLayoutEffect(() => {
    getPermissionAsync();
    return () => {

    };
  }, [])

  useEffect(() => {
    services.users.get(parentId).then((res) => {
      setUsername(res.name);
      setEmail(res.email);
      setAvatar(res.avatar);
      setUser(res);
    });
  }, [parentId]);

  const removeAccountAdvertise = () => {
    setDelete(true);
  };

  const handleConfirmDeleteAccount = () => {
    setLoading(true);
    services.users.remove(user.id).then(() => {
      setLoading(false);
      onComplete();
    });
  };

  const _pickImage = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        base64: true,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      if (!result.cancelled) {
        if (Platform.OS !== "web") {
          setNewAvatar('data:image/jpeg;base64,' + result.base64);
        } else {
          setNewAvatar(result.uri);
        }
      } else {
        setNewAvatar(false);
      }

      console.log(result);
    } catch (E) {
      console.log(E);
    }
  };

  const submitData = () => {
    setLoading(true);
    let data = {
      name: username,
      email: email,
    };
    if (newAvatar !== false) {
      data.avatar = newAvatar;
    }
    services.users.patch(user.id, data).then(() => {
      setLoading(false);
      onComplete();
    });
  };

  return (
    <Dialog style={{
      width: '33%',
      alignSelf: 'center'
    }} visible={visible}
      onDismiss={onDismiss}>
      <Dialog.Content>
        {user.hasOwnProperty('id') && (
          <View style={{
            flexDirection: 'column',
            marginVertical: 12
          }}>

            <TouchableRipple borderless style={{ borderRadius: 12, marginBottom: 12, cursor: 'pointer' }} onPress={_pickImage}>
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                {avatar ?
                  <Avatar.Image size={48} source={{
                    uri: newAvatar !== false ? newAvatar : `https://agenda.granota.cat/avatars/${avatar}`
                  }} /> : <Avatar.Text size={48} label={username ? username.toUpperCase().substring(0, 2) : ''} />
                }
              </View>
            </TouchableRipple>

            <TextInput
              style={{
                flex: 1,
                marginBottom: 12
              }}
              label="Nom"
              value={username}
              onChangeText={text => setUsername(text)}
            />
            <TextInput
              style={{
                flex: 1,
                marginBottom: 12
              }}
              label="Correu electrònic"
              value={email}
              onChangeText={text => setEmail(text)}
            />


            <Button loading={isLoading} mode="contained" icon="content-save" onPress={submitData}>
              Guardar dades
          </Button>
          </View>
        )}

        <View style={{
          marginTop: 12
        }}>
          {user.hasOwnProperty('id') && (
            <View style={{
              marginTop: 32,
            }}>
              <Button loading={isLoading} mode="contained" icon="delete" onPress={removeAccountAdvertise} contentStyle={{
                backgroundColor: 'darkred'
              }}>
                Esborrar compte
          </Button>
            </View>
          )}
        </View>

        <Portal>
          <Dialog style={{
            width: '33%',
            alignSelf: 'center'
          }} visible={showDelete} onDismiss={() => {
            setDelete(false);
          }}>
            <Dialog.Title style={{
              fontFamily: 'Rubik-Bold'
            }}>Atenció!!</Dialog.Title>
            <Dialog.Content>
              <Paragraph>Aquesta acció es irreversible, estàs segur/a de continuar?</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => {
                setDelete(false);
              }}>Cancel·lar</Button>
              <Button onPress={() => {
                handleConfirmDeleteAccount();
                setDelete(false);
              }}>Continuar</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

      </Dialog.Content>
    </Dialog>
  );
};

export default FormParentDetails;
