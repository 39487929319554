import React from "react";
import { View, StyleSheet } from "react-native";
import { Appbar, Avatar, Subheading, Title } from 'react-native-paper';
import { moderateScale } from "react-native-size-matters";
import { useNavigation } from '@react-navigation/native';

const HeaderBar = ({ classroom, today }) => {
  const navigation = useNavigation();

  const styles = StyleSheet.create({
                                     userTop: {
                                       elevation: 0,
                                       flexDirection: 'row',
                                       alignItems: 'center',
                                     },
                                     userHeader: {
                                       flex: 1,
                                       margin: 0,
                                       elevation: 0,
                                       flexDirection: 'row',
                                       justifyContent: 'space-between',
                                       alignItems: 'center',
                                     },
                                     userNickname: {
                                       paddingLeft: 8,
                                       flex: 1,
                                     },
                                     userNicknameLabel: {
                                       height: 28,
                                       fontFamily: 'Rubik-Medium',
                                       fontSize: moderateScale(20, 0.1)
                                     },
                                     userSubheadingLabel: {
                                       fontFamily: 'Rubik-Light',
                                       fontSize: moderateScale(16, 0.1)
                                     },
                                   });

  return (
    <View style={{
      margin: 12,
      backgroundColor: 'white',
      borderRadius: 12,
      elevation: 1,
      paddingVertical: 12,
      flexDirection: 'row'
    }}>
      <View style={styles.userTop}>
        <Appbar.BackAction onPress={() => navigation.goBack()}/>
      </View>

      <View style={styles.userHeader}>
        <View style={styles.userNickname}>
          <Title style={styles.userNicknameLabel}>
            {classroom.classroom_name}
          </Title>
          <Subheading style={styles.userSubheadingLabel}>{today}</Subheading>
        </View>
      </View>
    </View>
  );

};

export default HeaderBar;
