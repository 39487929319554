import React from "react";
import { View, StyleSheet } from "react-native";
import { Appbar, Avatar, Subheading, Title } from 'react-native-paper';
import { moderateScale } from "react-native-size-matters";
import { useNavigation } from '@react-navigation/native';

const HeaderBar = ({ student, family }) => {
  const navigation = useNavigation();

  const styles = StyleSheet.create({
                                     userTop: {
                                       elevation: 0,
                                       flexDirection: 'row',
                                       alignItems: 'center',
                                     },
                                     userHeader: {
                                       flex: 1,
                                       margin: 0,
                                       elevation: 0,
                                       flexDirection: 'row',
                                       justifyContent: 'space-between',
                                       alignItems: 'center',
                                     },
                                     userNickname: {
                                       paddingLeft: 8,
                                       flex: 1,
                                     },
                                     userNicknameLabel: {
                                       height: 20,
                                       fontFamily: 'Rubik-Medium',
                                       fontSize: moderateScale(20, 0.1),
                                       minHeight: 24
                                     },
                                     userSubheadingLabel: {
                                       fontFamily: 'Rubik-Light',
                                       fontSize: moderateScale(16, 0.1)
                                     },
                                   });

  return (
    <View style={{
      margin: 12,
      backgroundColor: 'white',
      borderRadius: 12,
      elevation: 1,
      paddingVertical: 12,
      flexDirection: 'row'
    }}>
      <View style={styles.userTop}>
        <Appbar.BackAction onPress={() => navigation.goBack()}/>
      </View>

      <View style={styles.userHeader}>
        {!student.avatar && <Avatar.Text size={36} label={student.name.toUpperCase().substring(0,2)}/>}
        {student.avatar && <Avatar.Image size={36} source={{
          uri: `https://agenda.granota.cat/avatars/${student.avatar}`
        }}/>}
        <View style={styles.userNickname}>
          <Title style={styles.userNicknameLabel}>
            {student.name}
          </Title>
          <Subheading style={styles.userSubheadingLabel}>{family}</Subheading>
        </View>
      </View>
    </View>
  );

};

export default HeaderBar;
