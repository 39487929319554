import React from "react";
import { StyleSheet, View } from 'react-native';
import { Text } from "react-native-paper";
import { SimpleLineIcons } from '@expo/vector-icons';
import { moderateScale } from 'react-native-size-matters';

const HeadingSection = ({ icon, iconColor, iconSize, title, titleColor, containerStyle, badgeCount}) => {
  const styles = StyleSheet.create({
    title: {
      fontFamily: 'Rubik-Light',
      fontSize: moderateScale(14, 0.1),
      color: titleColor,
      textTransform: 'uppercase',
      marginLeft: icon !== null ? 4.0 : 0
    }
                                   });

  return (
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 6,
      marginHorizontal: 28,
      ...containerStyle
    }}>
      {icon && <SimpleLineIcons name={icon} size={iconSize} color={iconColor}/>}
      <Text style={styles.title}>{title}</Text>
      {badgeCount > 0 && <Text style={{ minWidth: 19,  textAlign: 'center', padding: 4, fontSize: 10, marginLeft: 4, backgroundColor: '#ff3425', color: 'white', borderRadius: 50 }}>{badgeCount}</Text>}
    </View>
  );
};

HeadingSection.defaultProps = {
  icon: null,
  iconColor: 'red',
  iconSize: 14,
  title: null,
  titleColor: 'red',
  containerStyle: {}
};

export default HeadingSection;
