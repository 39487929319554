import React, { useEffect }  from 'react';
import { StatusBar } from 'expo-status-bar';
import { Provider as PaperProvider } from 'react-native-paper';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import MainRoutes from "./src/MainRoutes";
import AppLoading from "./src/components/loading/AppLoading";
import { useFonts } from 'expo-font';
import { theme } from './src/config/theme';


import { store, persistor } from './src/store/store';
//I import SplashScreen from 'react-native-splash-screen';
import * as SplashScreen from 'expo-splash-screen';


export default function App() {

  let [fontsLoaded] = useFonts({
                                 'Rubik-Black': require('./src/assets/fonts/Rubik-Black.ttf'),
                                 'Rubik-BlackItalic': require('./src/assets/fonts/Rubik-BlackItalic.ttf'),
                                 'Rubik-Bold': require('./src/assets/fonts/Rubik-Bold.ttf'),
                                 'Rubik-BoldItalic': require('./src/assets/fonts/Rubik-BoldItalic.ttf'),
                                 'Rubik-Italic': require('./src/assets/fonts/Rubik-Italic.ttf'),
                                 'Rubik-Light': require('./src/assets/fonts/Rubik-Light.ttf'),
                                 'Rubik-LightItalic': require('./src/assets/fonts/Rubik-LightItalic.ttf'),
                                 'Rubik-Medium': require('./src/assets/fonts/Rubik-Medium.ttf'),
                                 'Rubik-MediumItalic': require('./src/assets/fonts/Rubik-MediumItalic.ttf'),
                                 'Rubik-Regular': require('./src/assets/fonts/Rubik-Regular.ttf'),
                               });


  useEffect(() => {
    SplashScreen.hideAsync();
    //SplashScreen.hide();
  }, [])
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PaperProvider theme={theme}>
          <StatusBar style="auto"/>
          {!fontsLoaded ? <AppLoading/> : <MainRoutes/>}
        </PaperProvider>
      </PersistGate>
    </Provider>
  );
}
