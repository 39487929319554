import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { moderateScale } from "react-native-size-matters";
import { FontAwesome5 } from '@expo/vector-icons';
import { TouchableRipple, Text } from 'react-native-paper';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 10,
        padding: 8.0,
        flexDirection: 'row'
    },
    itemBody: {
        flex: 2,
        marginRight: 8.0,
        alignItems: 'center',
        justifyContent: 'center'
    },
    itemCenter: {
        flex: 1.25,
        alignItems: 'center',
        justifyContent: 'center'
    },
    itemCenterText: {
        fontFamily: 'Rubik-Bold',
        fontSize: 18
    },
    itemAction: {
        flex: 1.5
    },
    itemActionButton: {
        flex: 1,
        borderRadius: 12,
        paddingVertical: 12,
        paddingHorizontal: 8,
        borderWidth: 2.0,
        borderColor: '#eee',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

const DeposicioController = ({ initCounter, icon, iconSize, iconColor, label, onChangeCount }) => {
    const [counter, setCounter] = useState(initCounter);

    const increment = () => {
        let count = counter + 1;
        setCounter(count);
        onChangeCount(count);
    };

    const decrement = () => {
        if(counter > 0) {
            let count = counter - 1;
            setCounter(counter-1);
            onChangeCount(count);
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.itemBody}>
                <FontAwesome5 name={icon} size={iconSize} color={iconColor} />
                <Text style={{
                    marginTop: 8,
                    fontSize: moderateScale(14, 0.1),
                    fontFamily: 'Rubik-Bold',
                    textAlign: 'center'
                }}>{label}</Text>
            </View>
            <View style={styles.itemAction}>
                <TouchableRipple style={[styles.itemActionButton, { cursor: 'pointer' }]} onPress={decrement}>
                    <FontAwesome5 name="minus" size={24} color="#444" />
                </TouchableRipple>
            </View>
            <View style={styles.itemCenter}>
                <Text style={styles.itemCenterText}>{counter}</Text>
            </View>
            <View style={styles.itemAction}>
                <TouchableRipple style={[styles.itemActionButton, { cursor: 'pointer' }]} onPress={increment}>
                    <FontAwesome5 name="plus" size={24} color="#444" />
                </TouchableRipple>
            </View>
        </View>
    );
};

DeposicioController.defaultProps = {
    initCounter: 0,
    iconSize: 24,
    iconColor: '#8BC34A'
};

export default DeposicioController;