import React, { useState } from 'react';
import { View } from "react-native";
import { Button, TextInput, Dialog, Text } from "react-native-paper";
import services from "../../../services";

const FormNewSon = ({ visible, familyTitle, familyId, onDismiss, onComplete }) => {
  const [childName, setChildName] = useState('');

  return (
    <Dialog style={{
      width: '33%',
      alignSelf: 'center'
    }} visible={visible} onDismiss={onDismiss}>
      <Dialog.Content>
        <Text style={{ fontSize: 24 }}>Nou fill/a a: {familyTitle}</Text>
        <View style={{
          flexDirection: 'row',
          marginBottom: 12
        }}>
          <TextInput
            style={{
              flex: 1,
              marginRight: 24
            }}
            label="Nom del fill/a"
            value={childName}
            onChangeText={text => setChildName(text)}
          />
        </View>
        <Button mode="contained" onPress={() => services.students.create({
                                                                           name: childName,
                                                                           familyId
                                                                         }).then((res) => {
          setChildName('');
          onComplete();
          onDismiss();
        })}>
          Afegir fill
        </Button>
      </Dialog.Content>
    </Dialog>
  );
};

export default FormNewSon;
