import React, { useState } from 'react';
import { Dialog, Text, RadioButton, Button, TextInput } from "react-native-paper";
import { FlatList, ScrollView, View } from "react-native";
import RenderSelectableClassroom from "../../classroom/RenderSelectableClassroom";
import services from "../../../services";


const FormNewNotification = ({ visible, cursos, onDismiss }) => {
  const [step, setStep] = useState(0);
  const [notiType, setType] = useState('global');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [selectedClassrooms, setSelectedClassrooms] = useState([]);

  const onRenderClassroomItem = ({ index, item }) => {
    return <RenderSelectableClassroom course={item} handleOnPress={() => {
      let exists = selectedClassrooms.indexOf(item.id) !== -1;
      if (exists) {
        let newArr = selectedClassrooms.filter(e => e !== item.id);
        setSelectedClassrooms(newArr);
      } else {
        setSelectedClassrooms([...selectedClassrooms, item.id])
      }
    }} isSelected={selectedClassrooms.indexOf(item.id) !== -1}/>
  };

  const submitNotification = () => {
    let data = {
      title: title,
      text: text,
      type: notiType,
    };
    if (notiType === 'classroom') {
      data = { ...data, classrooms: selectedClassrooms };
    }
    services.notifications.create(data).then((res) => {
      setStep(0);
      setType('global');
      setTitle('');
      setText('');
      setSelectedClassrooms([]);
      onDismiss();
    });
  };

  return (
    <Dialog visible={visible} style={{
      width: '33%',
      alignSelf: 'center'
    }} onDismiss={onDismiss}>
      <Dialog.Content>
        <Text style={{ fontSize: 24, fontFamily: 'Rubik-Bold' }}>Crear nova notificació</Text>

        {step === 0 && (
          <RadioButton.Group onValueChange={value => setType(value)} value={notiType}>
            <RadioButton.Item label="Notificació global: notificar a tot el món" value="global"/>
            <RadioButton.Item label="Notificació curs: notificar segons el curs/os seleccionats" value="classroom"/>
            <RadioButton.Item label="Notificació família: notificar solament a una família especifica" value="familia"/>
          </RadioButton.Group>
        )}

        {((step === 1 && notiType === 'global') || (step === 2 && notiType === 'classroom')) && <>
          <View style={{
            flexDirection: 'column',
            marginVertical: 12
          }}>
            <TextInput
              style={{
                flex: 1,
                marginBottom: 12
              }}
              label="Títol"
              value={title}
              onChangeText={text => setTitle(text)}
            />
            <TextInput
              multiline
              style={{
                flex: 1,
              }}
              label="Texte"
              value={text}
              onChangeText={text => setText(text)}
            />
          </View>
        </>}

        {step === 1 && notiType === 'classroom' && (
          <>
            <ScrollView style={{
              maxHeight: 200
            }}>
              <FlatList
                style={{
                  padding: 6,
                  marginHorizontal: 12,
                  backgroundColor: 'white',
                  borderRadius: 12
                }}
                ListEmptyComponent={() =>
                  <Text style={{ fontSize: 18, fontFamily: 'Rubik-Light', padding: 8 }}>
                    no hi han cursos disponibles.
                  </Text>
                }
                data={cursos}
                renderItem={onRenderClassroomItem}
                keyExtractor={(item, index) => `course-${item.id}`}
              />
            </ScrollView>
          </>
        )}

        <View style={{
          marginTop: 12,
          paddingTop: 12,
          borderTopWidth: 1.0,
          borderTopColor: 'silver',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
          <Button disabled={step === 0} onPress={() => setStep(step - 1)} icon="arrow-left">Anterior</Button>
          {
            (!(step === 1 && notiType === 'global') && !(step === 2 && notiType === 'classroom'))
            && <Button onPress={() => setStep(step + 1)} icon="arrow-right">Següent</Button>}
          {
            ((step === 1 && notiType === 'global') || (step === 2 && notiType === 'classroom'))
            && <Button onPress={submitNotification}>Enviar notificació</Button>}
        </View>

      </Dialog.Content>
    </Dialog>
  );

};

export default FormNewNotification;
