import React, { useState, useEffect } from 'react';
import { Button, Portal, Text, TextInput, Dialog, Paragraph, TouchableRipple, useTheme } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import FormNewSon from "./FormNewSon";
import FormFamily from "./FormFamily";
import FormNewUser from "./FormNewUser";
import FormNewFamily from "./FormNewFamily";
import Pagination from '../../pagination/Pagination';
import services from "../../../services";

const ManageFamilies = ({ currentSchoolYear }) => {
  const { colors } = useTheme();

  const limit = 10;
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const [showCreate, setCreate] = useState(false);
  const [showManage, setManage] = useState(false);
  const [showDelete, setDelete] = useState(false);
  const [showAddSon, setShowAddSon] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [manageFamily, setManageFamily] = useState({});

  const firstFetchRes = (_page=0) => {
    services.families.find({
                             query: {
                               $sort: {
                                 firstName: 1,
                                 lastName: 1,
                               },
                               $skip: _page * limit,
                               $limit: limit
                             }
                           }).then((res) => {
                            setTotal(res.total);
                            setData(res.data);
    })
  };

  useEffect(() => {
    firstFetchRes();
  }, []);

  const deleteItem = (familyId) => {
    services.families.remove(familyId).then((res) => {
      setManageFamily({});
      setDelete(false);
      firstFetchRes();
    })
  };

  const onHandleUtilesResendVerifications = () => {
    services.utiles.create({
      type: 'generate-massive-reverifications'
    }).then((res) => {
      alert('Enviat ' + res + " correus de verificació")
    }).catch((err) => {
      console.log('err', err)
    })
  };

  return (
    <>
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 24
      }}>
        <Text style={styles.headingTitle}>Gestionar Famílies</Text>
        <View style={{ flexDirection: 'row' }}>
          <Button mode="contained" icon="email" onPress={onHandleUtilesResendVerifications} style={{ marginRight: 12 }}>Reenviar verificacions</Button>
          <Button mode="contained" icon="plus" onPress={() => setCreate(true)}>Nova</Button>
        </View>
      </View>

      <View style={styles.mainTable}>
        <View style={styles.tableHeading}>
          <Text style={styles.tableHeadingItem}>Família</Text>
          <Text style={styles.tableHeadingItem}>Accions</Text>
        </View>
        {data.map((reg) =>
                    <View key={reg.id} style={styles.tableRow}>
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItem}>
                          {reg.firstName} {reg.lastName}
                        </Text>
                      </View>
                      <View style={[styles.tableItem, { flexDirection: 'row' }]}>
                        <Button compact mode="text" onPress={() => {
                          setManageFamily(reg);
                          setManage(true);
                        }}>Gestionar membres</Button>
                        <Button compact mode="text" onPress={() => {
                          setManageFamily(reg);
                          setDelete(true);
                        }}>Esborrar família</Button>
                      </View>
                    </View>
        )}

        <Pagination
          totalElements={total}
          elementsPerPage={limit}
          currentPage={currentPage}
          handlePagePress={(page) => {
            setCurrentPage(page)
            firstFetchRes(page-1);
          }}
        />

      </View>
      <Portal>
        <Dialog style={{
          width: '33%',
          alignSelf: 'center'
        }} visible={showDelete} onDismiss={() => {
          setDelete(false);
          setManageFamily({});
        }}>
          <Dialog.Title style={{
            fontFamily: 'Rubik-Bold'
          }}>Atenció!!</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Aquesta acció es irreversible, estàs segur/a de continuar?</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => {
              setDelete(false);
              setManageFamily({});
            }}>Cancel·lar</Button>
            <Button onPress={() => {
              deleteItem(manageFamily.id);
              setManageFamily({});
            }}>Continuar</Button>
          </Dialog.Actions>
        </Dialog>

        <FormNewFamily
          visible={showCreate}
          onDismiss={() => setCreate(false)}
          onComplete={() => {
            firstFetchRes();
            setCreate(false);
          }}
        />
        {(showAddSon && manageFamily.hasOwnProperty('id')) && <FormNewSon
          visible={showAddSon}
          familyTitle={`${manageFamily.firstName} ${manageFamily.lastName}`}
          familyId={manageFamily.id}
          onDismiss={() => {
            setShowAddSon(false);
            setManage(true);
          }}
          onComplete={() => setManage(true)}
        />}
        {(showAddUser && manageFamily.hasOwnProperty('id')) && <FormNewUser
          visible={showAddUser}
          familyTitle={`${manageFamily.firstName} ${manageFamily.lastName}`}
          familyId={manageFamily.id}
          onDismiss={() => {
            setShowAddUser(false);
            setManage(true);
          }}
          onComplete={() => setManage(true)}
        />}
        {(showManage && manageFamily.hasOwnProperty('id')) && <FormFamily
          visible={showManage}
          familyId={manageFamily.id}
          familyTitle={`${manageFamily.firstName} ${manageFamily.lastName}`}
          familyUsers={manageFamily.tutors}
          familyStudents={manageFamily.students}
          currentSchoolYear={currentSchoolYear.id}
          onAddNewSon={() => setShowAddSon(true)}
          onAddNewUser={() => setShowAddUser(true)}
          onDismiss={() => setManage(false)}
        />}

      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
                                   headingTitle: {
                                     fontSize: 24,
                                     fontFamily: 'Rubik-Medium',
                                   },
                                   mainTable: {
                                     margin: 12,
                                     padding: 12
                                   },
                                   tableHeading: {
                                     flexDirection: 'row',
                                   },
                                   tableRow: {
                                     flexDirection: 'row'
                                   },
                                   tableHeadingItem: {
                                     flex: 1,
                                     padding: 6,
                                     margin: 4,
                                     backgroundColor: '#a3d77f',
                                     color: 'white',
                                     fontFamily: 'Rubik-Light',
                                     textTransform: 'uppercase'
                                   },
                                   tableItem: {
                                     flex: 1,
                                     padding: 6,
                                     margin: 4,
                                     backgroundColor: '#e5efdf',
                                     fontFamily: 'Rubik-Medium',
                                     alignItems: 'center',
                                     textTransform: 'uppercase'
                                   }
                                 });

export default ManageFamilies;
