import React from "react";
import { useState, useEffect } from "react";
import { View, Platform } from "react-native";
import { IconButton, Menu, Chip } from "react-native-paper";
import RenderStudent from "../sons/RenderStudent";
import services from "../../services";
import { format } from "date-fns-tz";

const RenderClassroomStudent = ({ onPressStudent, onPressAssistance, onPressDailyControl, onPressAgenda, onPressNotifications, date, item, classroom }) => {
  const [menu, setMenu] = useState(false);
  const [assistanceObj, setAssistanceObj] = useState({});
  const [assistance, setAssistance] = useState(false);
  const [haveMedicaments, setHaveMedicaments] = useState(false);
  const [haveComunicacio, setHaveComunicacio] = useState(false)

  const toggleAssistance = () => {
    let value = !assistance;
    if (assistanceObj.hasOwnProperty('id')) {
      services.studentAttendance.remove(null, {
        query: {
          studentId: item.studentId,
          date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' })
        }
      });
      setAssistanceObj({});
    } else {
      services.studentAttendance.create({
        studentId: item.studentId,
        date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' })
      })
    }
    setAssistance(value);
  };

  useEffect(() => {
    services.studentAttendance.find({
      query: {
        studentId: item.studentId,
        date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' })
      }
    }).then((res) => {
      if (res.total > 0) {
        setAssistanceObj(res.data[0]);
        setAssistance(true);
      } else {
        setAssistanceObj({});
        setAssistance(false);
      }

      services.agendaMedicament.find({
        query: {
          date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
          studentId: item.studentId,
          paginate: false,
          $sort: { createdAt: 1 }
        }
      }).then((meds) => {
        if (meds.length > 0) {
          setHaveMedicaments(true);
        }
        services.agendaFamilia.find({
          query: {
            date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
            studentId: item.studentId,
          }
        }).then((agendaFam) => {
          if (agendaFam.total > 0) {
            setHaveComunicacio(true);
          }
        })
      });

    });
  }, [date, item]);

  return <View>
    <View style={{
      flexDirection: 'row',
      alignItems: 'flex-start'
    }}>
      <RenderStudent handleOnPress={onPressStudent} studentId={item.studentId} currentSchoolYear={classroom.schoolyearId}
        showFamily={true} />
      <View style={{
        flexDirection: 'row'
      }}>
        <IconButton
          icon="marker-check"
          color={assistance ? "#4CAF50" : "#444"}
          size={24}
          onPress={toggleAssistance}
        />
        <Menu
          visible={menu}
          onDismiss={() => setMenu(false)}
          anchor={
            <IconButton
              icon="dots-vertical"
              color={"#444"}
              size={24}
              onPress={() => setMenu(true)}
            />
          }>
          <Menu.Item
            icon="clock-outline"
            titleStyle={{
              fontFamily: 'Rubik-Light'
            }}
            onPress={() => {
              setMenu(false);
              onPressAssistance(item.studentId);
            }} title="Assistencia"
          />
          <Menu.Item
            icon="notebook"
            titleStyle={{
              fontFamily: 'Rubik-Light'
            }}
            onPress={() => {
              setMenu(false);
              onPressDailyControl(item.studentId);
            }}
            title="Control diari" />
          <Menu.Item
            icon="calendar"
            titleStyle={{
              fontFamily: 'Rubik-Light'
            }}
            onPress={() => {
              setMenu(false);
              onPressAgenda(item.studentId);
            }} title="Comunicacions" />
        </Menu>
      </View>
    </View>

    <View style={{
      marginLeft: Platform.OS === 'web' ? 48 : 12
    }}>
      {haveMedicaments && <Chip textStyle={{ color: '#444' }} style={{ backgroundColor: '#f5fbf4', marginBottom: 8 }} icon="information">Consultar medicació per avui</Chip>}
      {haveComunicacio && <Chip textStyle={{ color: '#444' }} style={{ backgroundColor: '#f5fbf4', marginBottom: 12 }} icon="information">Consultar Comunicacions</Chip>}
    </View>
  </View>

};

export default RenderClassroomStudent;
