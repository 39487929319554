import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { Button, Text, Paragraph, Dialog, Portal, TextInput } from "react-native-paper";
import services from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { manuallyChangeYearId } from "../../../store/info/actions";

const ManageYears = () => {
  const dispatch = useDispatch();
  const { currentYearId } = useSelector(state => state.info);
  const [currentYear, setCurrentYear] = useState(currentYearId);


  const [skip, setSkip] = useState(0);
  const [haveMore, setHaveMore] = useState(false);
  const [data, setData] = useState([]);
  const [newCursName, setNewCursName] = useState('');

  const [showDelete, setDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [showCreate, setCreate] = useState(false);
  const firstFetchRes = () => {
    services.schoolyears.find({
                                query: {
                                  $sort: {
                                    name: -1
                                  }
                                }
                              }).then((res) => {
      setSkip(skip + res.data.length);
      setData(res.data);
      if (res.total > (skip + res.data.length)) {
        setHaveMore(true);
      } else {
        setHaveMore(false);
      }
    });
  };


  useEffect(() => {
    firstFetchRes();
  }, []);

  const deleteItem = (yearId) => {
    if (currentYear === yearId) {
      setDelete(false);
      alert('No pots esborrar el curs acadèmic actual, primer tens que canviar a un altre.');
    } else {
      services.schoolyears.remove(yearId).then((res) => {
        firstFetchRes();
        setDelete(false);
      });
    }
  };

  const onMarkToCurrentYear = (yearId) => {
    services.info.patch(1, {
      currentYearId: yearId
    }).then((res) => {
      dispatch(manuallyChangeYearId(yearId));
      setCurrentYear(yearId);
    })
  };

  return (
    <>
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 24
      }}>
        <Text style={styles.headingTitle}>Gestionar cursos acadèmics</Text>
        <View>
          <Button mode="contained" icon="plus" onPress={() => setCreate(true)}>Nou</Button>
        </View>
      </View>

      <View style={styles.mainTable}>
        <View style={styles.tableHeading}>
          <Text style={styles.tableHeadingItem}>Curs</Text>
          <Text style={styles.tableHeadingItem}>Accions</Text>
        </View>
        {data.map((curs) =>
                    <View key={curs.id} style={styles.tableRow}>
                      <View style={[styles.tableItem, { flexDirection: 'row' }]}>
                        <Text style={styles.tableItem}>{curs.name}</Text>
                        {currentYear === curs.id && <Text style={{
                          fontSize: 12,
                          padding: 6,
                          margin: 4,
                          backgroundColor: 'lightgoldenrodyellow',
                          alignSelf: 'center',
                          borderRadius: 8
                        }}>Curs acadèmic actual</Text>}
                      </View>
                      <View style={[styles.tableItem, { flexDirection: 'row' }]}>
                        {currentYear !== curs.id && <Button compact icon="star" mode="text" onPress={() => {
                          onMarkToCurrentYear(curs.id);
                        }}>Establir com actual</Button>}
                        <Button compact icon="delete" mode="text" onPress={() => {
                          setSelectedRow(curs);
                          setDelete(true);
                        }}>Esborrar curs acadèmic</Button>
                      </View>
                    </View>
        )}
      </View>

      <Portal>
        <Dialog style={{
          width: '33%',
          alignSelf: 'center'
        }} visible={showDelete} onDismiss={() => setDelete(false)}>
          <Dialog.Title style={{
            fontFamily: 'Rubik-Bold'
          }}>Atenció!!</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Aquesta acció es irreversible, estàs segur/a de continuar?</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => {
              setDelete(false);
              setSelectedRow({});
            }}>Cancel·lar</Button>
            <Button onPress={() => {
              deleteItem(selectedRow.id);
              setSelectedRow({});
            }}>Continuar</Button>
          </Dialog.Actions>
        </Dialog>
        <Dialog style={{
          width: '33%',
          alignSelf: 'center'
        }} visible={showCreate} onDismiss={() => setCreate(false)}>
          <Dialog.Content>
            <TextInput
              label="Curs acadèmic"
              placeholder="2019-2020"
              value={newCursName}
              onChangeText={text => setNewCursName(text)}
              onKeyPress={(event) => {
                if (event.nativeEvent.key === "Enter") {
                  if (newCursName.length > 0) {
                    services.schoolyears.create({
                                                  name: newCursName
                                                }).then((res) => {
                      firstFetchRes();
                      setCreate(false);
                    })
                  }
                }
              }}
            />
          </Dialog.Content>
        </Dialog>
      </Portal>

    </>
  );
};

const styles = StyleSheet.create({
                                   headingTitle: {
                                     fontSize: 24,
                                     fontFamily: 'Rubik-Medium',
                                   },
                                   mainTable: {
                                     margin: 12,
                                     padding: 12
                                   },
                                   tableHeading: {
                                     flexDirection: 'row',
                                   },
                                   tableRow: {
                                     flexDirection: 'row'
                                   },
                                   tableHeadingItem: {
                                     flex: 1,
                                     padding: 6,
                                     margin: 4,
                                     backgroundColor: '#a3d77f',
                                     color: 'white',
                                     fontFamily: 'Rubik-Light',
                                     textTransform: 'uppercase'
                                   },
                                   tableItem: {
                                     flex: 1,
                                     padding: 6,
                                     margin: 4,
                                     backgroundColor: '#e5efdf',
                                     fontFamily: 'Rubik-Medium',
                                   },
                                 });

export default ManageYears;
