import React, { useState } from "react";
import { Surface, Text, TextInput, Title, Subheading, Button, useTheme, Snackbar, Portal } from "react-native-paper";
import { ImageBackground, View, StyleSheet, Platform, Dimensions  } from "react-native";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { useDispatch } from "react-redux";
import useDimensions from '@rnhooks/dimensions';
import bgContent from '../assets/bg/bg-login.png';
import { doLogin } from "../store/user/actions";

const LoginScreen = ({ navigation }) => {
  const dispatch = useDispatch();
  const { colors } = useTheme();
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showErrorLogin, setShowErrorLogin] = useState(false);
  const screenWidth = useDimensions('window').width;

  const styles = StyleSheet.create({
                                     background: {
                                       flex: 1, width: '100%', height: '100%', padding: 0
                                     },
                                     contentContainer: {
                                       flex: 1,
                                       padding: 12,
                                       paddingBottom: 0,
                                       alignSelf: 'center',
                                       alignItems: 'center',
                                       width: (Platform.OS === 'web' && screenWidth > 600) ? '33%' : '100%',
                                       justifyContent: (Platform.OS === 'web' && screenWidth > 600) ? 'center' : 'flex-end',
                                     },
                                     title: {
                                       fontFamily: 'Rubik-Bold',
                                     },
                                     subtitle: {
                                       fontFamily: 'Rubik-Light',
                                       marginBottom: 16
                                     },
                                     formContainer: {
                                       width: '100%',
                                       backgroundColor: '#fff',
                                       elevation: 0,
                                       padding: 24,
                                       borderRadius: 24,
                                       borderBottomLeftRadius: (Platform.OS === 'web') ? 24 : 0,
                                       borderBottomRightRadius: (Platform.OS === 'web') ? 24 : 0,
                                     },
                                     btnSubmit: {
                                       alignSelf: 'center',
                                     },
                                     btnRecoveryLabel: {
                                       fontSize: 14
                                     },
                                     btnSubmitLabel: {
                                       color: 'white'
                                     },
                                     inputContainer: {
                                       borderRadius: 12,
                                       overflow: 'hidden',
                                       marginBottom: 12
                                     },
                                     textInput: {
                                       borderRadius: 12,
                                       backgroundColor: '#dbf496',
                                     }
                                   });

  const onClickLogin = () => {
    setIsSubmitting(true);
    dispatch(doLogin({
                       email,
                       password: pwd
                     },
                     () => {
                       setIsSubmitting(false);
                       navigation.replace('Loading');
                       console.log('LOGGED!');
                     }, (err) => {
        setIsSubmitting(false);
        setShowErrorLogin(true);
      }));
  };

  return (
    <KeyboardAwareScrollView
    resetScrollToCoords={{ x: 0, y: 0 }}
    automaticallyAdjustContentInsets={true}
    contentContainerStyle={{
      backgroundColor: '#47b000',
      flexGrow: 1,
      justifyContent: 'center'
    }}
    scrollEnabled>
    <ImageBackground
      source={bgContent}
      resizeMode='cover'
      style={styles.background}
    >

      <View style={styles.contentContainer}>
        <Surface style={styles.formContainer}>
          <Title style={styles.title}>Benvingut</Title>
          <Subheading style={styles.subtitle}>Accedeix al sistema per continuar</Subheading>
          <View style={styles.inputContainer}>
            <TextInput
              autoCorrect={false}
              autoCompleteType="email"
              autoCapitalize="none"
              textContentType="emailAddress"
              keyboardType="email-address"
              importantForAutofill="yes"
              dense
              style={styles.textInput}
              mode="flat"
              label="Correu electrònic"
              value={email}
              underlineColor={"#dbf496"}
              theme={{
                colors: {
                  text: colors.primary,
                  placeholder: 'rgba(0,0,0,.25)'
                }
              }}
              onChangeText={text => setEmail(text)}
            />
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              autoCorrect={false}
              autoCompleteType="password"
              secureTextEntry
              textContentType="password"
              keyboardType="default"
              importantForAutofill="yes"
              dense
              style={styles.textInput}
              mode="flat"
              label="Contrasenya"
              value={pwd}
              theme={{
                colors: {
                  text: colors.primary,
                  placeholder: 'rgba(0,0,0,.25)'
                }
              }}
              onChangeText={text => setPwd(text)}
            />
          </View>

          <View style={{
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginBottom: 32
          }}>
            {/*

            <Button mode="text" onPress={() => navigation.navigate('Signup')}>Crear Compte</Button>
            */}
            <Button loading={isSubmitting} disabled={isSubmitting} style={styles.btnSubmit} labelStyle={styles.btnSubmitLabel} mode="contained"
                    onPress={onClickLogin}>
              Accedir
            </Button>
          </View>

          <Button labelStyle={styles.btnRecoveryLabel} mode="none" onPress={() => navigation.navigate('Recovery')}>
            Recuperar contrasenya
          </Button>
        </Surface>
      </View>
    </ImageBackground>

<Portal>

<Snackbar
        visible={showErrorLogin}
        theme={{
          colors: {
              onSurface: "rgba(204, 0, 0, 0.8)",
          },
      }}
        onDismiss={() => setShowErrorLogin(false)}
        action={{
          label: 'Tancar',
          onPress: () => {
            // Do something
            setShowErrorLogin(false)
          },
        }}>
        El usuari no existeix
      </Snackbar>

</Portal>

    </KeyboardAwareScrollView>
  )
};

export default LoginScreen;
