import React from 'react';
import { Avatar, Text, TouchableRipple, useTheme } from "react-native-paper";
import { StyleSheet, View } from "react-native";
import { moderateScale } from "react-native-size-matters";

const RenderParentItem = (props) => {
  const { colors } = useTheme();
  const { isSelected, item, handleOnPress, showVerifyStatus } = props;
  const { id, avatar, name, isVerified } = item;

  const styles = StyleSheet.create({
                                     container: {
                                       padding: 8,
                                       backgroundColor: isSelected ? colors.accent : 'white',
                                       borderRadius: 12,
                                     },
                                     parent: {
                                       flexDirection: 'row',
                                     },
                                     content: {
                                       justifyContent: 'center',
                                       alignItems: 'flex-start',
                                       marginLeft: 6
                                     },
                                     sonName: {
                                       color: isSelected ? 'white' : colors.text,
                                       fontFamily: 'Rubik-Medium',
                                       fontSize: moderateScale(16, 0.1),
                                       marginBottom: 2.0
                                     },
                                     sonClassroom: {
                                       color: isSelected ? 'white' : colors.text,
                                       fontFamily: 'Rubik-Light',
                                       fontSize: moderateScale(12, 0.1)
                                     }
                                   });

  return (
    <TouchableRipple onPress={() => handleOnPress(id)} style={[styles.container, { cursor: 'pointer' }]}>
      <View style={styles.parent}>
        {!avatar && <Avatar.Text size={36} label={name.toUpperCase().substring(0,2)}/>}
        {avatar && <Avatar.Image size={36} source={{
          uri: `https://agenda.granota.cat/avatars/${avatar}`
        }}/>}
        <View style={styles.content}>
          <Text style={styles.sonName}>{name}</Text>
          {showVerifyStatus &&
          <Text
            style={[styles.sonClassroom, !isVerified ? { color: 'orangered' } : {}]}>{isVerified ? 'Verificat' : 'Pendent de verificació'}</Text>
          }
        </View>
      </View>
    </TouchableRipple>
  );
};

RenderParentItem.defaultProps = {
  showVerifyStatus: true
};

export default RenderParentItem;
