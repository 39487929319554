import React from 'react';
import { View, StyleSheet } from 'react-native';
import { moderateScale } from "react-native-size-matters";
import { FontAwesome5 } from '@expo/vector-icons';
import { Text } from 'react-native-paper';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 4.0,
        borderRadius: 6.0,
        padding: 12.0,
        paddingHorizontal: 18.0,
        flexDirection: 'column',
    },
    itemBody: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemCenter: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    itemCenterText: {
        fontFamily: 'Rubik-Bold',
        fontSize: 32
    }
});

const DeposicioViewerItem = ({ count, icon, iconSize, iconColor, label }) => {
    return (
        <View style={styles.container}>
            <View style={styles.itemBody}>
                <FontAwesome5 name={icon} size={iconSize} color={iconColor} />
                <Text style={{
                    marginTop: 8,
                    fontSize: moderateScale(14, 0.1),
                    fontFamily: 'Rubik-Bold',
                    textAlign: 'center',
                    color: iconColor
                }}>{label}</Text>
            </View>
            <View style={styles.itemCenter}>
                <Text style={[styles.itemCenterText, { color: iconColor }]}>{count}</Text>
            </View>
        </View>
    );
};

DeposicioViewerItem.defaultProps = {
    count: 0,
    iconSize: 24,
    iconColor: '#8BC34A'
};

export default DeposicioViewerItem;