import React, { useState, useEffect } from 'react';
import { FlatList, ScrollView, StyleSheet, View } from "react-native";
import { Button, Surface, Text, Portal, Paragraph } from "react-native-paper";
import FormNewNotification from "./FormNewNotification";
import services from "../../../services";
import RenderNotificationItem from "../../notifications/RenderNotificationItem";

const ManageNotifications = ({ currentSchoolYear }) => {
  const [cicles, setCicles] = useState([]);
  const [globalNotifications, setGlobalNotifications] = useState([]);
  const [otherNotifications, setOtherNotifications] = useState([]);
  const [showCreate, setCreate] = useState(false);

  useEffect(() => {
    services.classrooms.find({
                               query: {
                                 schoolyearId: currentSchoolYear.id,
                                 paginate: false
                               }
                             }).then((res) => {
      setCicles(res);
    });
    services.notifications.find({
                                  query: {
                                    type: 'global',
                                    $sort: {
                                      createdAt: -1
                                    }
                                  }
                                }).then((notifications) => {
      setGlobalNotifications(notifications.data);
    });
    services.notifications.find({
                                  query: {
                                    type: {$ne:'global'},
                                    $sort: {
                                      createdAt: -1
                                    }
                                  }
                                }).then((notifications) => {
      setOtherNotifications(notifications.data);
    });
  }, []);


  const delGlobalNotification = (id) => {
    services.notifications.remove(id).then((res) => {
      let newArr = globalNotifications.filter(noti => noti.id !== id);
      setGlobalNotifications(newArr);
    });
  };


  const onRenderNotificationItem = ({ index, item }) => {
    return <RenderNotificationItem item={item} onDeleteNotification={(itemId) => delGlobalNotification(itemId)} />
  };

  return (
    <>
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 24
      }}>
        <Text style={styles.headingTitle}>Control de notificacions</Text>
        <View>
          <Button mode="contained" icon="plus" onPress={() => setCreate(true)}>Crear notificació</Button>
        </View>
      </View>


      <View style={styles.bodyContent}>
        <Surface style={styles.notificationListBox}>
          <Text style={styles.headingTitle}>Notificacions globals</Text>

            <FlatList
              style={{
                padding: 6,
                marginHorizontal: 12,
                backgroundColor: 'white',
                borderRadius: 12,
                maxHeight: 600
              }}
              ListEmptyComponent={() =>
                <Text style={{ fontSize: 18, fontFamily: 'Rubik-Light', padding: 8 }}>
                  no hi ha cap notificació.
                </Text>
              }
              data={globalNotifications}
              renderItem={onRenderNotificationItem}
              keyExtractor={(item, index) => `notification-${item.id}`}
            />


        </Surface>
        <Surface style={styles.notificationListBox}>
          <Text style={styles.headingTitle}>Notificacions segmentades</Text>


            <FlatList
              style={{
                padding: 6,
                marginHorizontal: 12,
                backgroundColor: 'white',
                borderRadius: 12,
                maxHeight: 600
              }}
              ListEmptyComponent={() =>
                <Text style={{ fontSize: 18, fontFamily: 'Rubik-Light', padding: 8 }}>
                  no hi ha cap notificació.
                </Text>
              }
              data={otherNotifications}
              renderItem={onRenderNotificationItem}
              keyExtractor={(item, index) => `notification-${item.id}`}
            />


        </Surface>
      </View>

      <Portal>
        <FormNewNotification visible={showCreate} cursos={cicles} onDismiss={() => setCreate(false)}/>
      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
                                   headingTitle: {
                                     fontSize: 24,
                                     fontFamily: 'Rubik-Medium',
                                   },
                                   bodyContent: {
                                     flexDirection: 'row'
                                   },
                                   notificationListBox: {
                                     flex: 1,
                                     margin: 12,
                                     padding: 12,
                                     borderRadius: 12
                                   }
                                 });


export default ManageNotifications;
