import React, { useState, useEffect } from "react";
import { FlatList, View } from "react-native";
import { Button, Surface, Text, Dialog, useTheme } from "react-native-paper";
import ContainedButton from "../../buttons/ContainedButton";
import services from "../../../services";
import RenderNavbarSonItem from "../../sons/NavbarSons/RenderNavbarSonItem";
import RenderParentItem from "../../parents/RenderParentItem/RenderParentItem";
import FormAssignClassroom from "./FormAssignClassroom";
import FormParentDetails from './FormParentDetails';

const FormFamily = ({ familyTitle, familyId, familyUsers, familyStudents, visible, currentSchoolYear, onDismiss, onAddNewSon, onAddNewUser }) => {
  const { colors } = useTheme();
  const [pares, setPares] = useState(familyUsers);
  const [fills, setFills] = useState(familyStudents);
  const [showAssignateStudent, setShowAssignateStudent] = useState({});
  const [showParentinfo, setShowParentInfo] = useState({});

  const fetchFamily = (famId) => {
    services.families.get(famId).then((res) => {
      setPares(res.tutors);
      setFills(res.students);
    });
  };

  useEffect(() => {
    fetchFamily(familyId);
  }, []);

  const onRenderItemSon = ({ index, item }) => {
    return <RenderNavbarSonItem item={item} handleOnPress={() => setShowAssignateStudent(item)}
      currentSchoolYear={currentSchoolYear} />
  };

  const onRenderItemUser = ({ index, item }) => {
    return <RenderParentItem item={item} handleOnPress={() => setShowParentInfo(item)} />
  };

  return (
    <>
      <Dialog style={{
        width: '33%',
        alignSelf: 'center'
      }} visible={visible} onDismiss={onDismiss}>
        <Dialog.Content>
          <Text style={{ fontSize: 24, fontFamily: 'Rubik-Bold' }}>Família {familyTitle}</Text>

          <View style={{
            flexDirection: 'row',
            padding: 24
          }}>
            <Surface style={{
              backgroundColor: colors.primary,
              flex: 1,
              marginRight: 12,
              borderRadius: 6,
              padding: 6
            }}>
              <View style={{ marginTop: 8, alignItems: 'center' }}>
                <Text style={{
                  color: '#fff',
                  fontSize: 18,
                  fontFamily: 'Rubik-Bold'
                }}>Pares / Tutors</Text>
              </View>
              <View style={{ flex: 1, alignItems: 'center', marginTop: 12 }}>
                <FlatList
                  style={{
                    width: '100%',
                    backgroundColor: '#fff',
                    padding: 6,
                    borderRadius: 12,
                    flex: 1
                  }}
                  contentContainerStyle={{ flexGrow: 1 }}
                  ListEmptyComponent={() => <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontFamily: 'Rubik-Light', marginBottom: 12 }}>No hi ha cap credencial per a:</Text>
                    <Text style={{ fontFamily: 'Rubik-Light', marginBottom: 12 }}>pare / mare / tutor</Text>
                    <ContainedButton icon={'plus-circle'} lblButton="Afegir" onPress={() => {
                      onAddNewUser();
                      onDismiss(false);
                    }} />
                  </View>}
                  data={pares}
                  renderItem={onRenderItemUser}
                  keyExtractor={(item, index) => `family-son-${index}`}
                />
                {pares.length > 0 && <View>
                  <ContainedButton icon={'plus-circle'} lblButton="Crear credencial" onPress={() => {
                    onAddNewUser();
                    onDismiss(false);
                  }} />
                </View>}
                <View>
                </View>
              </View>
            </Surface>

            <Surface style={{
              backgroundColor: colors.primary,
              flex: 1,
              marginLeft: 12,
              borderRadius: 6,
              padding: 6
            }}>
              <View style={{ marginTop: 8, alignItems: 'center' }}>
                <Text style={{
                  color: '#fff',
                  fontSize: 18,
                  fontFamily: 'Rubik-Bold'
                }}>Fill/s</Text>
              </View>
              <View style={{ flex: 1, alignItems: 'center', marginTop: 12 }}>
                <FlatList
                  style={{
                    width: '100%',
                    backgroundColor: '#fff',
                    padding: 6,
                    borderRadius: 12,
                    flex: 1
                  }}
                  contentContainerStyle={{ flexGrow: 1 }}
                  ListEmptyComponent={() => <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontFamily: 'Rubik-Light', marginBottom: 12 }}>No hi ha cap fill</Text>
                    <ContainedButton icon={'plus-circle'} lblButton="Afegir" onPress={() => {
                      onAddNewSon();
                      onDismiss(false);
                    }} />
                  </View>}
                  data={fills}
                  renderItem={onRenderItemSon}
                  keyExtractor={(item, index) => `family-son-${index}`}
                />
                {fills.length > 0 && <View>
                  <ContainedButton icon={'plus-circle'} lblButton="Afegir fill/a" onPress={() => {
                    onAddNewSon();
                    onDismiss(false);
                  }} />
                </View>}
                <View>
                </View>
              </View>
            </Surface>
          </View>

          <Button mode="contained" onPress={onDismiss}>
            Tancar
          </Button>
        </Dialog.Content>
      </Dialog>

      {showAssignateStudent && showAssignateStudent.hasOwnProperty('id') &&
        <FormAssignClassroom
          visible={true}
          studentId={showAssignateStudent.id}
          studentName={showAssignateStudent.name}
          familyTitle={familyTitle}
          schoolYearId={currentSchoolYear}
          onDismiss={() => {
            setShowAssignateStudent({})
          }}
          onComplete={() => {
            fetchFamily(familyId);
            setShowAssignateStudent({})
          }
          }
        />
      }

      {showParentinfo && showParentinfo.hasOwnProperty('id') &&
        <FormParentDetails
          visible={true}
          parentId={showParentinfo.id}
          onDismiss={() => {
            setShowParentInfo({})
          }}
          onComplete={() => {
            fetchFamily(familyId);
            setShowParentInfo({})
          }
          }
        />
      }

    </>
  );

};

export default FormFamily;
