import React, { useState } from "react";
import { Button, Checkbox, Subheading, Surface, Text, TextInput, Title, useTheme } from "react-native-paper";
import { ImageBackground, StyleSheet, View } from "react-native";
import bgContent from "../assets/bg/bg-login.png";
import { doSignup } from "../store/user/actions";

const SignupScreen = () => {
  const { colors } = useTheme();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [repeatPwd, setRepeatPwd] = useState('');
  const [checkTOS, setCheckTOS] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const styles = StyleSheet.create({
                                     background: {
                                       flex: 1, width: '100%', height: '100%', padding: 0
                                     },
                                     contentContainer: {
                                       flex: 1,
                                       padding: 12,
                                       paddingBottom: 0,
                                       justifyContent: 'flex-start',
                                       alignItems: 'center'
                                     },
                                     title: {
                                       fontFamily: 'Rubik-Bold',
                                     },
                                     subtitle: {
                                       fontFamily: 'Rubik-Light',
                                       marginBottom: 16
                                     },
                                     formContainer: {
                                       width: '100%',
                                       flex: 1,
                                       backgroundColor: '#fff',
                                       elevation: 0,
                                       padding: 24,
                                       borderRadius: 24,
                                       borderBottomLeftRadius: 0,
                                       borderBottomRightRadius: 0,
                                     },
                                     btnSubmit: {
                                       alignSelf: 'center',
                                     },
                                     btnRecoveryLabel: {
                                       fontSize: 14
                                     },
                                     btnSubmitLabel: {
                                       color: 'white'
                                     },
                                     inputContainer: {
                                       borderRadius: 12,
                                       overflow: 'hidden',
                                       marginBottom: 12
                                     },
                                     textInput: {
                                       borderRadius: 12,
                                       backgroundColor: '#dbf496',
                                     }
                                   });

  const onClickSubmit = async () => {
    // check validation fields
    setIsSubmitting(true);
    doSignup({ name, email, password: pwd }, () => {
      setIsSubmitting(false)
    }, (err) => {
      console.log('errorrrrr on signup', err)
    })

  };


  return (
    <ImageBackground
      source={bgContent}
      resizeMode='cover'
      style={styles.background}
    >

      <View style={styles.contentContainer}>
        <Surface style={styles.formContainer}>
          <Title style={styles.title}>Primer pas</Title>
          <Subheading style={styles.subtitle}>Omple el formulari per continuar amb el registre</Subheading>
          <View style={styles.inputContainer}>
            <TextInput
              autoCorrect={false}
              autoCompleteType="name"
              autoCapitalize="none"
              textContentType="givenName"
              keyboardType="default"
              importantForAutofill="yes"
              dense
              style={styles.textInput}
              mode="flat"
              label="Nom de pila"
              value={name}
              underlineColor={"#dbf496"}
              theme={{
                colors: {
                  text: colors.primary,
                  placeholder: 'rgba(0,0,0,.25)'
                }
              }}
              onChangeText={text => setName(text)}
            />
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              autoCorrect={false}
              autoCompleteType="email"
              autoCapitalize="none"
              textContentType="emailAddress"
              keyboardType="email-address"
              importantForAutofill="yes"
              dense
              style={styles.textInput}
              mode="flat"
              label="Correu electrònic"
              value={email}
              underlineColor={"#dbf496"}
              theme={{
                colors: {
                  text: colors.primary,
                  placeholder: 'rgba(0,0,0,.25)'
                }
              }}
              onChangeText={text => setEmail(text)}
            />
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              autoCorrect={false}
              autoCompleteType="password"
              secureTextEntry
              textContentType="password"
              keyboardType="default"
              importantForAutofill="yes"
              dense
              style={styles.textInput}
              mode="flat"
              label="Contrasenya"
              value={pwd}
              theme={{
                colors: {
                  text: colors.primary,
                  placeholder: 'rgba(0,0,0,.25)'
                }
              }}
              onChangeText={text => setPwd(text)}
            />
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              autoCorrect={false}
              autoCompleteType="password"
              secureTextEntry
              textContentType="password"
              keyboardType="default"
              importantForAutofill="yes"
              dense
              style={styles.textInput}
              mode="flat"
              label="Repeteix la contrasenya"
              value={repeatPwd}
              theme={{
                colors: {
                  text: colors.primary,
                  placeholder: 'rgba(0,0,0,.25)'
                }
              }}
              onChangeText={text => setRepeatPwd(text)}
            />
          </View>


          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 24
          }}>
            <Checkbox
              status={checkTOS ? 'checked' : 'unchecked'}
              onPress={() => {
                setCheckTOS(!checkTOS);
              }}
            />
            <Text style={{ fontSize: 12 }}>He llegit i accepto els termes i condicions de l'aplicació Granota</Text>
          </View>

          <View style={{
            width: '100%',
            marginBottom: 32
          }}>
            <Button loading={isSubmitting} disabled={isSubmitting} style={styles.btnSubmit} labelStyle={styles.btnSubmitLabel} mode="contained"
                    onPress={onClickSubmit}>
              Registrar-se
            </Button>
          </View>
        </Surface>
      </View>
    </ImageBackground>
  );
};

export default SignupScreen;
