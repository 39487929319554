import React, { useState } from 'react';
import { View } from 'react-native';
import { Surface, Title, TextInput, Button } from 'react-native-paper';
import { useTheme } from 'react-native-paper';
import services from '../../services/index';

const RecoveryStepTwo = ({ styles, setDisplayError, goNext }) => {
    const { colors } = useTheme();
    const [pwd, setPwd] = useState('');
    const [pwdVerify, setPwdVerify] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);
    const onSubmitRecovery = () => {
        setSubmitting(true);
        if(pwd.length > 3) { 
            if (pwd === pwdVerify) {
                goNext(pwd);
            }else{
                setSubmitting(false);
                setDisplayError('Les contrasenyes no coincideixen')
            }
        } else {
            setSubmitting(false);
            setDisplayError('La contrasenya té que tenir com a mínim més de 3 caràcters')
        }

    };

    return (
        <View style={styles.contentContainer}>
            <Surface style={styles.formContainer}>
                <Title style={styles.title}>Recuperar contrasenya</Title>
                <Title style={styles.subtitle}>Introdueix la nova contrasenya que vols tenir.</Title>

                <View style={styles.inputContainer}>
                    <TextInput
                        autoCorrect={false}
                        autoCompleteType="password"
                        secureTextEntry
                        textContentType="password"
                        keyboardType="default"
                        importantForAutofill="yes"
                        dense
                        style={styles.textInput}
                        mode="flat"
                        label="Contrasenya"
                        value={pwd}
                        theme={{
                            colors: {
                                text: colors.primary,
                                placeholder: 'rgba(0,0,0,.25)'
                            }
                        }}
                        onChangeText={text => setPwd(text)}
                    />
                </View>

                <View style={styles.inputContainer}>
                    <TextInput
                        autoCorrect={false}
                        autoCompleteType="password"
                        secureTextEntry
                        textContentType="password"
                        keyboardType="default"
                        importantForAutofill="yes"
                        dense
                        style={styles.textInput}
                        mode="flat"
                        label="Repetir Contrasenya"
                        value={pwdVerify}
                        theme={{
                            colors: {
                                text: colors.primary,
                                placeholder: 'rgba(0,0,0,.25)'
                            }
                        }}
                        onChangeText={text => setPwdVerify(text)}
                    />
                </View>


                <View style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    marginBottom: 32
                }}>
                    <Button loading={isSubmitting} disabled={isSubmitting} style={styles.btnSubmit} labelStyle={styles.btnSubmitLabel} mode="contained"
                        onPress={onSubmitRecovery}>
                        Següent pas
        </Button>
                </View>

            </Surface>
        </View>
    );
}

export default RecoveryStepTwo;