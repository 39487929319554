import React, { useState, useEffect } from 'react';
import HeaderBar from "../components/sons/HeaderBar";
import { View, StyleSheet, FlatList, SafeAreaView, ScrollView, Platform } from "react-native";
import { NAV_BAR_HEIGHT } from "../utiles/CalculateNavbarHeight";
import { useTheme, Surface, Text, TouchableRipple, Portal, TextInput, Button, Dialog } from "react-native-paper";
import { eachDayOfInterval, endOfWeek, isSameWeek, startOfToday, startOfWeek } from "date-fns";
import ca from 'date-fns/locale/ca/index';
import { SimpleLineIcons } from '@expo/vector-icons';
import CalendarWeekDays from "../components/calendar/CalendarWeekDays";
import HeadingSection from "../components/headings/HeadingSection";
import { Checkbox } from 'react-native-paper';
import { moderateScale } from "react-native-size-matters";
import services from "../services";
import AppLoading from "../components/loading/AppLoading";
import RenderMedicamentItem from "../components/medicaments/RenderMedicamentItem";
import { format } from "date-fns-tz";

const AgendaScreen = ({ route, navigation }) => {
  const { colors } = useTheme();
  const { childId, familyTitle } = route.params;

  const today = startOfToday();
  const [currentDay, setCurrentDay] = useState(today);
  const [currentWeek, setCurrentWeek] = useState(eachDayOfInterval({
    start: startOfWeek(today, {
      locale: ca
    }),

    end: endOfWeek(today, {
      locale: ca
    }
    )
  }));

  const [isLoading, setLoading] = useState(true);
  const [student, setStudent] = useState([]);

  const [bolquers, setBolquers] = useState(false);
  const [tovalloletes, setTovalloletes] = useState(false);
  const [crema, setCrema] = useState(false);
  const [customCalPortar, setCustomCalPortar] = useState(false);
  const [customCalPortarValue, setCustomCalPortarValue] = useState(false);

  const [showMedicationForm, setShowMedicationForm] = useState(false);
  const [medicamentName, setMedicamentName] = useState('');
  const [medicamentNameErr, setMedicamentNameErr] = useState(false);
  const [dosiValue, setDosiValue] = useState('');
  const [dosiErr, setDosiErr] = useState(false);
  const [horaValue, setHoraValue] = useState('');
  const [horaErr, setHoraErr] = useState(false);
  const [observacionsMed, setObservacionsMed] = useState('');

  const [agendaFamilia, setAgendaFamilia] = useState({});
  const [showObservacionsFamilia, setShowObservacionsFamilia] = useState(false);
  const [observacionsFamilia, setObservacionsFamilia] = useState('');

  const [medicaments, setMedicaments] = useState([]);

  const submitNewMedication = () => {
    let validation = true;

    if (medicamentName.length === 0) {
      setMedicamentNameErr(true);
      validation = false;
    } else {
      setMedicamentNameErr(false)
    }

    if (dosiValue.length === 0) {
      setDosiErr(true);
      validation = false;
    } else {
      setDosiErr(false)
    }

    if (horaValue.length === 0) {
      setHoraErr(true);
      validation = false;
    } else {
      setHoraErr(false)
    }

    if (validation) {
      services.agendaMedicament.create({
        date: format(currentDay, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
        studentId: childId,
        medicament: medicamentName,
        dosi: dosiValue,
        hora: horaValue,
        observacions: observacionsMed
      }).then((res) => {
        setMedicaments([...medicaments, res]);
        setShowMedicationForm(false);
        setMedicamentName('');
        setDosiValue('');
        setHoraValue('');
        setObservacionsMed('');
      })
    }
  };

  const submitObservacionsFamilia = () => {
    if (agendaFamilia.hasOwnProperty('id')) {
      services.agendaFamilia.patch(agendaFamilia.id, {
        observacions_familia: observacionsFamilia
      }).then((res) => {
        setObservacionsFamilia(res.observacions_familia);
        setShowObservacionsFamilia(false);
      });
    } else {
      services.agendaFamilia.create({
        date: format(currentDay, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
        studentId: childId,
        observacions_familia: observacionsFamilia
      }).then((res) => {
        setAgendaFamilia(res);
        setObservacionsFamilia(res.observacions_familia);
        setShowObservacionsFamilia(false);
      });
    }
  };

  const fetchAgenda = (date, student) => {
    services.studentAgenda.find({
      query: {
        date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
        studentId: student,
      }
    }).then((calPortar) => {
      if (calPortar.total > 0) {
        let item = calPortar.data[0];
        setBolquers(item.bolquers);
        setTovalloletes(item.tovalloletes);
        setCrema(item.crema);
        setCustomCalPortar(item.custom_field);
        setCustomCalPortarValue(item.custom_field_value);
      } else {
        setBolquers(false);
        setTovalloletes(false);
        setCrema(false);
        setCustomCalPortar(false);
        setCustomCalPortarValue('');
      }
    })
    services.agendaMedicament.find({
      query: {
        date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
        studentId: student,
        paginate: false,
        $sort: { createdAt: 1 }
      }
    }).then((meds) => {
      setMedicaments(meds);
      services.agendaFamilia.find({
        query: {
          date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
          studentId: student,
          $sort: {
            createdAt: -1,
          },
        }
      }).then((agendaFam) => {
        if (agendaFam.total > 0) {
          setAgendaFamilia(agendaFam.data[0]);
          setObservacionsFamilia(agendaFam.data[0].observacions_familia);
        } else {
          setAgendaFamilia({});
          setObservacionsFamilia('');
        }
        setLoading(false);
      })
    });
  };

  useEffect(() => {
    setLoading(true);
    services.students.get(childId).then((res) => {
      setStudent(res);
      fetchAgenda(currentDay, childId);
      setLoading(false);
    });
  }, [childId]);

  useEffect(() => {
    fetchAgenda(currentDay, childId);
  }, [currentDay]);


  const styles = StyleSheet.create({
    container: {
      flex: 1,
      paddingTop: NAV_BAR_HEIGHT,
      backgroundColor: colors.primary
    },
    calendarHeaderWeekContainer: {
      elevation: 0,
      padding: 8.0,
      marginHorizontal: 12,
      marginTop: 4,
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12
    },
    calendarObj: {
      padding: 8.0,
      marginHorizontal: 12,
      backgroundColor: colors.accent,
      borderBottomRightRadius: 12,
      borderBottomLeftRadius: 12
    },
    sectionContainer: {
      elevation: 0,
      margin: 12,
      marginTop: 0,
      paddingVertical: 16,
      borderRadius: 12
    },
    sectionInlineContent: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    medicacioButtonAdd: {
      padding: 12.0,
      borderRadius: 12,
      backgroundColor: '#e8e8e8'
    }
  });

  const handleChangeDay = (date) => {
    setCurrentDay(date);
    if(!isSameWeek(currentDay, date, { locale: ca })) {
      setCurrentWeek(
        eachDayOfInterval({
          start: startOfWeek(date, {
            locale: ca
          }),
      
          end: endOfWeek(date, {
            locale: ca
          }
          )
        })
      );
    }
  };

  const onRenderMedicament = ({ item }) => <RenderMedicamentItem item={item} />

  const FlatListMedicacioFooter = () => {
    if (medicaments.length > 0) {
      return (
        <TouchableRipple onPress={() => setShowMedicationForm(true)} style={[styles.medicacioButtonAdd, { cursor: 'pointer' }]}>
          <View style={[styles.sectionInlineContent, { justifyContent: 'center' }]}>
            <SimpleLineIcons name="plus" size={12} color="#666" />
            <Text style={{
              fontSize: moderateScale(12, 0.1),
              marginLeft: 4.0,
              fontFamily: 'Rubik-Regular',
              textTransform: 'uppercase',
              color: '#666'
            }}>Afegir</Text>
          </View>
        </TouchableRipple>
      );
    } else {
      return (null);
    }
  };

  const FlatListItemSeparator = () => {
    if ((Platform.OS === 'web')) {
      return (null);
    }
    return (
      <View
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#47b000",
        }}
      />
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      {isLoading && <AppLoading />}
      {!isLoading && (
        <ScrollView>

          <HeaderBar student={student} family={familyTitle} />

          <HeadingSection icon="calendar" title="Agenda" iconColor={colors.secondaryText}
            titleColor={colors.secondaryText} />
          <Surface style={styles.calendarHeaderWeekContainer}>
            <CalendarWeekDays onClickDay={handleChangeDay} selectedDay={currentDay} weekdays={currentWeek} />
          </Surface>

          <View style={styles.calendarObj}>
            <Surface style={styles.sectionContainer}>
              <HeadingSection icon="pin" title="Cal portar" iconColor={colors.primary} titleColor={colors.primary} />
              <View style={{
                marginHorizontal: 28
              }}>
                <View style={styles.sectionInlineContent}>
                  <Checkbox
                    status={bolquers ? 'checked' : 'unchecked'}
                  />
                  <Text>bolquers</Text>
                </View>
                <View style={styles.sectionInlineContent}>
                  <Checkbox
                    status={tovalloletes ? 'checked' : 'unchecked'}
                  />
                  <Text>tovalloletes</Text>
                </View>
                <View style={styles.sectionInlineContent}>
                  <Checkbox
                    status={crema ? 'checked' : 'unchecked'}
                  />
                  <Text>crema</Text>
                </View>
                {customCalPortar && <View style={styles.sectionInlineContent}>
                  <Checkbox
                    status={customCalPortar ? 'checked' : 'unchecked'}
                  />
                  <Text>{customCalPortarValue}</Text>
                </View>}
              </View>
            </Surface>

            <TouchableRipple borderless style={{
              borderRadius: 12, cursor: 'pointer'
            }} onPress={() => setShowObservacionsFamilia(true)}>
              <Surface style={styles.sectionContainer}>
                <HeadingSection icon="pencil" title="Observacions de la família" iconColor={colors.primary}
                  titleColor={colors.primary} />
                <View style={{
                  marginHorizontal: 28
                }}>
                  <Text style={{
                    fontSize: 14,
                    fontFamily: 'Rubik-Medium'
                  }}>{observacionsFamilia}</Text>
                </View>
              </Surface>
            </TouchableRipple>

            <Surface style={styles.sectionContainer}>
              <HeadingSection icon="exclamation" title="Medicació" iconColor={colors.primary}
                titleColor={colors.primary} />
              <View>

                <FlatList
                  style={{
                    marginHorizontal: 12,
                    marginBottom: 12,
                    borderRadius: 12,
                    padding: 6,
                    backgroundColor: 'white',
                  }}
                  ItemSeparatorComponent={FlatListItemSeparator}
                  ListFooterComponent={FlatListMedicacioFooter}
                  contentContainerStyle={{ flexGrow: 1 }}
                  ListEmptyComponent={() => <View style={styles.emptyView}>
                    <TouchableRipple onPress={() => setShowMedicationForm(true)} style={[styles.medicacioButtonAdd, { cursor: 'pointer' }]}>
                      <View style={[styles.sectionInlineContent, { justifyContent: 'center' }]}>
                        <SimpleLineIcons name="plus" size={12} color="#666" />
                        <Text style={{
                          fontSize: moderateScale(12, 0.1),
                          marginLeft: 4.0,
                          fontFamily: 'Rubik-Regular',
                          textTransform: 'uppercase',
                          color: '#666'
                        }}>Afegir</Text>
                      </View>
                    </TouchableRipple>
                  </View>}
                  data={medicaments}
                  renderItem={onRenderMedicament}
                  keyExtractor={(item, index) => `med-item-${item.id}`}
                />
              </View>
            </Surface>
          </View>

          <Portal>
            <Dialog visible={showMedicationForm} onDismiss={() => setShowMedicationForm(false)}>
              <Dialog.Content>
                <TextInput
                  style={{
                    marginBottom: 12
                  }}
                  error={medicamentNameErr}
                  label="Nom del medicament *"
                  value={medicamentName}
                  onChangeText={text => setMedicamentName(text)}
                />
                <TextInput
                  style={{
                    marginBottom: 12
                  }}
                  error={dosiErr}
                  label="Dosi *"
                  value={dosiValue}
                  onChangeText={text => setDosiValue(text)}
                />
                <TextInput
                  style={{
                    marginBottom: 12
                  }}
                  error={horaErr}
                  label="Hora *"
                  value={horaValue}
                  onChangeText={text => setHoraValue(text)}
                />
                <TextInput
                  style={{
                    marginBottom: 12
                  }}
                  multiline
                  label="Observacions"
                  value={observacionsMed}
                  onChangeText={text => setObservacionsMed(text)}
                />
              </Dialog.Content>
              <Dialog.Actions>
                <Button onPress={() => setShowMedicationForm(false)}>Cancel·lar</Button>
                <Button onPress={submitNewMedication}>Guardar</Button>
              </Dialog.Actions>
            </Dialog>
            <Dialog visible={showObservacionsFamilia} onDismiss={() => setShowObservacionsFamilia(false)}>
              <Dialog.Content>
                <TextInput
                  multiline
                  style={{
                    marginBottom: 12
                  }}
                  label="Observacions de la família"
                  value={observacionsFamilia}
                  onChangeText={text => setObservacionsFamilia(text)}
                />
              </Dialog.Content>
              <Dialog.Actions>
                <Button onPress={() => setShowObservacionsFamilia(false)}>Cancel·lar</Button>
                <Button onPress={submitObservacionsFamilia}>Guardar</Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>

        </ScrollView>)}
    </SafeAreaView>
  )
};

export default AgendaScreen;
