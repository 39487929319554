import OneSignal from 'react-native-onesignal';
import client from '../../config/client';
import { doCreateUser } from "../../services/users";
import { hideLoader, showLoader } from "../loader/actions";
import { initSchoolYear } from "../schoolyear/actions";
import { fetchNotifications } from "../notifications/actions";

export const doSignup = ({ name, email, password }, onComplete, onError) => {
  doCreateUser({
                 name,
                 email,
                 password
               })
    .then((res) => onComplete(res))
    .catch((err) => onError(err));
};


export const doLogin = ({ email, password }, onSuccess, onError) => {
  return dispatch => {
    client.authenticate({
                          strategy: 'local',
                          email: email,
                          password: password
                        }).then((res) => {
      // ok
      dispatch({
                 type: 'SET_USER',
                 payload: res.user
               });
      dispatch({
                 type: 'LOGIN',
                 payload: true
               });
      dispatch(initPushNotifications(res.user.id));
      dispatch(initSchoolYear());
      dispatch(fetchNotifications(res.user.id));
      onSuccess();
    }).catch((err) => onError(err))
  }
};

export const reAuthenticate = () => {
  return dispatch => {
    dispatch(showLoader());
    client.reAuthenticate()
      .then((resp) => {
        dispatch({
                   type: 'LOGIN',
                   payload: true
                 });
        dispatch({type: 'REFRESH_SESSION', payload: resp.user});
        dispatch(initPushNotifications(resp.user.id))
        dispatch(initSchoolYear());
        dispatch(fetchNotifications(resp.user.id));
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(logout());
        dispatch(hideLoader());
      });
  };
};

export const initPushNotifications = (userBackendId) => {
  return dispatch => {
    console.log('initPushNotifications', userBackendId);
    OneSignal.setExternalUserId(userBackendId);
    /*
    OneSignal.push(function() {
      OneSignal.on('subscriptionChange', function(isSubscribed) {
        if (isSubscribed) {
          // The user is subscribed
          //   Either the user subscribed for the first time
          //   Or the user was subscribed -> unsubscribed -> subscribed
          OneSignal.getUserId( function(userId) {
            alert(userId);
            // Make a POST call to your server with the user ID
          });
        }
      });
    });
    */
  };
};

export const logout = (onComplete) => {
  return dispatch => {
    client.logout()
      .then(() => {
        dispatch({type: 'UNSET_LOGIN'});
        dispatch({
                   type: 'LOGIN',
                   payload: false
                 });
        if(onComplete) {
          onComplete();
        }
      }).catch(() => {
      dispatch({type: 'UNSET_LOGIN'});
      dispatch({
                 type: 'LOGIN',
                 payload: false
               });
      if(onComplete) {
        onComplete();
      }
    });
  }
};
