import React, { useState, useEffect } from 'react';
import { StyleSheet, View, ScrollView } from "react-native";
import { ActivityIndicator, Surface, Text, TouchableRipple, useTheme } from "react-native-paper";
import { moderateScale } from "react-native-size-matters";
import HeadingSection from "../components/headings/HeadingSection";
import CalendarWeekDays from "../components/calendar/CalendarWeekDays";
import { eachDayOfInterval, endOfWeek, startOfToday, startOfWeek, isSameWeek } from "date-fns";
import ca from 'date-fns/locale/ca/index';
import { NAV_BAR_HEIGHT } from "../utiles/CalculateNavbarHeight";
import HeaderBar from "../components/sons/HeaderBar";
import { FontAwesome5 } from '@expo/vector-icons';
import services from "../services";
import AppLoading from "../components/loading/AppLoading";
import DeposicioViewerItem from '../components/deposicions/DeposicioViewerItem';
import { format } from "date-fns-tz";

const DailyControlScreen = ({ route, navigation }) => {
  const { colors } = useTheme();
  const { childId, familyTitle } = route.params;

  const today = startOfToday();
  const [currentDay, setCurrentDay] = useState(today);
  const [currentWeek, setCurrentWeek] = useState(eachDayOfInterval({
    start: startOfWeek(today, {
      locale: ca
    }),

    end: endOfWeek(today, {
      locale: ca
    }
    )
  }));

  const [isLoading, setLoading] = useState(true);
  const [student, setStudent] = useState([]);

  const [dailyItem, setDailyItem] = useState({});

  useEffect(() => {
    setLoading(true);
    services.students.get(childId).then((res) => {
      setStudent(res);
      fetchDaily(currentDay, childId);
      setLoading(false);
    });
  }, [childId]);

  const fetchDaily = (date, student) => {
    services.dailyControl.find({
      query: {
        date: format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Madrid' }),
        studentId: student,
        $sort: {
          createdAt: -1,
        },
      }
    }).then((res) => {
      if (res.total > 0) {
        let item = res.data[0];
        setDailyItem(item);
      } else {
        setDailyItem({});
      }
    });
  };

  useEffect(() => {
    fetchDaily(currentDay, childId);
  }, [currentDay]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      paddingTop: NAV_BAR_HEIGHT,
      backgroundColor: colors.primary
    },
    calendarHeaderWeekContainer: {
      elevation: 0,
      padding: 8.0,
      marginHorizontal: 12,
      marginTop: 4,
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12
    },
    calendarObj: {
      flex: 1,
      padding: 8.0,
      marginHorizontal: 12,
      backgroundColor: colors.accent,
      borderBottomRightRadius: 12,
      borderBottomLeftRadius: 12
    },
    sectionContainer: {
      flex: 1,
      elevation: 0,
      margin: 12,
      marginTop: 0,
      paddingVertical: 16,
      borderRadius: 12
    },
    sectionInlineContent: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    medicacioButtonAdd: {
      padding: 12.0,
      borderRadius: 12,
      backgroundColor: '#e8e8e8'
    },
    sectionInlineTitle: {
      fontSize: moderateScale(18, 0.1),
      textAlign: 'right',
      fontFamily: 'Rubik-Bold',
      marginRight: 8
    },
    sectionInlineValue: {
      fontSize: moderateScale(18, 0.1),
      fontFamily: 'Rubik-Light'
    }
  });

  const handleChangeDay = (date) => {
    setCurrentDay(date);
    if(!isSameWeek(currentDay, date, { locale: ca })) {
      setCurrentWeek(
        eachDayOfInterval({
          start: startOfWeek(date, {
            locale: ca
          }),
      
          end: endOfWeek(date, {
            locale: ca
          }
          )
        })
      );
    }
  };

  return (
    <View style={styles.container}>
      {isLoading && <AppLoading />}
      {!isLoading && (
        <>

          <HeaderBar student={student} family={familyTitle} />

          <HeadingSection icon="notebook" title="Control diari" iconColor={colors.secondaryText}
            titleColor={colors.secondaryText} />
          <Surface style={styles.calendarHeaderWeekContainer}>
            <CalendarWeekDays onClickDay={handleChangeDay} selectedDay={currentDay} weekdays={currentWeek} />
          </Surface>

          {!dailyItem.hasOwnProperty('id') && (
            <View style={[styles.calendarObj, { justifyContent: 'center', alignItems: 'center' }]}>
              <Text style={{ fontSize: 24, fontFamily: 'Rubik-Light', color: 'white' }}>Oops!</Text>
              <Text style={{ fontSize: 18, fontFamily: 'Rubik-Light', color: 'white', textAlign: 'center', marginTop: 8, marginBottom: 8 }}>Encara no hi ha registre diari per a aquesta data</Text>
              <ActivityIndicator animating={true} color={'white'} size={'small'} />
            </View>
          )}

          {dailyItem.hasOwnProperty('id') && (
            <View style={styles.calendarObj}>
              <ScrollView>
                <View style={{
                  flexDirection: 'column'
                }}>
                  <Surface style={styles.sectionContainer}>
                    <HeadingSection
                      icon={'cup'}
                      title="Menjar"
                      iconColor={colors.primary}
                      titleColor={colors.primary}
                      containerStyle={{
                        marginTop: 6,
                        marginBottom: 12,
                        marginHorizontal: 24
                      }}
                    />
                    <View style={{
                      marginHorizontal: 36
                    }}>
                      <View style={{
                        ...styles.sectionInlineContent,
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        borderBottomWidth: 1.0,
                        borderColor: '#eee'
                      }}>
                        <Text style={styles.sectionInlineTitle}>Esmorzar: </Text>
                        <Text style={styles.sectionInlineValue}>{dailyItem.esmorzar.length > 0 ? dailyItem.esmorzar : '-'}</Text>
                      </View>
                      <View style={{
                        ...styles.sectionInlineContent,
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        borderBottomWidth: 1.0,
                        borderColor: '#eee',
                        marginTop: 6
                      }}>
                        <Text style={styles.sectionInlineTitle}>Dinar: </Text>
                        <Text style={styles.sectionInlineValue}>{dailyItem.dinar.length > 0 ? dailyItem.dinar : '-'}</Text>
                      </View>
                      <View style={{
                        ...styles.sectionInlineContent,
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        borderBottomWidth: 1.0,
                        borderColor: '#eee',
                        marginTop: 6
                      }}>
                        <Text style={styles.sectionInlineTitle}>Berenar: </Text>
                        <Text style={styles.sectionInlineValue}>{dailyItem.berenar.length > 0 ? dailyItem.berenar : '-'}</Text>
                      </View>
                    </View>
                  </Surface>

                  <Surface style={styles.sectionContainer}>
                    <HeadingSection
                      icon={'info'}
                      title="Tipus d'alimentació"
                      iconColor={colors.primary}
                      titleColor={colors.primary}
                      containerStyle={{
                        marginTop: 6,
                        marginBottom: 18,
                        marginHorizontal: 24,
                      }}
                    />
                    <View style={{
                      marginHorizontal: 28,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'},
                        dailyItem.tipus_alimentacio === 1 ? { } : { opacity: 0.25 }
                      ]}>
                        <FontAwesome5 name="carrot" size={24} color="orange" />
                        <Text style={{
                          marginTop: 8,
                          fontSize: moderateScale(14, 0.1),
                          fontFamily: 'Rubik-Bold',
                          textAlign: 'center'
                        }}>NORMAL</Text>
                      </View>
                      <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'},
                        dailyItem.tipus_alimentacio === 2 ? { } : { opacity: 0.25 }
                      ]}>
                        <FontAwesome5 name="seedling" size={24} color="#CDDC39" />
                        <Text style={{
                          marginTop: 8,
                          fontSize: moderateScale(14, 0.1),
                          fontFamily: 'Rubik-Bold',
                          textAlign: 'center'
                        }}>DIETA</Text>
                      </View>
                    </View>
                  </Surface>
                </View>

                <View style={{
                  flexDirection: 'column'
                }}>
                  <Surface style={styles.sectionContainer}>
                    <HeadingSection
                      icon={'hourglass'}
                      title="Dormir"
                      iconColor={colors.primary}
                      titleColor={colors.primary}
                      containerStyle={{
                        marginTop: 6,
                        marginBottom: 12,
                        marginHorizontal: 24
                      }}
                    />
                    <View style={{
                      marginHorizontal: 36
                    }}>
                      <View style={{
                        ...styles.sectionInlineContent,
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        borderBottomWidth: 1.0,
                        borderColor: '#eee'
                      }}>
                        <Text style={styles.sectionInlineTitle}>Matí: </Text>
                        <Text style={styles.sectionInlineValue}>{dailyItem.mati_dormir.length > 0 ? dailyItem.mati_dormir : '-'}</Text>
                      </View>
                      <View style={{
                        ...styles.sectionInlineContent,
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        borderBottomWidth: 1.0,
                        borderColor: '#eee',
                        marginTop: 6
                      }}>
                        <Text style={styles.sectionInlineTitle}>Tarda: </Text>
                        <Text style={styles.sectionInlineValue}>{dailyItem.tarda_dormir.length > 0 ? dailyItem.tarda_dormir : '-'}</Text>
                      </View>
                    </View>
                  </Surface>

                  <Surface style={styles.sectionContainer}>
                    <HeadingSection
                      icon={"book-open"}
                      title="Deposicions"
                      iconColor={colors.primary}
                      titleColor={colors.primary}
                      containerStyle={{
                        marginTop: 6,
                        marginBottom: 12,
                        marginHorizontal: 24
                      }}
                    />
                    <View style={{
                      marginHorizontal: 28,
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'row'
                    }}>
                      <DeposicioViewerItem count={dailyItem.deposicio_normal} icon="thumbs-up" label="Normal" />
                      <DeposicioViewerItem count={dailyItem.deposicio_tova} icon="poo" iconColor="#795548" label="Tova" />
                      <DeposicioViewerItem count={dailyItem.deposicio_liquida} icon="tint" iconColor="#03A9F4" label="Líquida" />
                    </View>
                  </Surface>
                </View>

                <Surface style={styles.sectionContainer}>
                  <HeadingSection                       containerStyle={{
                        marginTop: 6,
                        marginBottom: 12,
                        marginHorizontal: 24
                      }} icon="pencil" title="Observacions del centre" iconColor={colors.primary}
                    titleColor={colors.primary} />
                  <View style={{
                    marginHorizontal: 28
                  }}>
                    <Text style={{
                      fontSize: 14,
                      fontFamily: 'Rubik-Medium'
                    }}>{dailyItem.observacions_centre}</Text>
                  </View>
                </Surface>

              </ScrollView>
            </View>

          )}
        </>)}

    </View>
  );
};

export default DailyControlScreen;
