import React, { useState, useLayoutEffect, useEffect } from 'react';
import { View } from 'react-native';
import { useTheme, TouchableRipple, Text } from 'react-native-paper';

const Pagination = ({ totalElements, elementsPerPage, currentPage, handlePagePress }) => {
    const { colors } = useTheme();
    const [current, setCurrent] = useState(currentPage);
    const [total, setTotal] = useState(totalElements);
    const [limit, setLimit] = useState(elementsPerPage);
    const [totalPages, setTotalPages] = useState(Math.ceil(totalElements / elementsPerPage));
    const [pages, setPages] = useState([]);

    useLayoutEffect(() => {
        let _totalPages = Math.ceil(totalElements / elementsPerPage);
        setTotal(totalElements);
        setTotalPages(_totalPages);
        setPages(Array(_totalPages).fill());
    }, [totalElements]);

    useEffect(() => {
        setCurrent(currentPage);
    }, [currentPage]);

    useEffect(() => {
        setLimit(limit);
    }, [elementsPerPage]);

    return (
        <>
        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center'
        }}>
            <Text style={{
                fontSize: 10,
                color: colors.silver
            }}>{total} resultats en {totalPages} pàgines</Text>
        </View>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {pages.map((v, i) => (
                    <TouchableRipple key={`pagination-${i}`} onPress={() => handlePagePress(i + 1)} style={{
                        margin: 6,
                        padding: 12,
                        borderWidth: 1.0,
                        borderColor: '#e4e4e4',
                        borderRadius: 8,
                        cursor: 'pointer',
                        ...(current === i + 1 ? {
                            backgroundColor: colors.accent,
                            borderColor: colors.accent
                        } : {})
                    }}>
                        <Text style={{
                            fontFamily: 'Rubik-Bold',
                            fontSize: 14.0,
                            minWidth: 24,
                            textAlign: 'center',
                            color: (current === i + 1 ? 'white' : colors.text)
                        }}>{i + 1}</Text>
                    </TouchableRipple>
                ))}
            </View>
        </>
    );
};

export default Pagination;