import React, { useEffect, useState } from 'react';
import { FlatList, StyleSheet, View } from "react-native";
import { Text, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import RenderNotificationItem from "../components/notifications/RenderNotificationItem";
import { notificationUpdateToReaded } from "../store/notifications/actions";
import services from "../services";
import HeaderBar from "../components/sons/HeaderBar";
import HeadingSection from "../components/headings/HeadingSection";
import { NAV_BAR_HEIGHT } from "../utiles/CalculateNavbarHeight";
import AppLoading from "../components/loading/AppLoading";


const ChildrenNotificationsScreen = ({ route }) => {
  const { colors } = useTheme();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { childId, familyTitle } = route.params;
  const [isLoading, setLoading] = useState(true);
  const [elements, setElements] = useState([]);
  const [student, setStudent] = useState({});

  const markAsRead = (id) => {
    dispatch(notificationUpdateToReaded(id));
  };

  const onRenderNotificationItem = ({ index, item }) => {
    return <RenderNotificationItem item={item.notification} unread={item.unread} onRender={() => {
      if (item.unread === true) {
        markAsRead(item.id)
      }
    }}/>
  };

  const fetchChildrenNotifications = (childId) => {
    services.myNotifications.find({
                                    query: {
                                      studentId: childId,
                                      $sort: { createdAt: -1 }
                                    }
                                  }).then((res) => {
      setElements(res.data);
    });
  };

  useEffect(() => {

  }, []);


  useEffect(() => {
    setLoading(true);
    services.students.get(childId).then((res) => {
      setStudent(res);
      setLoading(false);
      fetchChildrenNotifications(childId);
    });
  }, [childId]);


  const styles = StyleSheet.create({
                                     container: {
                                       flex: 1,
                                       paddingTop: NAV_BAR_HEIGHT,
                                       backgroundColor: colors.primary
                                     }
                                   });


  return (
    <View style={styles.container}>

      {isLoading && <AppLoading/>}
      {!isLoading && (<>
        <HeaderBar student={student} family={familyTitle}/>
        <HeadingSection icon="bell" title="Notificacions" iconColor={colors.secondaryText}
                        titleColor={colors.secondaryText}/>
        <FlatList
          style={{
            padding: 12,
            backgroundColor: 'white',
            borderRadius: 12
          }}
          ListEmptyComponent={() =>
            <Text style={{ fontSize: 18, fontFamily: 'Rubik-Light', padding: 8 }}>
              no hi ha cap notificació.
            </Text>
          }
          data={elements}
          renderItem={onRenderNotificationItem}
          keyExtractor={(item, index) => `notification-${item.id}`}
        />
      </>)}
    </View>
  );
};
export default ChildrenNotificationsScreen;
