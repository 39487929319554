import React from "react";
import { View, Text } from "react-native";
import { ActivityIndicator } from "react-native-paper";

const AppLoading = () => {
  return (
    <View style={{
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <ActivityIndicator color={"white"} size={"small"} />
    </View>
  );
};

export default AppLoading;
