const initialState = {
  list: [],
  current: null
};

const schoolYearReducer = (state = initialState, action) => {
  switch(action.type){
    case 'LOAD_YEARS_LIST':
      return {...state, list: action.payload };
    case 'SET_SCHOOLYEAR':
      return {...state, current: action.payload };
    case 'WIPE_SCHOOLYEAR':
      return state = initialState;
    default:
      return state
  }
};

export default schoolYearReducer;
