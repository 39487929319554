import React, { useEffect, useState } from 'react';
import { FlatList, View, StyleSheet, Platform } from "react-native";
import { Portal, Text, useTheme, Dialog, TextInput, Button, IconButton } from "react-native-paper";
import ContainedButton from "../components/buttons/ContainedButton";
import { NAV_BAR_HEIGHT } from "../utiles/CalculateNavbarHeight";
import HeaderBar from "../components/sons/HeaderBar";
import HeadingSection from "../components/headings/HeadingSection";
import { moderateScale } from "react-native-size-matters";
import services from "../services";
import AppLoading from "../components/loading/AppLoading";

const AllergensScreen = ({ route, navigation }) => {
  const { colors } = useTheme();
  const { childId, familyTitle } = route.params;
  const [data, setData] = useState([]);

  const [showCreate, setCreate] = useState(false);
  const [textAllergen, setAllergen] = useState('');
  const [errAllergen, setErrAllergen] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [student, setStudent] = useState([]);

  const fetchAllergens = (studentId) => {
    return services.studentAllergens.find({
                                            query: {
                                              studentId: studentId,
                                              paginate: false,
                                              $sort: { createdAt: 1 }
                                            }
                                          }).then((res) => {
      setData(res);
      return res;
    })
  };

  const removeItem = (itemId) => {
    return services.studentAllergens.remove(itemId).then((res) => {
      let newArr = data.filter(it => it.id !== itemId);
      setData(newArr);
    });
  };

  useEffect(() => {
    setLoading(true);
    services.students.get(childId).then((res) => {
      setStudent(res);
      fetchAllergens(childId).then((res) => {
        setLoading(false);
      })
    });
  }, [childId]);

  const onRenderItem = ({ index, item }) => {
    return <View style={{
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 8.0
    }}>
      <View>
        <Text style={{
          fontSize: 18,
          fontFamily: 'Rubik-Medium',
        }}>{item.text}</Text>
      </View>
      <View>
        <IconButton
          icon="delete"
          color={'#F44336'}
          size={24}
          onPress={() => removeItem(item.id)}
        />
      </View>
    </View>
  };

  const submitNewAllergen = () => {
    if(textAllergen.length > 0){
      services.studentAllergens.create({
                                         studentId: childId,
                                         text: textAllergen
                                       }).then((res) => {
        setCreate(false);
        setData([...data, res]);
        setAllergen('');
        setErrAllergen(false);
      })
    } else {
      setErrAllergen(true)
    }
  };

  const styles = StyleSheet.create({
                                     container: {
                                       flex: 1,
                                       paddingTop: NAV_BAR_HEIGHT,
                                       backgroundColor: colors.primary
                                     },
                                     emptyView: {
                                       flex: 1,
                                       alignItems: 'center',
                                       justifyContent: 'center',
                                     },
                                     emptyText: {
                                       fontSize: moderateScale(16, 0.1),
                                       textAlign: 'center',
                                       marginVertical: 24,
                                       fontFamily: 'Rubik-Light'
                                     }
                                   });

  const FlatListItemSeparator = () => {
    return (
      <View
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#47b000",
        }}
      />
    );
  };

  const FlatListFooter = () => {
    if(data.length === 0) {
      return (null);
    }
    return (
      <View style={styles.emptyView}>
        <View>
          <ContainedButton icon={'plus-circle'} onPress={() => setCreate(true)} lblButton="Afegir"/>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>

      {isLoading && <AppLoading/>}
      {!isLoading && (<>
        <HeaderBar student={student} family={familyTitle}/>
        <HeadingSection icon="heart" title="Al·lergens" iconColor={colors.secondaryText}
                        titleColor={colors.secondaryText}/>
        <FlatList
          style={{
            marginHorizontal: 12,
            marginBottom: 12,
            borderRadius: 12,
            padding: 6,
            backgroundColor: 'white',
          }}
          ItemSeparatorComponent={FlatListItemSeparator}
          ListFooterComponent={FlatListFooter}
          contentContainerStyle={{ flexGrow: 1 }}
          ListEmptyComponent={() => <View style={styles.emptyView}>
            <Text style={styles.emptyText}>No hi ha cap al·lergen registrat encara</Text>
            <View>
              <ContainedButton icon={'plus-circle'} onPress={() => setCreate(true)} lblButton="Afegir"/>
            </View>
          </View>}
          data={data}
          renderItem={onRenderItem}
          keyExtractor={(item, index) => `allergen-item-${index}`}
        />
      </>)}

      <Portal>
        <Dialog visible={showCreate} onDismiss={() => setCreate(false)}>
          <Dialog.Content>
            <TextInput
              multiline
              style={{
                marginBottom: 12
              }}
              error={errAllergen}
              label="Descriu o indica'ns"
              value={textAllergen}
              onChangeText={text => setAllergen(text)}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setCreate(false)}>Cancel·lar</Button>
            <Button onPress={submitNewAllergen}>Guardar</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

    </View>
  )
};

export default AllergensScreen;
