import React, { useMemo, useState, useEffect } from 'react';
import ca from 'date-fns/locale/ca/index';
import { startOfWeek, addDays, format, isEqual } from 'date-fns'
import { View, StyleSheet } from "react-native";
import { Text, TouchableRipple, useTheme } from "react-native-paper";
import { SimpleLineIcons } from '@expo/vector-icons';
import { Calendar, LocaleConfig } from 'react-native-calendars'; 
import startOfDay from 'date-fns/startOfDay';

LocaleConfig.locales['ca'] = {
  monthNames: ['Gener','Febrer','Març','Abril','Maig','Juny','Juliol','Agost','Setembre','Octubre','Novembre','Desembre'],
  monthNamesShort: ['Gen..','Febr.','Març','Abr.','Maig','Juny','Jul.','Ag.','Set.','Oct.','Nov.','Des.'],
  dayNames: ['Diumenge','Dilluns','Dimarts','Dimecres','Dijous','Divendres','Dissabte'],
  dayNamesShort: ['dg.','dl.','dt.','dc.','dj.','dv.','ds.'],
  today: 'Avui'
};
LocaleConfig.defaultLocale = 'ca';

const CalendarWeekDays = ({ selectedDay, onClickDay, weekdays }) => {
  const { colors } = useTheme();
  const [isExpandedCalendar, setExpandCalendar] = useState(false);
  const [markedDates, setMarkedDates] = useState({});

  useEffect(() => {
    let m = {};
    m[format(selectedDay, 'yyyy-MM-dd')] = { selected: true };
    setMarkedDates(m);
  }, [selectedDay])

  const formatDate = (date, strformat) => {
    return format(date, strformat, {
      locale: ca
    });
  };

  const firstDOW = startOfWeek(new Date(), {
    locale: ca
  });

  const shortWeekDays = useMemo(() => Array.from(
    Array(7)).map((e, i) => formatDate(addDays(firstDOW, i), 'iii')), [firstDOW]
  );

  const styles = StyleSheet.flatten({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    labelContent: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      margin: 2,
      flexDirection: 'column',
      borderWidth: 0.0,
      borderColor: colors.primary,
      borderRadius: 8
    },
    viewContent: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      paddingHorizontal: 4,
      paddingVertical: 8,
    },
    labelContentSelected: {
      backgroundColor: colors.primary
    },
    labelDay: {
      color: colors.primary,
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    labelNum: {
      color: colors.primary,
    },
    labelTextSelected: {
      color: 'white',
    }
  });

  return (
    <View style={{
      flexDirection: 'column'
    }}>
      {!isExpandedCalendar && (
        <View style={{ ...styles.container }}>
          {shortWeekDays.map((day, i) =>
            <TouchableRipple key={i} onPress={() => onClickDay(weekdays[i])}
              style={{
                cursor: 'pointer',
                ...styles.labelContent,
                ...isEqual(selectedDay, weekdays[i]) ? styles.labelContentSelected : null,
              }}>
              <View style={{ ...styles.viewContent }}>
                <Text
                  style={{
                    ...styles.labelDay,
                    ...isEqual(selectedDay, weekdays[i]) ? styles.labelTextSelected : null
                  }}
                  styles={{
                    ...styles.labelDay
                  }}>{day}</Text>
                <Text style={{
                  ...styles.labelNum,
                  ...isEqual(selectedDay, weekdays[i]) ? styles.labelTextSelected : null
                }}>{format(weekdays[i], 'd')}</Text>
              </View>
            </TouchableRipple>
          )}
        </View>
      )}
      {isExpandedCalendar && (
        <Calendar 
          current={format(selectedDay, 'yyyy-MM-dd')}
          markedDates={markedDates}
          enableSwipeMonths={true}
          firstDay={1}
          theme={{
            arrowColor: colors.accent,
            todayTextColor: colors.accent,
            selectedDayBackgroundColor: colors.accent,
            selectedDayTextColor: '#ffffff'
          }}
          renderArrow={(direction) => <SimpleLineIcons name={`arrow-${direction}`} size={18} color={colors.accent} />}
          onDayPress={(day) => {
            onClickDay(startOfDay(new Date(day.timestamp)))
            setExpandCalendar(false);
          }}
           />
      )}
      <View style={{
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <TouchableRipple borderless onPress={() => setExpandCalendar(!isExpandedCalendar)} style={{
          borderColor: '#47b000',
          borderWidth: 0.0,
          padding: 6,
          borderRadius: 50,
          cursor: 'pointer'
        }}>
          <SimpleLineIcons name={!isExpandedCalendar ? "arrow-down" : "arrow-up"} size={20} color={'#47b000'} />
        </TouchableRipple>
      </View>
    </View>
  );

};

export default CalendarWeekDays;
