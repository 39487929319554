const initialState = {};

const userReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SET_USER':
      return action.payload;
    case 'LOGOUT':
      return state = initialState;
    case 'REFRESH_SESSION':
      return {...action.payload};
    default:
      return state
  }
}

export default userReducer;
