import React, { useState, useEffect } from 'react';
import { Button, Text, Avatar, Portal, Dialog } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import services from "../../../services";
import FormNewUser from "./FormNewUser";
import Pagination from '../../pagination/Pagination';
import FormEmployee from './FormEmployee';

const ManageEmployees = () => {
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [manageEmployee, setManageEmployee] = useState({});

  const [showCreate, setCreate] = useState(false);
  const [showManage, setManage] = useState(false);

  const firstFetchRes = (_skip = 0) => {
    services.users.find({
      query: {
        $or: {
          isAdministration: true,
          isEmployee: true,
        },
        $limit: 10,
        $skip: _skip * limit
      }
    }).then((res) => {
      setTotal(res.total);
      setData(res.data);
    })
  };

  useEffect(() => {
    firstFetchRes();
  }, []);

  return (
    <>
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 24
      }}>
        <Text style={styles.headingTitle}>Gestionar personal</Text>
        <View>
          <Button mode="contained" icon="plus" onPress={() => setCreate(true)}>Nou</Button>
        </View>
      </View>

      <View style={styles.mainTable}>
        <View style={styles.tableHeading}>
          <Text style={styles.tableHeadingItemAvatar}></Text>
          <Text style={styles.tableHeadingItem}>Nom</Text>
          <Text style={styles.tableHeadingItem}>Correu</Text>
          <Text style={styles.tableHeadingItem}>Rols</Text>
          <Text style={styles.tableHeadingItem}>Accions</Text>
        </View>
        {data.map((reg) =>
          <View key={reg.id} style={styles.tableRow}>
            <View style={styles.tableItemAvatar}>
              {reg.avatar ? (
                <Avatar.Image size={24} source={{
                  uri: `https://agenda.granota.cat/avatars/${reg.avatar}`
                }} />
              ) : (
                  <Avatar.Text size={24} label={reg.name.toUpperCase().substring(0, 2)} />
                )}
            </View>
            <Text style={styles.tableItem}>{reg.name}</Text>
            <Text style={styles.tableItem}>{reg.email}</Text>
            <Text
              style={styles.tableItem}>{reg.isAdministration ? 'Administrador, ' : ''}{reg.isEmployee ? 'Treballador' : ''}</Text>
            <Text style={styles.tableItem}>

              <Button compact mode="text" onPress={() => {
                console.log('aa', reg)
              setManageEmployee(reg);
              setManage(true);
              }}>Gestionar fitxa</Button>

            </Text>
          </View>
        )}
        <Pagination
          totalElements={total}
          elementsPerPage={limit}
          currentPage={currentPage}
          handlePagePress={(page) => {
            setCurrentPage(page)
            firstFetchRes(page - 1);
          }}
        />
      </View>

      <Portal>
        <FormNewUser
          visible={showCreate}
          onDismiss={() => setCreate(false)}
          onComplete={() => {
            setCurrentPage(1);
            firstFetchRes();
            setCreate(false);
          }}
        />
        {(showManage && manageEmployee.hasOwnProperty('id')) && <FormEmployee
          visible={showManage}
          employeeId={manageEmployee.id}
          onDismiss={() => {
            setManageEmployee({});
            setManage(false);
          }}
          onComplete={() => {
            setCurrentPage(1);
            firstFetchRes();
            setManageEmployee({})
            setManage(false);
          }}
        />}
      </Portal>

    </>
  );
};

const styles = StyleSheet.create({
  headingTitle: {
    fontSize: 24,
    fontFamily: 'Rubik-Medium',
  },
  mainTable: {
    margin: 12,
    padding: 12
  },
  tableHeading: {
    flexDirection: 'row',
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableHeadingItem: {
    flex: 1,
    padding: 6,
    margin: 4,
    backgroundColor: '#a3d77f',
    color: 'white',
    fontFamily: 'Rubik-Light',
    textTransform: 'uppercase'
  },
  tableHeadingItemAvatar: {
    width: 24
  },
  tableItem: {
    flex: 1,
    padding: 6,
    margin: 4,
    backgroundColor: '#e5efdf',
    fontFamily: 'Rubik-Medium',
  },
  tableItemAvatar: {
    width: 24,
    alignSelf: 'center'
  }
});

export default ManageEmployees;
